import React, { useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';

import getFetchConfig from '../../../utils/getFetchConfig';
import handleFetchErrors from '../../../utils/handleFetchErrors';
import standardFetchHandlers from '../../../utils/standardFetchHandlers';
import LoaderButton from '../../buttons/loader-button/LoaderButton';
import { DOMParser } from '../../../utils/DOMParser';
import { DatePicker, TimePicker, Space } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import SelectLocation from '../select-location/SelectLocation';
import ImageUpload from '../images-upload/ImageUpload';


import {
    isTruthyString,
    isValidURL,
    isValidEmailAddress,
    isValidPassword,
    isDescriptionHas5Words,
    isValidIndianMobileNumber,
} from '../../../utils/verification';
import formDataAppender from '../../../utils/formDataAppender';

import Inputs from '../../Inputs/Inputs';
import PostAdsHeading from '../post-ads-heading/PostAdsHeading';
import FormButton from '../../buttons/form-button/FormButton';
import MultiSelect from '../../form-input/MultiSelect';
import RichTextEditor from '../../form-input/RichTextEditor';
import CongratulationsScreen from '../congratulations-screen/CongratulationsScreen';
import AdsListerInfo from '../ads-lister-info/AdsListerInfo';
// import AdsPlans from '../ads-plans/AdsPlans';
import AdsPlansUpdated from '../ads-plans/AdsPlansUpdated';

import appURLs from '../../../appURL';

import style from "./babysitterAndNany.module.css";
import { Item } from 'rc-menu';


export default function BabySitterAndNanny({ toggleModalVisibilty = () => { }, adsCategoriesId = ``, adsId = null, adsIds,setadsIds }) {

    console.log(adsIds , '34redfcwerfds');
    const dateFormat = 'MM-DD-YYYY';

    const fetchConfig = getFetchConfig(),
        appURL = appURLs();

    const [images, updateImage] = useState([]);
    const [dynamicData, updateDynamicData] = useState([]),
        [showLoader, toggleLoader] = useState(false),
        [selectedTagline, upatedSelectedTagline] = useState([]),
        [babysitterAndNannyId, UpdateBabysitterAndNannyId] = useState(null),
        [steps, updateSteps] = useState(0);

    
        
        const [selectedFiles, setSelectedFiles] = useState([]);
        const [errorMessage, setErrorMessage] = useState('');
        const [myadsId,setmyadsId]=useState('');
const [myadsType,setmyadsType]=useState('');


    const onCreateteHandler = (value) => {
        if (value && selectedTagline.length <= 4) {
            upatedSelectedTagline([
                ...selectedTagline,
                value
            ])
        }

    }


    const transportfacilityRadioBtn = [
        {
            name: "Yes",
            _id: "Yes"
        },
        {
            name: "No",
            _id: "No"
        }

    ];

    const optionsForSelectSalary = [

        {
            name: "Please Contact",
            _id: 1
        },

        {
            name: "Enter Your Amount",
            _id: 2
        },
    ];

    const genderRadioBtnOptionNeed = [
        {
            label: "Male",
            value: "Male"
        },
        {
            label: "Female",
            value: "Female"
        },

    ];

    const workTypeRadioBtnOption = [
        {
            label: "Live in",
            value: "Live in"
        },
        {
            label: "Live in & out",
            value: "Live in & out"
        },
        {
            label: "Live out",
            value: "Live out"
        }

    ];

    const genderRadioBtnOptionOffer = [
        {
            label: "Male",
            value: "Male"
        },
        {
            label: "Female",
            value: "Female"
        },


    ];

    console.log(dynamicData, "dynamicData454554dgd");


    const [locationResponseData, setLocationResponseData] = useState(false);

    const [formData, updateFormData] = useState({

        careType: '',
        title: "",
        work_type: "",
        prefered_gender: "",
        other_langauge_for_want: "",
        other_langauge_for_offer: "",
        transport_facilty: "",
        service_from_date: "",
        salary_option: "",
        is_contact: false,
        expected_salary: null,
        expected_salary_rate: dynamicData?.expected_salary_rate_need?.[0] || `/hour`,
        description: "",
        tagline: [],
        currency: dynamicData?.currency?.[0],
        location: "",
        image: [],
        photos: [],
        latitude: "",
        longitude: "",
        adsType:"",
        

        name: JSON.parse(localStorage.getItem(`user`))?.name,
        email_address: JSON.parse(localStorage.getItem(`user`))?.email_address,
        primary_phone_number: JSON.parse(localStorage.getItem(`user`))?.phone_number,
        secondary_phone_number: "",
        website: "",
        hide_secondary_phone_number: false,
        hide_primary_phone_number: false,
        status:""

    });
    const [lat, setlat] = useState()
    const [lng, setlng] = useState()


    const {


        careType,
        title,
        work_type,
        prefered_gender,
        transport_facilty,
        other_langauge_for_want,
        other_langauge_for_offer,
        service_from_date,
        salary_option,
        currency,
        expected_salary,
        expected_salary_rate,
        descriptions,
        tagline,
        location,
        location_name,
        latitude,
        longitude,
        image,
        photos,
        adsType,


        // orginizerEmailAddress,
        // orginizerName,

        name,
        email_address,
        primary_phone_number,
        secondary_phone_number,
        hide_primary_phone_number,
        hide_secondary_phone_number,
        status

    } = formData;


    console.log(careType, "cscvdgedhydvwedvbaby");



    const [isHideMobileNumberChecked, toggleIsHideMobileNumberChecked] = useState(false),
        [isHideEmailAddressChecked, toggleIsHideEmailAddressChecked] = useState(false),
        [isHideEmailAddress1Checked, toggleIsHideEmailAddress1Checked] = useState(false);


    // lister info
    const hideEmailNumberSwitchButtonHandler = (e) => {

        const inputName = e?.target?.name;

        if (inputName === `hide_secondary_phone_number`) toggleIsHideEmailAddressChecked(!isHideEmailAddressChecked)
        if (inputName === `hide_primary_phone_number`) toggleIsHideMobileNumberChecked(!isHideMobileNumberChecked)
        if (inputName === `hide_my_email_address`) toggleIsHideEmailAddress1Checked(!isHideEmailAddress1Checked)

        console.log(isHideEmailAddressChecked, "trrererer")

    }


    const onChangeForDate = (date, dateString) => {

        updateFormData({
            ...formData,
            service_from_date: dateString
        })

    };

    function disabledDate(current) {
        return current && current < moment().startOf('day');
        // Disable all dates before today's date
    }

    const [ageGroupArr, updateAgeGroupArr] = useState([]);
    const [selectedType,setselectedType]=useState('');
    const [selectedAge,setselectedAge]=useState([]);
    const [ageGroupArrForOffer, updateAgeGroupArrForOffer] = useState([]);
    const [careServiceArr, updateCareServiceArr] = useState([]);
    const [careServiceArrForOffer, updateCareServiceArrForOffer] = useState([]);
    const [preferedLangaugeArr, updatePreferedLangaugeArr] = useState([]);
    const [preferedLangaugeArrForOffer, updatePreferedLangaugeArrForOffer] = useState([]);
    const [value, setValue] = useState('');
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState("");
  
    // Handle typing in the input field (with character limit)
    const handleTagInputChange = (e) => {
      const value = e.target.value;
      if (value.length <= 25) {
        setInputValue(value);  // Only update the input if the length is <= 25
      }
    };
  
    // Handle adding a tag (either pressing Enter or clicking the Add button)
    const handleAddTag = () => {
      if (inputValue.trim() && !tags.includes(inputValue)) {
        updateFormData((prevData) => ({
          ...prevData,
          tagline: [...prevData.tagline, inputValue],  // Append to tagline array
        }));
        setTags([...tags, inputValue]);  // Add the tag to the list
        setInputValue("");  // Clear the input field
      }
    };
  
    // Handle tag removal
    const handleRemoveTag = (tagToRemove) => {
      setTags(tags.filter(tag => tag !== tagToRemove));  // Remove the tag from the list
    };
  
    // Handle the Enter key press
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        handleAddTag();
      }
    };

    console.log("jjjj", value)
    const handleChangeCheckkBox = (e) => {

        // Destructuring
        const { value, checked } = e.target;    

        console.log(`e.target.value check`, value,e.target.name,checked,careType)
        if (careType === '652d0a2d8467c8dbf3e160f0') {
            // Case 1 : The user checks the box
            console.log("case1")
            if (checked && e.target.name === `age_group`) {

                console.log(`e.target.value checking`, e.target.value)

                if (value.toLocaleLowerCase() === `any age`) updateAgeGroupArr([value]);
                else {
                    const filteredData = ageGroupArr.filter((data) => data.toLocaleLowerCase() !== `any age`)
                    updateAgeGroupArr([...filteredData, value]);
                }
            }
            // Case 2  : The user unchecks the box
            else {
                console.log("else");
                updateAgeGroupArr(ageGroupArr.filter((e) => e !== value));
            }

            if (checked && e.target.name === `care_service`) {
                updateCareServiceArr([...careServiceArr, value]);
            }
            // Case 2  : The user unchecks the box
            else {
                updateCareServiceArr(careServiceArr.filter((e) => e !== value));
            }

            if (checked && e.target.name === `prefered_languge`) {
            updatePreferedLangaugeArr([...preferedLangaugeArr, value]);
            }
            else {
                updatePreferedLangaugeArr(preferedLangaugeArr.filter((e) => e !== value));
            }


            // Case 2  : The user unchecks the box
        } else {
            console.log(`working`)
            // Case 1 : The user checks the box
            if (checked && e.target.name === `age_group`) {
                // updateAgeGroupArrForOffer([...ageGroupArrForOffer, value]);
                if (value.toLocaleLowerCase() === `any age`) updateAgeGroupArrForOffer([value]);
                else {
                    const filteredData = ageGroupArrForOffer.filter((data) => data.toLocaleLowerCase() !== `any age`)
                    updateAgeGroupArrForOffer([...filteredData, value]);
                }
            }
            // Case 2  : The user unchecks the box
            else {
                updateAgeGroupArrForOffer(ageGroupArrForOffer.filter((e) => e !== value));
            }

            if (checked && e.target.name === `care_service`) {
                updateCareServiceArrForOffer([...careServiceArrForOffer, value]);
            }
            // Case 2  : The user unchecks the box
            else {
                updateCareServiceArrForOffer(careServiceArrForOffer.filter((e) => e !== value));
            }

            if (checked && e.target.name === `prefered_languge`) {

                updatePreferedLangaugeArrForOffer([...preferedLangaugeArrForOffer, value]);
            }
            // Case 2  : The user unchecks the box
            else {
                updatePreferedLangaugeArrForOffer(preferedLangaugeArrForOffer.filter((e) => e !== value));
            }
        }
    };

    useEffect(()=>{
        console.log("ageGroupArr",ageGroupArr);
    },[ageGroupArr])



    // checking option other is selected for prefier langauge or not
    const isOtherOptionSelectedInPreLangWant = useMemo(() => preferedLangaugeArr.includes("Other"), [preferedLangaugeArr]),
    isOtherOptionSelectedInPreLangOffer = useMemo(() => preferedLangaugeArrForOffer.includes("Other"), [preferedLangaugeArrForOffer]);
    console.log(preferedLangaugeArrForOffer)



    // const onBlurHandlerform = (e) => {
    //     const { name, value } = e.target;
    //     if (name === "other_langauge_for_offer") {
    //         setValue(e.target.value)
    //         console.log(e.target.value,"e.target.value")
    //     }

    // };
    const onChangeHandlerForForm = (e) => {

        const inputName = e?.target?.name || e?.name;
        let inputValue = e?.target?.value || e?.value;


        const { name, value } = e.target;

        console.log("first check",name,value);

        if (name === 'salary_option' && value === '1') {
            
            updateFormData({ ...formData, [name]: value, is_contact: true });
        } else {
            updateFormData({ ...formData, [name]: value, is_contact: false });
        }



        //    if(name==="other_langauge_for_offer"){
        //        setValue(e.target.value)
        //    }



        //    if (inputName === "other_langauge_for_offer") {
        //     // Handle the input for "Other" language when selected
        //     if (isOtherOptionSelectedInPreLangOffer) {
        //       // Split the input by commas and trim each language
        //     //   const languages = inputValue.split(',').map((language) => language.trim());
        //       // Update the selectedLanguages array


        //         updatePreferedLangaugeArrForOffer([...preferedLangaugeArrForOffer, inputValue]);

        //     }
        //   }


        // if (inputName === "hide_primary_phone_number") {
        //     updateFormData((prev) => ({
        //         ...prev,
        //         hide_primary_phone_number: !hide_primary_phone_number
        //     }));
        // } else if (inputName === "hide_secondary_phone_number") {
        //     updateFormData((prev) => ({
        //         ...prev,
        //         hide_secondary_phone_number: !hide_secondary_phone_number
        //     }));
        // } else {


        if (inputName === 'expected_salary') {
            // let inputValue1 = e.target.value.replace(/\D/g, '');
            let inputValue1 = e.target.value.replace(/[^0-9.]/g, '');

            if (/^0+$|^0+[1-9][0-9]*$/.test(inputValue1)) {
                setErrorMessage("Please enter a valid amount.");
            } else {
                setErrorMessage('');
            }

            if (inputValue1.length > 7) {
                inputValue1 = inputValue1.slice(0, 7);
            }

            inputValue = inputValue1;
        }


        if (inputName === `primary_phone_number` || inputName === `secondary_phone_number`) {
            const inputNumber1 = e.target.value.replace(/\D/g, ""); // Remove non-digit characters

            if (inputNumber1.length <= 15) {
                updateFormData((prev) => ({
                    ...prev,
                    [inputName]: inputValue
                }));
            }
        } else {

            updateFormData((prev) => ({
                ...prev,
                [inputName]: inputValue
            }));
        }

    }

    const handleFormSubmit = async (e) => {
        console.log("careType",careType);
        e.preventDefault();
        toast.dismiss();

        localStorage.removeItem("start_date");
        localStorage.removeItem("end_date");
        // alert(`Steps:${steps}`);

        if (steps === 3) return toggleModalVisibilty("babySitterAndNanny", false);

        const missingData = [],
            invalidData = [];

        let missingDataMessageForserviceFromDate = ``,
            salary = ``;


        if (careType === '652d0a2d8467c8dbf3e160f0') {
            missingDataMessageForserviceFromDate = `Needed from`
            salary = `Salary`;
        } else {
            missingDataMessageForserviceFromDate = `Aveliable from`;
            salary = `Expected Salary Amount`
        }

        if (steps === 0) {
            console.log("careType",careType);
            if (!isTruthyString(careType)) missingData.push(`type`);
            if (!isTruthyString(title)) missingData.push(`Title`);
            if (work_type && !isTruthyString(work_type)) missingData.push(`work type`)
            if (transport_facilty && !isTruthyString(transport_facilty)) missingData.push(`Transport facilty`);
            if (!isTruthyString(prefered_gender)) missingData.push(`Preferred Gender`);
            if (service_from_date && !isTruthyString(service_from_date)) missingData.push(missingDataMessageForserviceFromDate);

            if (!salary_option) missingData.push(`Salary option`)

            if (Number(salary_option) === 2 && !expected_salary) missingData.push(salary);
            else if (Number(salary_option) === 2 === 2 && expected_salary && isNaN(expected_salary)) invalidData.push(salary);

            if (!isTruthyString(expected_salary_rate)) missingData.push(`${salary} salary rate`)

            // if (tagline && !isTruthyString(tagline)) missingData.push(`tagline`);
            // if (!isTruthyString(location)) missingData.push(`location`);

            // if (!isTruthyString(location_name)) missingData.push('location');
            // else if (!locationResponseData && !adsIds) {
            //     return toast.error('Please select a location from the suggestions');
            // }
           

            if (/^0+$|^0+[1-9][0-9]*$/.test(expected_salary)) {
                return toast.error(`Please enter a valid amount`);
            }

            if (!isTruthyString(descriptions)) missingData.push(`description`);
            else if (isDescriptionHas5Words(descriptions)) {
                return toast.error("You must write at least 5 words in your description");
            }

            if (careType == '652d0a2d8467c8dbf3e160f0') {
                if (ageGroupArr && ageGroupArr.length === 0) missingData.push(`Age group`);
                if (isOtherOptionSelectedInPreLangWant && !isTruthyString(other_langauge_for_want)) missingData.push(`Other language`);

            } else if (careType == '652d0a458467c8dbf3e160f3') {

                if (isOtherOptionSelectedInPreLangOffer && !isTruthyString(other_langauge_for_offer)) missingData.push(`Other language`);
                if (ageGroupArrForOffer && ageGroupArrForOffer.length === 0) missingData.push(`Age group`);
            }
            if (/^0+$|^0+[1-9][0-9]*$/.test(expected_salary)) {
                return toast.error(`Please enter a valid amount`);
            }
        }

        else if (steps === 1) {

            if (!(name || email_address || primary_phone_number || secondary_phone_number || primary_phone_number)) return toast.error(`PLlease provide all data`)

            if (!isTruthyString(name)) missingData.push(`Name`);
            if (!email_address) missingData.push(`Email address`);
            else if (email_address && !isValidEmailAddress(email_address)) invalidData.push(`Email address`);

            if (!primary_phone_number) missingData.push(`Primary phone number`);
            else if (primary_phone_number && isNaN(primary_phone_number)) invalidData.push(`Primary phone number`);
            else if (primary_phone_number && (primary_phone_number.length < 10 || primary_phone_number.length > 15)) invalidData.push(`Primary phone number`);

            if (secondary_phone_number && isNaN(secondary_phone_number)) invalidData.push(`Secondary phone number`);
            else if (secondary_phone_number && (secondary_phone_number.length < 10 || secondary_phone_number.length > 15)) invalidData.push(`secondary phone number`);

            if ((secondary_phone_number && secondary_phone_number) && primary_phone_number === secondary_phone_number) return toast.error(`Primary phone number can not be same as secondary phone number`)

        }

        
        // Show errors if needed
        if (missingData.length || invalidData.length) {
            if (missingData.length) toast.error(`Missing Data: - ${missingData.join(`, `)}`);
            if (invalidData.length) toast.error(`Invalid Data:-  ${invalidData.join(`, `)}`);
            return;
        } else if (steps !== 0 && steps !== 1) {
            updateSteps(steps + 1);
        }

        if(steps == 1 && adsIds && status === "active"){
            toast.success("Ad edit successfully");
            window.location.reload();
        }

        let formDataObj = {
            ads_type: adsCategoriesId || adsType,
            category_value: 1,
            category_name: careType,

        };
        
    // debugger
    if(careType === "652d0a2d8467c8dbf3e160f0" || careType === '652d0a2d8467c8dbf3e160f0')
        if (careType) formDataObj.careType = '652d0a2d8467c8dbf3e160f0';
        if (images.length) formDataObj.photos = images;
        // if (Array.isArray(image)) {
        //     formDataObj.image = image;
        //   }

        if (title) formDataObj.title = title;
        if (work_type) formDataObj.work_type = work_type;
        if (transport_facilty) formDataObj.transport_facilty = String(transport_facilty).toLocaleLowerCase() === `yes` ? true : false;

        if (careType === '652d0a2d8467c8dbf3e160f0'|| careType === '652d0a2d8467c8dbf3e160f0') {
            console.log("in babysitter want");

            // const updatedPreferedLangaugeArr = [...preferedLangaugeArr];
            // if (isOtherOptionSelectedInPreLangWant) updatedPreferedLangaugeArr.push(other_langauge_for_want)
            // if (updatedPreferedLangaugeArr && updatedPreferedLangaugeArr.length) formDataObj.prefered_language = updatedPreferedLangaugeArr;

            const updatedPreferedLangaugeArr = [...preferedLangaugeArr];
            // Check if the "Other" option is selected
            const isOtherOptionSelectedInPreLangWant2 = updatedPreferedLangaugeArr.includes('Other');
            
            // Remove "Other" from the array
            const filteredPreferedLangaugeArr = updatedPreferedLangaugeArr.filter(language => language !== 'Other');
            
            // If "Other" was selected, add it back to the array
            if (isOtherOptionSelectedInPreLangWant2) {
                filteredPreferedLangaugeArr.push(other_langauge_for_want);
            }
            
            // Assign the updated array to formDataObj.prefered_language
            formDataObj.prefered_language = filteredPreferedLangaugeArr;


            if (careServiceArr && careServiceArr.length) formDataObj.care_service = careServiceArr
            if (ageGroupArr && ageGroupArr.length>0) {console.log("yes called",ageGroupArr);formDataObj.age_group=ageGroupArr}
            
        } else if (careType == '652d0a458467c8dbf3e160f3') {

            // const updatedPreferedLangaugeArrForOffer = [...preferedLangaugeArrForOffer];
            // if (isOtherOptionSelectedInPreLangOffer) updatedPreferedLangaugeArrForOffer.push(other_langauge_for_offer)
            // if (updatedPreferedLangaugeArrForOffer && updatedPreferedLangaugeArrForOffer.length) formDataObj.prefered_language = updatedPreferedLangaugeArrForOffer

            const updatedPreferedLangaugeArrForOffer = [...preferedLangaugeArrForOffer];

            // Check if the "Other" option is selected
            const isOtherOptionSelectedInPreLangOffer2 = updatedPreferedLangaugeArrForOffer.includes('Other');

            // Remove "Other" from the array
            const filteredPreferedLangaugeArrForOffer = updatedPreferedLangaugeArrForOffer.filter(language => language !== 'Other');

            // If "Other" was selected, add it back to the array
            if (isOtherOptionSelectedInPreLangOffer2) {
                filteredPreferedLangaugeArrForOffer.push(other_langauge_for_offer);
            }

            // Assign the updated array to formDataObj.prefered_language
            formDataObj.prefered_language = filteredPreferedLangaugeArrForOffer;



            if (careServiceArrForOffer && careServiceArrForOffer.length) formDataObj.care_service = careServiceArrForOffer
            if (ageGroupArrForOffer && ageGroupArrForOffer.length) formDataObj.age_group = ageGroupArrForOffer
        }

        if (prefered_gender) formDataObj.prefered_gender = prefered_gender;

        if (expected_salary) formDataObj.amount = expected_salary;
        if (tagline) formDataObj.tagline = tagline;
        if (expected_salary_rate) formDataObj.expected_salary_rate = expected_salary_rate;
        if (service_from_date) formDataObj.service_from_date = service_from_date;
        if (location_name) formDataObj.location_name = location_name;
        if (latitude) formDataObj.latitude = latitude;
        if (longitude) formDataObj.longitude = longitude;
        if (descriptions) formDataObj.description = descriptions;
        if (currency) formDataObj.currency = currency;
        if (salary_option) formDataObj.salary_option = salary_option;



        // lsiter detail;
        if (name) formDataObj.name = name;
        if (email_address) formDataObj.email_address = email_address;
        if (primary_phone_number) formDataObj.primary_phone_number = primary_phone_number;
        if (secondary_phone_number) formDataObj.secondary_phone_number = secondary_phone_number;
        if (isHideMobileNumberChecked) formDataObj.hide_my_phone = isHideMobileNumberChecked;
        if (isHideEmailAddressChecked) formDataObj.hide_my_secondary_phone = isHideEmailAddressChecked;

        console.log(formDataObj,"formDataObjformDataObj")
      


        const babbiysitterAndNannyFormData = new FormData();

        if((adsId || adsIds) && status === 'draft'){
            // alert("infirsty")
            steps ===1 && selectedFiles.forEach((file, index) => {
              babbiysitterAndNannyFormData.append('photos', file);
            })
          }
          if(!((adsId || adsIds) && status === 'draft')){
            // alert("insecond")
            steps ===1 && selectedFiles.forEach((file, index) => {
              babbiysitterAndNannyFormData.append('photos', file);
            })
        }
          if(status === 'active'){
            // alert("inthird")
            steps ===0 && selectedFiles.forEach((file, index) => {
              babbiysitterAndNannyFormData.append('photos', file);
            })
          }


        // babbiysitterAndNannyFormData.append('photos', selectedFile);

        const data = formDataAppender(formDataObj, babbiysitterAndNannyFormData);


        // data.append(photos,images.files[0])

        for (const pair of data.entries()) {
            console.log(`${pair[0]}, ${pair[1]}`);
        }


        const updatedFetchConfig = fetchConfig;
        delete updatedFetchConfig.headers['Content-Type'];

        if (steps === 0 && !babysitterAndNannyId && !adsIds) {
            

            toggleLoader(true);

            const updatedFetchConfig = fetchConfig;
            delete updatedFetchConfig.headers['Content-Type'];

            fetch(`${appURL}/posts/babysitter/add`, {
                ...updatedFetchConfig,
                body: data
            })
                .then(handleFetchErrors)
                .then((res) => {
                    toggleLoader(false)
                    if (res?.status == 200) {
                        toggleLoader(false)
                        const BabysitterNanniesObjectId = res?.Babysitter_Nannies?._id

                        updateSteps(steps + 1);
                        UpdateBabysitterAndNannyId(BabysitterNanniesObjectId)

                        localStorage.setItem('adsInfo', JSON.stringify({
                            id: res?.Babysitter_Nannies?._id,
                            title: title,
                            adsType: "BabbysitterAndNanny",
                            adsCategories: adsCategoriesId,
                            // advertisement : advertisement_id,

                        }));


                    } else {
                        return toast.error(res?.message);
                    }
                    console.log(`resresresres10010101`, res)


                })
                .catch((err) => {
                    toggleLoader(false);
                    console.log(err)
                });
        } else if ((steps === 1 || steps === 0) && babysitterAndNannyId || adsIds) {
        
            toggleLoader(true);

            const updatedFetchConfig = fetchConfig;
            delete updatedFetchConfig.headers['Content-Type'];

            // babbiysitterAndNannyFormData.delete('photos');

            fetch(`${appURL}/posts/babysitter/edit/${babysitterAndNannyId || adsIds}`, {
                ...updatedFetchConfig,
                method: "PATCH",
                body: data
            })
                .then(handleFetchErrors)
                .then((res) => {
                    console.log(res)
                    console.log(res.Babysitter_Nannies.advertisement_id, "my_new_id");
                    localStorage.setItem('advertisementId', res.Babysitter_Nannies.advertisement_id)
                    toggleLoader(false)
                    if (res?.status === 200) {
                        toggleLoader(false)
                        updateSteps(steps + 1);
                        // if(steps==2){
                        //     // if(status){}
                        //     // setReplacerental(false)
                        //     setadsIds("")
                        //     // toggleModalForPostAdscategories(false)
                        // }

                        // if(steps==1){
                        //     setadsIds("")
                        // }

                    } else {
                        return toast.error(res?.message);
                    }
                    // console.log(`resresresres`, res)
                })
                .catch((err) => {
                    toggleLoader(false);
                    console.log(err)
                });
        }

    };


    console.log(preferedLangaugeArr,)


    // // fetching ads categories
    const fetchingDynamicsData = () => {

        fetch(`${appURL}/posts/babysitter/dynamics-data?PostType=${'64e48f6af9eeb14b6e2135b6'}`, {
            ...fetchConfig,
            method: "GET"
        })
            .then(handleFetchErrors)
            .then((res) => {

                if (res.status === 200) {
                    const dynamicDataObj = res?.data;
                    if (dynamicDataObj) updateDynamicData(dynamicDataObj)

                } else {
                    return toast.error(`Enable fetch dynamics data`);
                }

            })
            .catch((err) => standardFetchHandlers.error(err));
    }

    const backBtnClickHandler = () => {
        if (steps === 0) return toggleModalVisibilty("babySitterAndNanny", false);
        updateSteps(steps - 1)
    }

    const callBackFunction = (address = '', lat = '', lng = '') => {
        updateFormData({
            ...formData,
            location_name: address,
            latitude: lat,
            longitude: lng,
        });
    }


    const handleFileChange = (event) => {
        const files = event.target.files;
        setSelectedFiles([...selectedFiles, ...files]);
    };


    const handlingClearSelectInput = (name) => {

        switch (name) {
            case "recurring_type":
                updateFormData({
                    ...formData,
                    recurring_type: ``
                })
                break;

            case "work_type":
                updateFormData({
                    ...formData,
                    work_type: ``
                })
                break;

            case "transport_facilty":
                updateFormData({
                    ...formData,
                    transport_facilty: ``
                })
                break;


            default:
                break;
        }

    }

    useEffect(()=>{
        console.log("careType sourabh",careType);
    },[careType])


    const fetchingAdsById = () => {

        const actualAdsId = adsId || adsIds; 
        
        fetch(`${appURL}/posts/babysitter/ad_details?adsId=${actualAdsId}`, {
            ...fetchConfig,
            method: "GET"
        })
            .then(handleFetchErrors)
            .then((res) => {
                console.log(`res`, res);

                if (res?.status == 200) {
                    updateFormData((prev) => ({
                        ...prev,
                        careType: res?.ads_details?.adsInfo?.category?.category_name?._id
                    }));
                    console.log("check careType",res?.ads_details?.adsInfo?.category?.category_name?._id,"----",careType);
                    const data = res?.ads_details, 
                        listerInfo = data?.lister_basic_info,
                        adsInfo = data?.adsInfo;
                        setTags(adsInfo?.tagline)
                        setmyadsId(res?.ads_details?._id);
                        setmyadsType(res?.ads_details?.adsType);
                        setSelectedFiles(res?.ads_details?.adsInfo?.image);
                        setselectedType(res?.ads_details?.adsInfo?.category?.category_name?.name);
                        // const values=res?.ads_details?.adsInfo?.age_group?.map(item=>item);
                        setselectedAge(res?.ads_details?.adsInfo?.age_group);
                        // setselectedAge(res?.ads_details?.adsInfo?.age_group);
                        // setselectedType(adsInfo?.categories?.name)
                        updateAgeGroupArr(adsInfo?.age_group)
                    
                        updateAgeGroupArrForOffer(adsInfo?.age_group)

                        updateCareServiceArr(adsInfo?.care_service)
                        updateCareServiceArrForOffer(adsInfo?.care_service)

                        updatePreferedLangaugeArr(adsInfo?.prefered_language)
                        updatePreferedLangaugeArrForOffer(adsInfo?.prefered_language)
                  

                    const dataObj = {
                        ...formData,
                        title: adsInfo?.title,
                        category: adsInfo?.category?.category_name,
                        work_type: adsInfo?.work_type,
                        // descriptions: adsInfo?.descriptions,
                        location_name:adsInfo?.location?.location_name,
                        latitude:adsInfo?.location?.coordinates[1],
                        longitude:adsInfo?.location?.coordinates[0],
                        
                        careType: adsInfo?.category?.category_name?._id,
                        // work_type: "",
                        prefered_gender: adsInfo?.prefered_gender,
                        other_langauge_for_want: adsInfo?.prefered_language,
                        other_langauge_for_offer: adsInfo?.prefered_language,
                        transport_facilty: adsInfo?.transport_facilty === false ? "No" : 'Yes',
                        service_from_date: "",
                        salary_option: adsInfo?.expected_salary_amount?.is_contact === false ? "Please Contact" : "Enter Your Amount",
                        expected_salary: null,
                        expected_salary_rate: adsInfo?.expected_salary_rate || `/hour`,
                        descriptions: adsInfo?.descriptions || "",
                        tagline: adsInfo?.tagline || [],
                        currency: adsInfo?.currency || `/hour`,
            
                        image: adsInfo?.image || [],
                        photos: adsInfo?.photos || [],
                       

                        name: listerInfo?.name !== ""?listerInfo?.name:JSON.parse(localStorage.getItem("user"))?.name,
                        email_address: JSON.parse(localStorage.getItem(`user`))?.email_address,
                        primary_phone_number: listerInfo?.primary_mobile_number?.primary_phone_number !== ""?listerInfo?.primary_mobile_number?.primary_phone_number:JSON.parse(localStorage.getItem("user"))?.phone_number,
                        secondary_phone_number: listerInfo?.secondary_mobile_number?.secondary_phone_number || "",
                        website: listerInfo?.webiste || "",
                        hide_secondary_phone_number: listerInfo?.hide_my_secondary_phone || false,
                        hide_primary_phone_number: listerInfo?.hide_my_phone || false,
                        adsType:data?.adsType,
                        status:data.status,


                    }
                    console.log("dataObj",dataObj)
                

                    updateFormData(dataObj)

                    

                }
            })
            .catch((err) => {

                console.log(err)
            });
    }
    console.log(ageGroupArr, "image.png");


    console.log(adsType, "adsType5656565665");

    useEffect(() => {
        fetchingDynamicsData();
        if (adsId || adsIds) fetchingAdsById();
    }, [adsId || adsIds]);

    useEffect(()=>{
        console.log("selectedGae",selectedAge);
    },[selectedAge])

    let HeadingMessage = `Post your Ad`;
    let SubHeadingMessage = ``;

    if (steps === 2) {
        HeadingMessage = `Post your Ad`
    } else if (steps === 3) {
        HeadingMessage = ``
    } else if (steps === 1) {
        SubHeadingMessage = `  Basic Information`
    }



    const abcfunction = (data, callback) => {
        setlat(data.lat)
        setlng(data.lng)

        console.log(data.lat, data.lng, "Nothing")

        callback();
    }

    // useEffect(() => {
    //     if (value) {
    //         updatePreferedLangaugeArrForOffer([...preferedLangaugeArrForOffer, value]);
    //        const newarray  = preferedLangaugeArrForOffer.filter(item => item !== 'Other');
    //        updatePreferedLangaugeArrForOffer(newarray)
    //     }

    // }, [value]);

    return (
        <main className={style.main_wrapper}>

<PostAdsHeading
  headingText={
    steps === 1 && status === 'active' ? 'Update Your Ad' : HeadingMessage
  }
  subHeadingText={SubHeadingMessage}
/>
            <form id={style.room_rents_form} >

                {
                    (() => {

                        if (steps === 0) {
                            return (
                                <>
                                {!(adsId || adsIds) || status === 'draft'?
                                    <Inputs
                                        name='careType'
                                        value={careType}
                                        onChangeHandler={onChangeHandlerForForm}
                                        label='Choose an option'
                                        type='radio'
                                        id='span-2'
                                        required={true}
                                        // radioBtnOtions={dynamicData?.category}
                                        radioBtnOtions={dynamicData && dynamicData.category ? dynamicData.category.map(cat => ({ label: cat.name, value: cat._id })) : []}
                                        disabled={status !== "draft" && (adsIds ? true : false)}
                                        
                                    />
                                :<Inputs
                                name='Selected Type'
                                required={true}
                                value={selectedType}
                                label='Selected Type'
                                type='text'
                                id='span-2'
                                placeholder='Enter ad title'
                                disabled={status !== "draft" && (adsIds ? true : false)}
                            />}

                                    <Inputs
                                        name='title'
                                        required={true}
                                        value={title}
                                        onChangeHandler={onChangeHandlerForForm}
                                        label='Title'
                                        type='text'
                                        id='span-2'
                                        placeholder='Enter ad title'
                                    />
                                    
                                    <Inputs
                                        name='age_group'
                                        onChangeHandler={handleChangeCheckkBox}
                                        label={careType === '652d0a2d8467c8dbf3e160f0' ? 'Which age groups do you need care for?' : 'Which age groups do you prefer to care?'}
                                        type='checkbox'
                                        selectOptionArrForCheckBox={careType === '652d0a2d8467c8dbf3e160f0' ? selectedAge : ageGroupArrForOffer}
                                        id='span-2'
                                        required={true}
                                        checkboxInputOtions={careType === '652d0a2d8467c8dbf3e160f0' ? dynamicData?.age_group_need : dynamicData?.age_group_offer}
                                    />

                                    {/* <Inputs
                                        name='care_service'
                                        onChangeHandler={handleChangeCheckkBox}
                                        label={careType === '652d0a2d8467c8dbf3e160f0' ? 'What Services do you need?' : 'What Services do you offer?'}
                                        type='checkbox'

                                        selectOptionArrForCheckBox={careType === '652d0a2d8467c8dbf3e160f0' ? careServiceArr : careServiceArrForOffer}
                                        id='span-2'
                                        // checkboxInputOtions={careType === '652d0a2d8467c8dbf3e160f0' ? dynamicData?.care_service_need : dynamicData?.care_service_offer}

                                        // checkboxInputOtions={dynamicData.category.map(cat => 
                                        //         cat.name === '652d0a2d8467c8dbf3e160f0' ? // Check if the category name matches
                                        //             cat.subcategory.map(subcat => ({ label: subcat.name, value: subcat._id })) // If yes, map subcategories
                                        //             : cat.name === '652d0a458467c8dbf3e160f3' ? // Check if the category name matches
                                        //             cat.subcategory.map(subcat => ({ label: subcat.name, value: subcat._id })) // If yes, map subcategories
                                        //             : [] // If no matching category, return empty array
                                        //     ).flat()  // Flatten the array of arrays into a single array
                                        // }

                                        checkboxInputOptions={
                                            dynamicData && dynamicData.category ? 
                                            dynamicData.category.flatMap(cat => 
                                                cat.name === '652d0a2d8467c8dbf3e160f0' || cat.name === '652d0a458467c8dbf3e160f3' ? // Check if the category name matches either
                                                    cat.subcategory.map(subcat => ({ label: subcat.name, value: subcat._id })) // If yes, map subcategories
                                                    : [] // If no matching category, return empty array
                                            ) : []
                                        }
                                        
                                    
                                        
                                        
            
                                    /> */}
                                  
                                    <Inputs
                                        name='prefered_languge'
                                        selectOptionArrForCheckBox={careType === '652d0a2d8467c8dbf3e160f0' ? preferedLangaugeArr : preferedLangaugeArrForOffer}
                                        onChangeHandler={handleChangeCheckkBox}
                                        label={careType === '652d0a2d8467c8dbf3e160f0' ? 'Preferred language' : 'What language do you speak?'}
                                        type='checkbox'
                                        id='span-2'
                                        checkboxInputOtions={careType === '652d0a2d8467c8dbf3e160f0' ? dynamicData?.preferrd_languge_need : dynamicData?.preferrd_languge_offer}
                                    />

                                    {
                                        careType === '652d0a2d8467c8dbf3e160f0' && isOtherOptionSelectedInPreLangWant &&
                                        <Inputs
                                            id='span-2'
                                            name='other_langauge_for_want'
                                            value={other_langauge_for_want}
                                            // onChangeHandler={onChangeHandlerForForm}
                                            onChangeHandler={(e) => {
                                                const inputValue = e.target.value;
                                                // Limit the input to 15 characters
                                                if (inputValue.length <= 15) {
                                                  onChangeHandlerForForm(e);
                                                }
                                              }}
                                            placeholder='Enter other language'
                                            label='Other language'
                                            required={true}
                                            type='text'
                                        />
                                    }

                                    {
                                        careType === '652d0a458467c8dbf3e160f3' && isOtherOptionSelectedInPreLangOffer &&
                                        <Inputs
                                            id='span-2'
                                            name='other_langauge_for_offer'
                                            value={other_langauge_for_offer}
                                            // onChangeHandler={onChangeHandlerForForm}
                                            onChangeHandler={(e) => {
                                                const inputValue = e.target.value;
                                                // Limit the input to 15 characters
                                                if (inputValue.length <= 15) {
                                                  onChangeHandlerForForm(e);
                                                }
                                              }}
                                            // onBlurHandler={onBlurHandlerform}
                                            placeholder='Enter other langauge'
                                            label='Other langauge'
                                            required={true}
                                            type='text'
                                        />
                                    }

                                    <Inputs
                                        name='prefered_gender'
                                        id='span-2'
                                        value={prefered_gender}
                                        onChangeHandler={onChangeHandlerForForm}
                                        label={careType === '652d0a2d8467c8dbf3e160f0' ? 'Preferred gender' : 'Your gender'}
                                        type='radio'
                                        required={true}
                                        radioBtnOtions={careType === '652d0a2d8467c8dbf3e160f0' ? dynamicData?.preferrd_gender_need : dynamicData?.preferrd_gender_offer}
                                    />

                                    <Inputs
                                        label='Work type'
                                        name='work_type'
                                        value={work_type}
                                        // handlingClearSelectInput={handlingClearSelectInput}
                                        handlingClearSelectInput={handlingClearSelectInput}
                                        onChangeHandler={onChangeHandlerForForm}
                                        type='select2'
                                        id='span-2'
                                        placeholder='Select'
                                        selectInputOptions={dynamicData?.work_type}
                                    />




                                    <Inputs
                                        label={careType === '652d0a2d8467c8dbf3e160f0' ? ' Do you provide transportation facility?' : 'Do you need transportation facility?'}
                                        name='transport_facilty'
                                        value={transport_facilty}
                                        // handlingClearSelectInput={handlingClearSelectInput}
                                        handlingClearSelectInput={handlingClearSelectInput}
                                        onChangeHandler={onChangeHandlerForForm}
                                        type='select2'
                                        id='span-2'
                                        placeholder='Select'
                                        selectInputOptions={transportfacilityRadioBtn}
                                    />

                                    <section>
                                        <Space direction="vertical">
                                            <span id='label'>{careType === '652d0a2d8467c8dbf3e160f0' ? 'Needed from(Optional)' : 'Available from(Optional)'}</span>
                                            <DatePicker defaultValue={service_from_date ? dayjs(service_from_date, dateFormat) : ``} disabledDate={(current) => disabledDate(current, 1)} className={style.date_picter_wrapper} placeholder="Enter Needed from" onChange={(date, dateString) => onChangeForDate(date, dateString, 1)} format={dateFormat} />
                                        </Space>
                                    </section>
                                  
                                    {
                                        <Inputs
                                            label={careType === '652d0a2d8467c8dbf3e160f0' ? 'Salary' : 'Expected Salary'}
                                            type='select'
                                            required={true}
                                            name='salary_option'
                                            value={salary_option}
                                            placeholder='Select salary'
                                            onChangeHandler={onChangeHandlerForForm}
                                            selectInputOptions={optionsForSelectSalary}
                                        />
                                    }

                                    {
                                        Number(salary_option) === 2 ?

                                            <div id='span-2'>
                                                <span id='label' >{careType === '652d0a2d8467c8dbf3e160f0' ? `Salary` : `Expected Salary Amount`} <span className="required_field">*</span></span>

                                                <div className={style.expected_salary_input_wrapper}>
                                                    <section>
                                                        <Inputs
                                                            inputId={style.currency_input_field}
                                                            type='select'
                                                            name='currency'
                                                            value={currency}
                                                            onChangeHandler={onChangeHandlerForForm}
                                                            selectInputOptions={dynamicData?.currency}
                                                        />
                                                    </section>
                                                    <section>
                                                        <Inputs
                                                            inputId={style.expected_salary_input}
                                                            type='number'
                                                            id='span-2'
                                                            required={true}
                                                            name='expected_salary'
                                                            value={expected_salary}
                                                            onChangeHandler={onChangeHandlerForForm}
                                                            placeholder={`Enter ${careType === '652d0a2d8467c8dbf3e160f0' ? `Salary` : `Expected Salary`} Amount`}
                                                        />
                                                        <div style={{ color: 'red', fontSize: "12px", marginTop: "10px" }}>{errorMessage}</div>
                                                    </section>
                                                    <section>
                                                        <Inputs
                                                            inputId={style.expected_salary_input_rate}
                                                            type='select'
                                                            name='expected_salary_rate'
                                                            value={expected_salary_rate}
                                                            onChangeHandler={onChangeHandlerForForm}
                                                            selectInputOptions={dynamicData?.expected_salary_rate_need}
                                                        />
                                                    </section>
                                                </div>


                                            </div> : ``

                                    }


                                    <SelectLocation callBackFunction={callBackFunction}
                                       location={location_name}
                                        latitude={latitude}
                                        longitude={longitude}
                                        updateFormData={updateFormData}
                                        locationResponseData={locationResponseData}
                                        setLocationResponseData={setLocationResponseData}
                                        formData={formData} required={true} />

                                    <RichTextEditor value={descriptions} updateFormData={updateFormData} formData={formData}label="Description" required={true} />


                                    <section id="span-2">
                    <div>
                      <label className="tagline-main">Tagline (optional)</label>
                      <div>
                        <input
                          type="text"
                          name="tagline"
                          value={inputValue}
                          onChange={handleTagInputChange}
                          onKeyPress={handleKeyPress}
                          placeholder="Enter tagline"
                        />
                        <button onClick={handleAddTag} style={{ display: "none" }} >Add Tag</button>
                      </div>

                      {/* Display tags */}
                      <div>
                        {tags.length  > 0 && (
                          <div>
                            {tags.map((tag, index) => (
                              <span key={index} style={{ margin: "5px", padding: "5px", border: "1px solid #ccc", display: "inline-block" }}>
                                {tag}
                                <button
                                  onClick={() => handleRemoveTag(tag)}
                                  style={{ marginLeft: "5px", cursor: "pointer", background: "red", color: "white", border: "none", borderRadius: "50%", width: "20px", height: "20px" }}
                                >
                                  ×
                                </button>
                              </span>
                            ))}
                          </div>
                        )}
                      </div>

                      {/* Display message if the limit is exceeded */}
                      {inputValue.length > 25 && <p style={{ color: 'red' }}>Tag must be less than 25 characters</p>}
                    </div>

                  </section>

                                    {/* <section id='span-2'>
                                        <MultiSelect
                                            label='Tagline'
                                            id='tagline'
                                            value={selectedTagline}
                                            allowCreate={selectedTagline.length <= 4 ? true : false}
                                            onCreate={onCreateteHandler}
                                            name='tagline'
                                            data={["test1", "test2", "test1", "test2", "test1", "test2", "test1", "test2"]}
                                            dataKey="short_id"
                                            textField="name_email"
                                            placeholder="Enter tagline"
                                            onChange={(admin) => upatedSelectedTagline(admin)}
                                        />
                                    </section> */}

                                    <ImageUpload images={images} updateImage={updateImage} handleFileChange={handleFileChange} setSelectedFiles={setSelectedFiles}
                                        selectedFiles={selectedFiles} myadsId={myadsId} myadsType={myadsType} />

                                    {/* <input type="file" name='photos' onChange={handleFileChange} 
                                      
                                    multiple/> */}

                                    {/* <div>
                                            {formData.image.map((imageUrl, index) => (
                                            <img key={index} src={imageUrl} alt={`image ${index}`} />
                                            ))}
                                            </div> */}

                                </>

                            )

                        }

                        else if (steps === 1) {
                            return <AdsListerInfo
                                onChangeHandler={onChangeHandlerForForm}
                                formData={formData}
                                isHideMobileNumberChecked={isHideMobileNumberChecked}
                                isHideEmailAddressChecked={isHideEmailAddressChecked}
                                isHideEmailAddress1Checked={isHideEmailAddress1Checked}
                                hideEmailNumberSwitchButtonHandler={hideEmailNumberSwitchButtonHandler}
                            />
                        }

                        else if (steps === 2 && !adsIds) {
                            return <AdsPlansUpdated
                                updateSteps={updateSteps}
                                steps={steps}
                                adsId={babysitterAndNannyId}
                                adsCategoriesId={adsCategoriesId}
                            />
                        }
                        else if (steps === 2 && adsIds && status!="active") {
                            return <AdsPlansUpdated
                                updateSteps={updateSteps}
                                steps={steps}
                                adsId={babysitterAndNannyId}
                                adsCategoriesId={adsCategoriesId || adsType}
                                adsIds={adsIds}
                            />
                        }
                        else if (steps === 3) {
                            return <CongratulationsScreen />
                        }

                    })()

                }

                {
                    (steps !== 3 && steps !== 2) && <FormButton cancelButtonText='Back' submitBtnText={ (steps === 1 && status === 'active') ? "Update My Ad" :
                        (steps === 2 && status === 'draft') ? "Post My Ad" :
                        "Next"} id="span-2" className={style.form_button_section} backBtnClickHandler=                   {backBtnClickHandler} onClickHandler={handleFormSubmit} showLoadder={showLoader} />      
                }
                
            </form>

        </main>
    )
}
