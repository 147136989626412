import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import appURLs from '../../appURL';
import getFetchConfig from '../../utils/getFetchConfig';
import ChatContent from './ChatContent/ChatContent';
import { useRef } from 'react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';


function Chat({selectedChat,newMessages,setnewMessages,listData,setSelectedChat,setdetailData}) {
  console.log("selectedChatSourabh",selectedChat);

  const checkAdsType=(adstype)=>{
    if(adstype === 'Buy & Sell' || adstype === 'babysitter & nannie' || adstype === 'Local_biz & Service'){
      return adstype.replace(/ /g, "+").replace(/&/g, "%26")
    }
    else{
      return adstype
    }
  }
  
  const ENDPOINT = 'https://menehariya-chat.netscapelabs.com/';
  // const ENDPOINT = 'https://chat.menehariya.com/';
  const adsId = localStorage.getItem("adsIdChat");
  const senderId  = localStorage.getItem("userIdchat");
  const sellerId  = localStorage.getItem("sellerIdChat");
  const ads_type = checkAdsType(localStorage.getItem("adsTypeChat"));
  const chat_id = localStorage.getItem("chatId");
  
  const appURL = appURLs();
  const fetchConfig = getFetchConfig();
  
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [socket, setSocket] = useState(null);
  const [chatDetailsData, setChatDetailsdata] = useState([]);
  const [loader,setloader]=useState(false);
  // const [chatMessages,setchatMessages]=useState([]);
  
  const [newMessageIds, setNewMessageIds] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);


  const [selectedFiles, setSelectedFiles] = useState([]);
  const location = useLocation();
  const { message } = location.state || {}; // Destructure message from the state

  console.log("message",message);
  
  
  const [checkOnlinestatus , setcheckOnlineStatus] = useState(false);
  const [mylistData,setmylistData]=useState([]);

  const [myMessages,setMyMessages]=useState([]);
  const prevMessageCount = useRef(myMessages.length);
  
  // const [adsId, setAds_Id] = useState('');
  // const [sellerId, setSellerId] = useState('');
  // const [senderId, setSenderId] = useState('');
  // const [buyerId, setBuyerId] = useState('');
  // const [ads_type, setAds_type] = useState('');

 // Function to handle file selection
 const handleFileSelect = (event) => {
  console.log("called file select");
  setSelectedFiles([...selectedFiles, ...event.target.files]);
};


const uploadFiles = async () => {
  console.log("called upload");
  setloader(true);
  console.log("selectedFiles", selectedFiles);
  setPreviewUrls([]);
  const fileUrls = [];

  for (const file of selectedFiles) {
    try {
      const formData = new FormData();
      console.log("eachFile", file);
      formData.append('photos', file);

      const token = localStorage.getItem("token");
      const headers = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      // Commenting out Content-Type as fetch API sets it automatically for FormData
      // headers['Content-Type'] = 'multipart/form-data';

      console.log("formData", formData);

      const response = await fetch(`${appURL}/chat/upload-file`, {
        method: 'POST',
        body: formData,
        headers: headers,
      });

      if (!response.ok) {
        throw new Error('File upload failed');
      }

      const data = await response.json();
      const fileUrl = data.url; // Assuming the server returns the file URL
      console.log("fileUrl",fileUrl);
      // return fileUrl;
      fileUrls.push(fileUrl);
      console.log(fileUrl, "fileUrl");
    } catch (error) {
      console.error('Error uploading file:', error);
      // Decide if you want to continue on error or break the loop
      // return []; // Uncomment if you want to stop on the first error
    }
  }
  console.log('fileUrls',fileUrls);
  return fileUrls;
};

useEffect(()=>{
  setmylistData(listData);
},[listData])





   
  const chatListData = async () => {
    // alert('hello');
    if(!sellerId){
      console.log("yes here",listData);
      setChatDetailsdata(listData[0]);
      setSelectedChat(listData[0]);
      
    let newArray=[];
    // const listData=await sendChatlistData();
    console.log("listData here",mylistData);
    listData?.map((item,index)=>{
    newArray.push({
      message:item?.messages[0]?.content,
      timeStamp:item?.messages[0]?.timestamp,
      user:JSON.parse(localStorage.getItem("user"))?.userId===item?.messages[0]?.senderId?"me":"other",
      type:'text',
      key:item?._id
    })
  })
  setMyMessages(Array.isArray(newArray) ? [...newArray].reverse() : []);
  return;
}
    {(myMessages.length >= prevMessageCount.current)&& setloader(true);}
    try {
      console.log("yes here",listData);
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };
      const response = await fetch(
        `${appURL}/chat/chat-details?ads_id=${adsId}&sellerId=${sellerId}&senderId=${senderId}&buyerId=${senderId}&ads_type=${ads_type}`,
        {
          ...fetchConfig,
          method: "GET",
          headers: headers,
        }
      );

      if (!response.ok) {
        setloader(false);
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      // const reversedData = reverseObject(jsonData.data);
      console.log("jsonData check",jsonData);
      if(jsonData.data?.status === 'active'){
        setcheckOnlineStatus(true);
      }
      setChatDetailsdata(jsonData.data);
      console.log(jsonData.data,"chatsel");
      if(selectedChat === null){
      setSelectedChat(jsonData.data);}
      let newMessagess=[];
      console.log("jsonData?.data?.messages",jsonData?.data?.messages,"--",newMessages?.length);
      if(newMessages?.length === 0 || newMessages?.length === undefined){
        console.log("here newmsg");
      jsonData?.data?.messages?.map((item,index)=>{
        console.log("inside yess",item?.content);
        newMessagess.push({
          message:item?.content,
          timeStamp:item?.timestamp,
          user:JSON.parse(localStorage.getItem("user"))?.userId===item?.senderId?._id?"me":"other",
          type:'text',
          key:item?._id
        })  
        // setMyMessages(jsonData?.data?.messages)
      })
      // setdetailData(true);
      setMyMessages(Array.isArray(newMessagess) ? [...newMessagess].reverse() : []);
      
    }

      // if()

      // if(chatMessages?.length === 0){
      // setchatMessages(jsonData?.data?.messages);}

      console.log(jsonData.data, "jsonData.data");

    console.log(jsonData, "chat list api");
  setloader(false);

    } catch (error) {
      // setdetailData(false);
      console.error("Error fetching data:", error);
      console.log("listDatadown",listData)

  //     setChatDetailsdata(listData[0]);
  //     setSelectedChat(listData[0]);
      
  //   let newArray=[];
  //   // const listData=await sendChatlistData();
  //   console.log("listData here",mylistData);
  //   listData?.map((item,index)=>{
  //   newArray.push({
  //     message:item?.messages[0]?.content,
  //     timeStamp:item?.messages[0]?.timestamp,
  //     user:JSON.parse(localStorage.getItem("user"))?.userId===item?.messages[0]?.senderId?"me":"other",
  //     type:'text',
  //     key:item?._id
  //   })
  // })
  // setMyMessages(Array.isArray(newArray) ? [...newArray].reverse() : []);
      setloader(false);
    }
  };

  useEffect(()=>{
    console.log("myMessages",myMessages);
  },[myMessages])


  // useEffect(()=>{
  //   console.log("selectedChat",selectedChat,"--",listData);
  // },[selectedChat])




  const [map, setMap] = useState({
    ads_id: adsId,
    sellerId: sellerId,
    senderId: JSON.parse(localStorage.getItem("user")).userId,
    buyerId: localStorage.getItem("buyerId"),
    ads_type: ads_type,
    content: '',
    content_type: 'text'
  });

  useEffect(()=>{
    console.log("called for sellerIdChat",localStorage.getItem("sellerIdChat"));
    map.ads_id=localStorage.getItem("adsIdChat");
    map.sellerId=localStorage.getItem("sellerIdChat");
  },[localStorage.getItem("sellerIdChat")])


  console.log(senderId, "senderId1234");


  useEffect(()=>{
    console.log("myMessage Contennt",myMessages)
  },[myMessages])

  



 


  
  const containsUrl = (message) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return urlRegex.test(message);
  };
  
  
  useEffect(() => {
    const getCurrentUserId = localStorage.getItem('user');
    const chat_id=localStorage.getItem("chatId");
    map.buyerId=localStorage.getItem("buyerId");
    
    console.log(map.sellerId,"----",map.senderId, "getCurrentUserId.userId");
    
    const newSocket = io(ENDPOINT, {
      transports: ['websocket'],
      autoConnect: true,
      // query: `userId=${map.senderId}`
      query: `userId=${map.sellerId}`
    });
    
    console.log(chat_id, "chat_id");
    
    newSocket.on('connect', () => {
      newSocket.emit("join-room", chat_id);
      console.log('Connected to server');
    });
    
    newSocket.on('user-status',(data)=>{
      console.log("user status recieved",data);
    })

    newSocket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    newSocket.on('new-chat', message => {
      // Update the chat messages state when a new message is received
      console.log(message, "receivingTheMessage");
     

      // setMessages(prevMessages => [...prevMessages, message]);
      // setNewMessageIds(prevIds => [...prevIds, message.messages[0]._id]);
      console.log(message._id, "message._id");
      // chatListData();
    });

    
    newSocket.on('receive-message', (data)=>{
      console.log(data,selectedChat, "receivingThecount");
      if(localStorage.getItem("forChatCount") === 'true'){
        localStorage.setItem("forChatCount",false)
      }else if(localStorage.getItem("forChatCount") === 'false'){
        localStorage.setItem("forChatCount",true)
      } 
      if(selectedChat){
        if(data?.messages[0]?.senderId?._id !== selectedChat?.sellerId && data?.messages[0]?.senderId?._id !== selectedChat?.buyerId ||
          data?.ads_id !== selectedChat?.ads_id){
        console.log("in this serious",selectedChat,typeof(selectedChat));
        return;
        }
      }
      // if(data?.messages[0]?.senderId?._id !== JSON.parse(localStorage.getItem("user"))?.userId){
      //   return;
      // }

      if(containsUrl(data?.messages[0]?.content)){
        chatListData();
      }
      console.log("cred");
      if(data?.buyer_id === JSON.parse(localStorage.getItem("user"))?.userId || data?.seller_id === JSON.parse(localStorage.getItem("user"))?.userId){
      if(data?.messages[0]?.senderId?._id !== JSON.parse(localStorage.getItem("user"))?.userId){
        console.log("inside thisinchattt")
      setMyMessages((prev)=>[...prev,{
        message:data?.messages[0]?.content,
        user:"other",
        timeStamp:getIsoTime(),
        type:'text',
        key:data?.messages[0]?._id
      }])
    }
    else{
      // let newArray=[];
      // console.log("updateMymessage",myMessages,"----",message);
      // myMessages?.map((item,index)=>{
      //   console.log("timeStamps",item,"---",message);
      //   if(item?.timeStamp === message?.messages[0]?.timestamp){
      //     newArray?.push({...item,key:message?._id})
      //   }
      //   else{
      //     newArray?.push(item);
      //   }
      //   return;
      // })
      setMyMessages((prev)=>[...prev,{
        message:data?.messages[0]?.content,
        user:"me",
        timeStamp:getIsoTime(),
        type:'text',
        key:data?.messages[0]?._id
      }])
    }}

    // setTimeout(()=>{
    //   chatListData();
    // },3000)
    })


    newSocket.on('user-status', (data) => {
      if (data.userId.includes(sellerId)) {
        console.log('Seller is online');
        // setcheckOnlineStatus(true)
       // Add your logic here for when the seller is online
      }
      else{
        console.log("offline");
        setcheckOnlineStatus(false)
      }
    });

    newSocket.on('update-delete-msg',(data)=>{
      console.log("for delete called",data);
      setMyMessages(prevMessages => prevMessages.filter(msg => msg.key !== data?.messageId[0]));
    })

    newSocket.on('delete-msg', deletedMessageId => {
      console.log("hello");
      console.log("deletmsgid",deletedMessageId);
      setMessages(prevMessages => prevMessages.filter(msg => msg.id !== deletedMessageId));
      // chatListData();
    });

    setSocket(newSocket);

    // Cleanup function
    return () => {
      newSocket.disconnect();
    };
  }, [selectedChat, chat_id, map.senderId]);

  const derivedListData = useMemo(() => {
    return sellerId === null ? listData : null;
  }, [listData, sellerId]);

  useEffect(() => {
    chatListData();
  }, [selectedChat, derivedListData]);

  // const sendMessage = () => {
  //   socket.emit("send-message", map);
  //   if (input.trim() !== '') {
  //     socket.emit('message', input);
  //     setInput('');
  //   }
  // };


  //   const sendMessage = () => {
  //   if (input.trim() !== '') {
  //     setMap(prevMap => ({ ...prevMap, content: input })); // Update content in map state
  //     socket.emit("send-message", map); // Sending the updated map
  //     setInput(''); // Resetting the input field after sending
  //   }
  // };



//   const deleteMessage = (messageId) => {
//     console.log("Delete message event triggered for message ID:", messageId);
    
//     // if (socket) {
//         socket.emit("delete-msg", messageId);
//         chatListData();
//     // }
// };

useEffect(()=>{
  console.log("chatDetailsData",chatDetailsData);
},[])

const deleteMessage = (messageId) => {
  const map = {
    chatId: chatDetailsData._id,
    messageId: [messageId],
  };

  // Emit delete message to server
  socket.emit('delete-msg', map);
  console.log("ids",messageId,"-----",myMessages)
  setMyMessages(prevMessages => prevMessages.filter(msg => msg.key !== messageId));
  
  console.log('delete-msg', map);
};

const deleteChatUserSocket = () => {
const map ={
  chatId: chatDetailsData._id,
}
  socket.emit("delete-chat", map);
  chatListData();

  console.log('delete-chat', map);

};

  // const sendMessage = () => {
  //    chatListData();
  //   if (input.trim() !== '') {
  //     setMap(prevMap => {
  //       const updatedMap = { ...prevMap, content: input }; // Update content in map state
  //       socket.emit("send-message", updatedMap); // Sending the updated map
  //       localStorage.setItem("chaters", 'enable');
  //       return updatedMap;
  //     });
  //     setInput('');
  //   }
  // };


  const getIsoTime=()=>{
    const now = new Date();
    return now.toISOString();
  }


  const sendMessage = async () => {

    console.log("input val", input);
    // const ads_type = localStorage.getItem("adsTypeChat");
    map.ads_id = localStorage.getItem("adsIdChat");
    map.sellerId = localStorage.getItem("sellerIdChat");
    if(localStorage.getItem("adsTypeChat")==="Buy+%26+Sell"){
      map.ads_type='Buy & Sell'
    }
    else{
    map.ads_type = localStorage.getItem("adsTypeChat");
    }
    let contentToSend = input;
    let contentTypeToSend = 'text';

    // Check if there are selected files
    if (selectedFiles.length > 0) {
        const fileUrls = await uploadFiles();
        // Update content to contain file URLs
        console.log("fileUrls", fileUrls);
        contentToSend = fileUrls; // Assuming fileUrls is an array of URLs
        contentTypeToSend = 'file';
    }

    // If contentToSend is an array (file URLs), send each URL individually
    console.log("contentoSend",contentToSend);
    if (Array.isArray(contentToSend)) {
        for (const url of contentToSend) {
          console.log("url", url);
            const newMap = {
                ...map,
                content: url,
                content_type: contentTypeToSend
            };
            console.log("newMap",newMap);
            socket.emit('send-message', newMap);
            listData?.push(newMap);
        }
    }else {
        // If contentToSend is a single text message
        const newMap = {
            ...map,
            content: contentToSend,
            content_type: contentTypeToSend
        };
        console.log("newMap", newMap);
        socket.emit('send-message', newMap);
        listData?.push(newMap);
    }
    // Clear input and selected files after sending message 
    setInput('');
    setSelectedFiles([]);
    setPreviewUrls([]);
};

useEffect(()=>{
  console.log("msg in chat.js",myMessages);
},[myMessages])


  localStorage.setItem('message_count', messages.length);



  console.log(messages.length, "messages messages messages");

  return (
    <div>
      {/* <input type="text" value={input} onChange={e => setInput(e.target.value)} />
      <button onClick={sendMessage}>Send</button> */}

      <ChatContent
        chatDetailsData={chatDetailsData}  
        input={input} 
        setInput={setInput}
        sendMessage={sendMessage}
        inputs={input}
        deleteMessage={deleteMessage}
        selectedChat={selectedChat}
        handleFileSelect={handleFileSelect} 
        checkOnlinestatus={checkOnlinestatus}
        ads_type={ads_type}
        adsId={adsId}
        setSelectedFiles={setSelectedFiles}
        selectedFiles={selectedFiles}
        myMessages={myMessages}
        loader={loader}
        setPreviewUrls={setPreviewUrls}
        previewUrls={previewUrls}
        listData={listData}
        userData={message}
        // chatMessages={chatMessages}
        />
    </div>
  );
}

export default Chat;
