// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { getMessaging,getToken,onMessage } from 'firebase/messaging';


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAeAktYaifa6OcKkh2AgYAUBzv9CsBltS8",
    authDomain: "menehariya-d1e7f.firebaseapp.com",
    projectId: "menehariya-d1e7f",
    storageBucket: "menehariya-d1e7f.appspot.com",
    messagingSenderId: "445054121959",
    appId: "1:445054121959:web:7cf2ed301bb2b5f5472f42",
    measurementId: "G-CXXPMC65F7"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging=getMessaging(app);

onMessage((payload)=>{
  console.log("payload here",payload);
})

// Initialize Firebase Authentication and Google Auth Provider
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Optional: Set Google Auth provider parameters
googleProvider.setCustomParameters({
  prompt: 'select_account',
});

export const requestForToken = () => {
    return getToken(messaging, {
        vapidKey: "BG7vWYbJ6dnZLV177iloKLrUl3BmeGjN_6Vz46gcoZw8rgPSDr4esp0k0RXjLMo7e8fJJMT93UWumAmhZkEMifk"
    })
        .then((currentToken) => {
            if (currentToken) {
                console.log('Current token for client:', currentToken);
                return currentToken;
            } else {
                console.log('No registration token available. Request permission to generate one.');
                return null;
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token:', err);
            return null;
        });
};

export { auth, googleProvider, signInWithPopup, signOut ,messaging};