import React,{useState,useEffect} from "react";
import adsStyle from "./EventsAds.module.css"
import getFetchConfig from '../../../utils/getFetchConfig';
import appURLs from '../../../appURL';
import EventsAdsComp from "./EventsAdsComp";
import { toast } from "react-toastify";
import moment from 'moment-timezone';
import 'moment-duration-format';
import { CircularProgress } from "@mui/material";

const EventAds = ({setcheckEventEdit,setadsIds}) => {
 let appURL = appURLs()
  const fetchConfig = getFetchConfig()
  console.log(fetchConfig,"fetchConfigfetchConfigfetchConfig")
  const [activeTab, setActiveTab] = useState("tab1");
  const [EventAdsData,setEventAdsData] =useState([])
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [url, seturl] = useState();
  const [imageArray , setImageArray] = useState([]);
  const [isShareOpenlink, setIsShareOpenlink] = useState(false);
  const [shareID, setshareId] = useState("");
  const [favStatus, setFavStatus] = useState([]);
  const tokenExist = localStorage.getItem("token");
  const [adsId, setAdsId] = useState();
  const [loader,setloader]=useState(false);


  console.log(typeof(setadsIds),"game over")

  const [featureStatus , setFeaturedStatus] = useState(false);

  const fetchRentalAsds = async (status) => {
    setloader(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };
  
      const response = await fetch(
        `${appURL}/posts/events/fetchAds?is_myad=true&page=1&status=${status}`,
        {
          ...fetchConfig,
          method: "GET",
          headers: headers,
        }
      );
  
      if (!response.ok) {
        setloader(false);
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }
     
  
      const jsonData = await response.json();
      console.log("jsondata inactive acitve ads",jsonData);
      const roomRentDataWithCleanedDescriptions = jsonData.records.map((record) => ({
        ...record,
        adsInfo: {
          ...record.adsInfo,
          descriptions: record.adsInfo.descriptions.replace(/<[^>]+>/g, ""),
        },
      }));
      console.log("roomRentDataWithCleanedDescriptions",roomRentDataWithCleanedDescriptions);
      setEventAdsData(roomRentDataWithCleanedDescriptions);
      setAdsId(jsonData.records.map((e) => e._id));
  
      console.log("Ads IDs:", jsonData.records.map((e) => e._id));
      console.log("Fetched data for jobs with status", status);
      setloader(false);
  
    } catch (error) {
      setloader(false);
      console.error("Error fetching data:", error.message);
    }
  };
  


  const handleHeartIconClick = (event,_id) => {
    event.preventDefault();
    event.stopPropagation();
    const adsInfo = localStorage.getItem("adsInfo");
    const adsData = JSON.parse(adsInfo);
  
    const currentStatus = favStatus[_id];
  
    const requestBody = {
      adId: _id,
      ads_type: "64e48fbcf9eeb14b6e2135c0",
      isfavorite: !currentStatus,
    };
  
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  
    fetch(`${appURL}/posts/ads/create-favorite`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: headers,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Response not OK");
        }
      })
      .then((data) => {
        setFavStatus((prevFavStatus) => ({
          ...prevFavStatus,
          [_id]: !currentStatus,
        }));
  
        if (data.favoriteAd) {
          toast.success("Item added to Favorite");
        } else {
          toast.error("Item removed from Favorite");
        }
  
        console.log("Favorite status updated successfully");
      })
      .catch((error) => {
        console.error("Error sending the report:", error);
      });
  };



  const handleTabChange = (tabId) => {
    const newStatus = getStatusFromTabId(tabId);
    setActiveTab(tabId);
    // fetchRentalAdsDetails(newStatus);
    // setcheckJobEdit(true);
  };


  useEffect(() => {
    fetchRentalAsds(getStatusFromTabId(activeTab));
  }, [activeTab]);

  const currentDate = new Date().toISOString().split('T')[0];

  const currentDates = new Date();
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
  };

  const localTimeString = currentDates.toLocaleString('en-US', options);


  const formattedDateTime = `${currentDates.getFullYear()}-${(currentDates.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${currentDates.getDate().toString().padStart(2, '0')} ${currentDates
    .getHours()
    .toString()
    .padStart(2, '0')}:${currentDates.getMinutes().toString().padStart(2, '0')}:${currentDates
    .getSeconds()
    .toString()
    .padStart(2, '0')}`;

  const getStatusFromTabId = (tabId) => {
    switch (tabId) {
      case "tab1":
        return 0; // Active Ads
      case "tab2":
        return 1; // Inactive Ads
      case "tab3":
        return 2; // Drafts
      default:
        return 0; // Default to Active Ads
    }
  };


  const getTimeSinceCreation = (dateString, mytimers) => {

    // let dateString = rentDetailsData.ads_details.active_on_virtual;
  
    // Split the string at "T" and concatenate the date and time parts
    let parts = dateString?.split("T");
    let extractedDate = parts?.[0] && parts?.[1] 
    ? parts[0] + "T" + parts[1].split(".")[0] 
    : undefined;    
    console.log(extractedDate, "extractedDate");
    const convertToTimeZone = (datetime, fromTimeZone, toTimeZone) => {
      return moment.tz(datetime, fromTimeZone).tz(toTimeZone).format('YYYY-MM-DD HH:mm:ss');
    };
  
  const originalDatetime = extractedDate;
  // const originalTimeZone = rentDetailsData.ads_details.location_timezone;
  // console.log(originalTimeZone,"sbjsxjxx");
  
  const timezoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const targetTimeZone = timezoneString;
  
  const convertedDatetime = convertToTimeZone(originalDatetime, mytimers, targetTimeZone);
  
      
  console.log(convertedDatetime,"hhhhgxy",formattedDateTime);
  
  
  
    if (formattedDateTime && convertedDatetime) {
      const secondsDiff = moment.duration(moment(formattedDateTime).diff(convertedDatetime)).asSeconds();
  
  
      if (secondsDiff < 60) {
        return "just now";
      } else if (secondsDiff < 3600) {
        const minutesDiff = Math.floor(secondsDiff / 60);
        return minutesDiff === 1 ? "1 minute ago" : `${minutesDiff} minutes ago`;
      } else if (secondsDiff < 86400) {
        const hoursDiff = Math.floor(secondsDiff / 3600);
        return hoursDiff === 1 ? "1 hour ago" : `${hoursDiff} hours ago`;
      } else if (secondsDiff < 172800) {
        return "Yesterday";
      } else {
        const daysDiff = Math.floor(secondsDiff / 86400);
        return daysDiff === 1 ? "1 day ago" : `${daysDiff} days ago`;
      }
    }
    }

  const shareSocialMediaLink = (event, id, adsType) => {
    event.preventDefault();
    event.stopPropagation();
    const baseUrl = window.location.origin;
    let newUrl;
  
    switch (adsType) {
      case "Babysitters & Nannies":
        newUrl = `${baseUrl}/babySitterDetails/${id}`;
        break;
      case "Buy & Sell":
        newUrl = `${baseUrl}/buySellDetails/${id}`;
        break;
      case "Jobs":
        newUrl = `${baseUrl}/jobDetails/${id}`;
        break;
      case "Rentals":
        newUrl = `${baseUrl}/rentDetails/${id}`;
        break;
      case 'Local Biz & Services':
        newUrl = `${baseUrl}/localBizDetails/${id}`;
        break;
      case 'Events':
        newUrl = `${baseUrl}/eventDetails/${id}`;
        break;
      default:
        newUrl = "/";
    }
  
    setshareId(id);
    seturl(newUrl);
    setIsShareOpen(!isShareOpen);
  };


  return (
    <div className={adsStyle.JobAds}>
      <h1>My Ads</h1>

      <div className="containered">
      <div className="pad_left">
        <input
          type="radio"
          id="tab1"
          name="tab"
          checked={activeTab === "tab1"}
         onChange={() => handleTabChange("tab1")}
        />
        <label htmlFor="tab1">
          <i className="fa fa-code" /> Active Ads
        </label>
        <input
          type="radio"
          id="tab2"
          name="tab"
          checked={activeTab === "tab2"}
            onChange={() => handleTabChange("tab2")}
        />
        <label htmlFor="tab2">
          <i className="fa fa-history" /> Inactive Ads
        </label>
        <input
          type="radio"
          id="tab3"
          name="tab"
          checked={activeTab === "tab3"}
           onChange={() => handleTabChange("tab3")}
        />
        <label htmlFor="tab3">
          <i className="fa fa-pencil" /> Drafts
        </label>

        <div className="line" />
        <div className="content-container">
          <div className="content" id="c1">
                    {loader ?<div className="loader_overlay" style={{marginTop:'200px'}}>
        <CircularProgress/>  
      </div>:
            <div className="tabs_boxes_wrap">
             {EventAdsData.map((item, index) => (
                <EventsAdsComp
                  key={index}
                  item={item}
                  index={index}
                  handleHeartIconClick={handleHeartIconClick}
                  favStatus={favStatus}
                  featureStatus={featureStatus}
                  shareSocialMediaLink={shareSocialMediaLink}
                  isShareOpen={isShareOpen}
                  shareID={shareID}
                  url={url}
                  tokenExist={tokenExist}
                  getTimeSinceCreation={getTimeSinceCreation}
                  adsId={adsId}
                  setcheckEventEdit={setcheckEventEdit}
                  status_detail="active"
                  EventAdsData={EventAdsData}
                  setEventAdsData={setEventAdsData}
                  setadsIds={setadsIds}
                />
              ))}
            </div>
}
          </div>

          <div className="content" id="c2">
          {loader ?<div className="loader_overlay" style={{marginTop:'200px'}}>
        <CircularProgress/>  
      </div>:
             <div className="tabs_boxes_wrap">
              
              {EventAdsData.map((item, index) => (
                <EventsAdsComp
                  key={index}
                  item={item}
                  index={index}
                  handleHeartIconClick={handleHeartIconClick}
                  favStatus={favStatus}
                  featureStatus={featureStatus}
                  shareSocialMediaLink={shareSocialMediaLink}
                  isShareOpen={isShareOpen}
                  shareID={shareID}
                  url={url}
                  tokenExist={tokenExist}
                  getTimeSinceCreation={getTimeSinceCreation}
                  setcheckEventEdit={setcheckEventEdit}
                  status_detail="expired"
                  EventAdsData={EventAdsData}
                  setEventAdsData={setEventAdsData}
                  setadsIds={setadsIds}
                />
              ))}
            </div>
}
          </div>

         <div className="content" id="c3">
         {loader ?<div className="loader_overlay" style={{marginTop:'200px'}}>
        <CircularProgress/>  
      </div>:
            <div className="tabs_boxes_wrap">
              {EventAdsData.map((item, index) => (
                <EventsAdsComp
                   key={index}
                   item={item}
                   index={index}
                   handleHeartIconClick={handleHeartIconClick}
                   favStatus={favStatus}
                   featureStatus={featureStatus}
                   shareSocialMediaLink={shareSocialMediaLink}
                   isShareOpen={isShareOpen}
                   shareID={shareID}
                   url={url}
                   tokenExist={tokenExist}
                   getTimeSinceCreation={getTimeSinceCreation}
                   setcheckEventEdit={setcheckEventEdit}
                   status_detail="draft"
                   EventAdsData={EventAdsData}
                   setEventAdsData={setEventAdsData}
                   setadsIds={setadsIds}
                   
                />
              ))}
            </div>
}
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventAds;
