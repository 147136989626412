import React, { useState } from 'react';
import 'react-widgets/styles.css';
import styles from './formElems.module.css';

const MultiSelect = ({
    className = '',
    label = '',
    id = '',
    name = '',
    placeholder = '',
    required = false,
    onChange = () => {},
}) => {
    const [inputValue, setInputValue] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);

    // Handle when the Enter key is pressed
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && inputValue.trim()) {
            if (!selectedTags.includes(inputValue)) {
                setSelectedTags([...selectedTags, inputValue]);
                onChange([...selectedTags, inputValue]); // Pass the updated list to onChange
            }
            setInputValue('');
            e.preventDefault(); // Prevent the form submission
        }
    };

    // Handle removing a tag
    const removeTag = (tag) => {
        const updatedTags = selectedTags.filter((t) => t !== tag);
        setSelectedTags(updatedTags);
        onChange(updatedTags); // Pass the updated list to onChange
    };

    return (
        <section className={`multiSelectWrapper ${className}`}>
            <label id='label' className={styles.formElementLabel} htmlFor={id}>
                {label}
                {required ? (
                    <span className="required_field"> *</span>
                ) : (
                    <span className='optional_text'>(Optional)</span>
                )}
            </label>

            <div className={styles.multiSelectInputWrapper}>
                {/* Textbox for entering tags */}
                <input
                    type="text"
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyPress={handleKeyPress}
                    className={styles.textInput}
                />

                {/* Display selected tags as badges */}
                <div className={styles.tagsContainer}>
                    {selectedTags.map((tag, index) => (
                        <div key={index} className={styles.tagBadge}>
                            {tag}
                            <span className={styles.tagRemove} onClick={() => removeTag(tag)}>
                                &times; {/* Cross icon */}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default MultiSelect;
