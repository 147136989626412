import React from 'react';
import style from "./addonExampleCard.module.css"

export default function AddonExampleCard({ selectedExampleData, handleExampleModal=()=>{} }) {
    return (
        <main>
            <section className={style.image_section} style={{display:"flex",justifyContent:"center"}} >
                <img src={selectedExampleData?.image} alt="example_image"/>
            </section>

            <section className={style.example_setail_section}>
                <h3>{selectedExampleData?.title}</h3>
                <p>{selectedExampleData?.description}</p>
                <span onClick={()=>{handleExampleModal(false, null)}}>Ok</span>
            </section>
        </main>
    )
}
