import React, { useState, useEffect, useRef } from "react";
import CommonModalWrapper from "../common-modal-Wrapper/CommonModalWrapper";
import ChangePasswordCard from "../change-password-card/ChangePasswordCard";
import CloseIcon from "../Icons/CloseIcon";
import LoginCard from "../login-card/LoginCard";
import RegisterCard from "../register-card/RegisterCard";
import style from "./header.module.css";
import { Link, useNavigate } from "react-router-dom";
import DeleteAccount from "../delete-account/DeleteAccount";
import getFetchConfig from "../../utils/getFetchConfig";
import handleFetchErrors from "../../utils/handleFetchErrors";
import standardFetchHandlers from "../../utils/standardFetchHandlers";
import AdsCategories from "../postAds/ads-categories/AdsCategories";
import appURLs from "../../appURL";
import addFreeImage from "../../assest/images/addfreeimg.png";
import Banner from "../Banner/Banner";
import SimpleImageSlider from "react-simple-image-slider";
import SelectLocation from "../postAds/select-location/SelectLocation";
import FilterLocation from "../postAds/select-location/FilterLocation";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from "react-bootstrap";
import Modals from "react-modal";
import { Dropdown } from "react-bootstrap";
import MultilevelDropdown from "react-multilevel-dropdown";
import Search_query from "../SearchComponent/Search_query";
import DummyLocation from "../postAds/select-location/DummyLocation";
import VectorImage from "../../assest/images/vsector.png";
import arrowRight from '../../assest/images/arrowright.jpg';
import leftArrow from '../../assest/images/leftarrow.jpeg'

import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import './css.css';
import { io } from "socket.io-client";
import { Tooltip } from "@mui/material";
import { onMessage } from "firebase/messaging";
import { messaging } from "../Firebase/firebased";

export default function Header({
  search = false,
  image = true,
  myBanner,
  loctiondata,
  getCoordinates = () => { },
  notificationCount,
  count,
  setChangeState,
  checkJobEdit,
  checkRentalEdit,
  adsIds,
  setadsIds,
  checkBabyEdit,
  checkEventEdit,
  checkLocalBizEdit,
  checkBuySellEdit,
  refreshList,
  setcheckJobEdit,
  setcheckRentalEdit,
  setcheckBabyEdit,
  setcheckEventEdit,
  setcheckLocalBizEdit,
  setCheckBuySellEdit

}) {
  console.log(adsIds, "notificationCount");

  const [categoryHeader, setCategoryHeader] = useState(["All categories"]);

  const [notifClicked, setnotifClicked] = useState(false);
  const url = window.location.href;

  const fetchConfig = getFetchConfig();
  const appURL = appURLs();
  const dropdownRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const [checkTypeLoc, setCheckTypeLoc] = useState(false);

  const [modalIsOpenForLogin, updateModalVisibilityForLogin] = useState(false);

  const [rendered, setRendered] = useState(false);
  const [loginSource, updateLoginSource] = useState(false);
  const [
    modalIsOpenForLoginChangePassword,
    updateIsOpenForLoginChangePassword,
  ] = useState(false);
  const [modalIsOpenForRegister, updateModalVisibilityForRegister] =
    useState(false);
  const [modalIsOpenForDeleteAccount, updateModalVisibilityForDeleteAccount] =
    useState(false);
  const [toggleProfileDropDown, updateToggleProfileDropDown] = useState(false);

  const [
    modalIsModalForPostAdsCategries,
    updateModalIsModalForPostAdsCategries,
  ] = useState(false);
  const [tokenExist, updateToken] = useState(false);
  const [user, updateUser] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const [favCount, setFavCount] = useState([]);
  const [myAdsCount, setMyAdsCount] = useState([]);
  const [adsCount, setadsCount] = useState(0);

  const [rentMenu, setRentMenu] = useState([]);
  const [jobMenu, setJobMenu] = useState([]);
  const [eventMenu, setEventMenu] = useState([]);
  const [bizMenu, setBizMenu] = useState([]);
  const [buysellMenu, setBuysellMenu] = useState([]);
  const [babysitterMenu, setBabysitterMenu] = useState([]);

  const [rentcount, setRentCount] = useState("");
  const [jobcount, setJobcount] = useState("");
  const [babyCount, setBabyCount] = useState("");
  const [localCount, setLocalCount] = useState("");
  const [buysellCount, setBuysellCount] = useState("");
  const [eventCount, setEventCount] = useState("");
  const [locationPermission, setLocationPermission] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [locationName, setLocationName] = useState("");

  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubCategory, setActiveSubCategory] = useState(null);

  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [postTypes, setpostTypes] = useState([]);
  const [postTypeCount, setpostTypeCount] = useState(0);
  const [AllCat, setAllCat] = useState([]);
  const [openSubcat, setopenSubCat] = useState(false);
  const [idSubCat, setidSubCat] = useState(null);

  const [eachId, seteachId] = useState('');
  const [eachCount, seteachCount] = useState('');

  useEffect(() => {
    console.log("location.pathname headers", location.pathname)
  }, [location.pathname]);

  useEffect(() => {
    // Request permission to show notifications
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');

        // Listening for messages from Firebase
        onMessage(messaging, (payload) => {
          console.log('Message received. ', payload);
          // getNotifications();
        });
      } else {
        console.log('Unable to get permission to notify.');
      }
    });
  }, []);







  const [lat, setLat] = useState(null);
  const [lang, setLang] = useState(null);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      background: `rgba(0, 0, 0, 0.4)`,
      zIndex: 1,
    },
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  const [modalAdsIsOpen, setModalAdsOpen] = useState(false);

  const [modalIsOpenlocation, setIsOpenlocation] = useState(false);
  const [roomRents, setroomRents] = useState([]);
  const [myjobs, setmyjobs] = useState([]);
  const [myevents, setmyevents] = useState([]);
  const [localbiz, setlocalbiz] = useState([]);
  const [babysitter, setbabysitter] = useState([]);
  const [buysell, setbuysell] = useState([]);
  const [roomCount, setroomCount] = useState(null);
  const [jobsCount, setjobsCount] = useState(null);
  const [eventsCount, seteventsCount] = useState(null);
  const [bizCount, setbizCount] = useState(null);
  const [babysitterCount, setbabysitterCount] = useState(null);
  const [mybuysellCount, setmybuysellCount] = useState(null);
  const [chatCount, setchatCount] = useState(0);
  // const [categoryName,setCategoryName]


  const [loader, setloader] = useState(false);
  const ENDPOINT = 'https://menehariya-chat.netscapelabs.com/';

  function openModal() {
    setIsOpen(true);
    console.log("first");
  }




  useEffect(() => {
    console.log("eachId", eachId);
  }, [eachId])

  function openMyAdsModal() {
    setModalAdsOpen(true);
    closeHeader();
  }

  function afterOpenModal() {
    // Set the subtitle reference when the modal opens
    if (subtitle) {
      subtitle.style.color = "red";
    }
  }

  useEffect(() => {
    const newSocket = io(ENDPOINT, {
      transports: ['websocket'],
      autoConnect: true,
      // query: `userId=${map.senderId}`
      query: `userId=${JSON.parse(localStorage.getItem('user'))?.userId}`
    });
    newSocket.on('receive-message', (data) => {
      chatlistsdetailsData();
    })
  }, [])

  function closeModal() {
    setIsOpen(false);
  }
  function closeMyAdsModal() {
    setModalAdsOpen(false);
  }

  const chatlistsdetailsData = async () => {
    console.log("calledchatlistdataheader");
    localStorage.setItem("forChatCount", true);

    // (0);
    try {
      console.log("chatCount", chatCount);
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };
      const response = await fetch(`${appURL}/chat/chat-list`, {
        ...fetchConfig,
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setchatCount(0);

      const jsonData = await response.json();
      console.log("chatListData", jsonData);
      jsonData?.data?.map((item, index) => {
        setchatCount(chatCount => chatCount + item?.messageCount);
        console.log("ininininini");
      })
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    chatlistsdetailsData();
  }, [refreshList])

  useEffect(() => {
  }, [chatCount])

  const renderContentBasedOnUrl = () => {
    if (url.includes("view_for_rent")) {
      return (
        <div>
          <p className={style.header_content}>Rentals</p>
          {/* Add content related to rentals here */}
        </div>
      );
    } else if (url.includes("view_for_job")) {
      return (
        <div>
          <p className={style.header_content}>Jobs</p>
          {/* Add content related to job here */}
        </div>
      );
    } else if (url.includes("view_for_events")) {
      return (
        <div>
          <p className={style.header_content}>Events</p>
          {/* Add content related to job here */}
        </div>
      );
    } else if (url.includes("view_for_Babysitter_and_nannies")) {
      return (
        <div>
          <p className={style.header_content}>BabySitter</p>
          {/* Add content related to job here */}
        </div>
      );
    } else if (url.includes("view_for_Buy_and_Sell")) {
      return (
        <div>
          <p className={style.header_content}>Buy & Sell</p>
          {/* Add content related to job here */}
        </div>
      );
    } else if (url.includes("view_for_localBiz_and_Services")) {
      return (
        <div>
          <p className={style.header_content}>Local Biz & Services</p>
          {/* Add content related to job here */}
        </div>
      );
    } else {
      return (
        <div>
          <p className={style.header_content}>All Categories</p>
          {/* Add content related to job here */}
        </div>
      );
    }
  };

  const toggleModalForLogin = (toggleValue = false) => {
    updateModalVisibilityForRegister(false);
    updateModalVisibilityForLogin(toggleValue);
  };

  const toggleModalForRegister = (toggleValue = false) => {
    updateModalVisibilityForLogin(false);
    updateModalVisibilityForRegister(toggleValue);
  };

  const toggleModalForRegisterForPasswordChange = (toggleValue = false) => {
    updateToggleProfileDropDown(false);
    updateIsOpenForLoginChangePassword(toggleValue);
  };

  const toggleModalForRegisterForDeleteAccount = (toggleValue = false) => {

    if (toggleValue) {
      const currentUrl = window.location.href;
      const separator = currentUrl.includes('?') ? '&' : '?';
      const newUrl = `${currentUrl}${separator}action=delete&message=delete+account`;

      // Update the URL without redirecting
      window.history.pushState({}, document.title, newUrl);
    } else {
      // Clear the query parameters from the URL
      window.history.replaceState({}, document.title, window.location.pathname);
    }


    updateToggleProfileDropDown(false);
    updateModalVisibilityForDeleteAccount(toggleValue);

  };

  //     const toggleModalForPostAdscategories = (toggleValue = false) => {
  //     if(toggleValue) document.body.classList.add("body-fixed");
  //     else  document.body.classList.remove("body-fixed");
  //     updateModalIsModalForPostAdsCategries(toggleValue)
  //     }

  const clearAdsID = () => {
    // toggleModalForPostAdscategories(false);
    setadsIds("")
  }

  const toggleModalForPostAdscategories = (toggleValue = false) => {

    // setadsIds('');
    // setcheckBabyEdit('');
    // setcheckEventEdit('');
    // setcheckJobEdit('');
    // setcheckLocalBizEdit('');
    // setcheckRentalEdit('');
    // setCheckBuySellEdit('');
    // alert(`toggleValue${toggleValue}`);
    console.log("sourabh check", toggleValue);
    if ('caches' in window) {
      caches.keys().then(names => {
        for (let name of names) caches.delete(name);
      });
    }
    const getToken = localStorage.getItem('token');
    if (toggleValue && !getToken) {
      console.log("hello sourabh");
      toast.error('Please Login first');
      toggleModalForLogin(true);
      return;
    }

    if (toggleValue) {
      document.body.classList.add("body-fixed");
    } else {
      document.body.classList.remove("body-fixed");
    }

    updateModalIsModalForPostAdsCategries(toggleValue);
    closeHeader();
  };

  useEffect(() => {
    if (checkJobEdit) {
      toggleModalForPostAdscategories(true);
    }

    if (checkRentalEdit) {
      toggleModalForPostAdscategories(true);
    }
    if (checkBabyEdit) {
      toggleModalForPostAdscategories(true);
    }
    if (checkEventEdit) {
      toggleModalForPostAdscategories(true);
    }
    if (checkLocalBizEdit) {
      toggleModalForPostAdscategories(true);
    }
    if (checkBuySellEdit) {
      toggleModalForPostAdscategories(true);
    }

  }, [checkJobEdit, checkRentalEdit, checkBabyEdit, checkEventEdit, checkLocalBizEdit, checkBuySellEdit]);




  const demo = () => {
    localStorage.removeItem(`token`);
    localStorage.removeItem(`temtoken`);
    localStorage.removeItem(`user`);
    localStorage.removeItem(`source`);
    localStorage.removeItem(`profilePicture`);
    localStorage.removeItem(`backgroundColor`);
    localStorage.removeItem(`initial`);
    localStorage.removeItem('deviceToken');
    updateToggleProfileDropDown(false);
    window.location.href = '/'; // Redirect to the home page
    updateToken(false);
    window.location.reload(false);
  };

  const toggleProfileDropDownHandler = () => {
    updateToggleProfileDropDown(!toggleProfileDropDown);
  };

  const fetchingSellerAccountDetail = () => {
    fetch(`${appURL}/user-profile`, {
      ...fetchConfig,
      method: `GET`,
    })
      .then(handleFetchErrors)
      .then((sellerAccountJOSNReponse) => {
        const sellerAccountDetail = sellerAccountJOSNReponse?.user;
        localStorage.setItem('listerInfoChange', sellerAccountDetail?.staticListerInfo);

        if (sellerAccountDetail) {
          // //destructing sller data Object
          const sellerDataObj = {
            name: sellerAccountDetail.name,
          };

          updateUser(sellerDataObj);
        }
      })
      .catch(standardFetchHandlers.error);
  };
  // Hitting Menu Apis

  useEffect(() => {
    const getdata = async () => {
      const token = localStorage.getItem('token');
      await axios.get(`${appURL}/posts/types`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
        .then((res) => {
          console.log("res all header data sourabh", res?.data?.postType);
          const sortedPostType = res?.data?.postType.sort((a, b) => a.uniqueNumber - b.uniqueNumber);

          // Extract the _id values from the sorted array
          const idArray = sortedPostType.map(item => item._id);

          // Update the state with the sorted _id values
          console.log("idArray", idArray);
          setpostTypes(idArray)
        })
        .catch((err) => { console.log("error getting data sourabh", err) })
    }
    getdata();
  }, [])

  useEffect(() => {
    const eachData = async () => {
      const token = localStorage.getItem('token');
      await axios.get(`${appURL}/posts/room-rents/menu?longitude=${lang}&latitude=${lat}&PostType=${postTypes[0]}`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
        .then((res) => {
          console.log("only room rent", res); setroomRents(res?.data?.data);
          const total = res?.data?.data.reduce((sum, item) => sum + item.rentalTypeCount, 0);
          setroomCount(total);
        })
        .catch((err) => { console.log("err only room rent", err) })
    }
    eachData();
  }, [postTypes])
  useEffect(() => {
    const eachData = async () => {
      const token = localStorage.getItem('token');
      await axios.get(`${appURL}/posts/jobs/menu?longitude=${lang}&latitude=${lat}&PostType=${postTypes[1]}`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
        .then((res) => {
          console.log("only jobs", res); setmyjobs(res?.data?.data);
          const total = res?.data?.data.reduce((sum, item) => sum + item.rentalTypeCount, 0);
          setjobsCount(total);
        })
        .catch((err) => { console.log("err only jobs", err) })
    }
    eachData();
  }, [postTypes])

  useEffect(() => {
    const eachData = async () => {
      const token = localStorage.getItem('token');
      await axios.get(`${appURL}/posts/events/menu?longitude=${lang}&latitude=${lat}&PostType=${postTypes[2]}`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
        .then((res) => {
          console.log("only events", res); setmyevents(res?.data?.data);
          const total = res?.data?.data.reduce((sum, item) => sum + item.rentalTypeCount, 0);
          seteventsCount(total);
        })
        .catch((err) => { console.log("err only events", err) })
    }
    eachData();
  }, [postTypes])

  useEffect(() => {
    const eachData = async () => {
      const token = localStorage.getItem('token');
      await axios.get(`${appURL}/posts/biz/menu?longitude=${lang}&latitude=${lat}&PostType=${postTypes[3]}`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
        .then((res) => {
          console.log("only biz", res); setlocalbiz(res?.data?.data);
          const total = res?.data?.data.reduce((sum, item) => sum + item.rentalTypeCount, 0);
          setbizCount(total);
        })
        .catch((err) => { console.log("err only biz", err) })
    }
    eachData();
  }, [postTypes])

  useEffect(() => {
    const eachData = async () => {
      const token = localStorage.getItem('token');
      await axios.get(`${appURL}/posts/buysell/menu?longitude=${lang}&latitude=${lat}&PostType=${postTypes[5]}`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
        .then((res) => {
          console.log("only buy and sell", res); setbuysell(res?.data?.data);
          const total = res?.data?.data.reduce((sum, item) => sum + item.rentalTypeCount, 0);
          setmybuysellCount(total);
        })
        .catch((err) => { console.log("err only buy sell", err) })
    }
    eachData();
  }, [postTypes])

  useEffect(() => {
    const eachData = async () => {
      const token = localStorage.getItem('token');
      await axios.get(`${appURL}/posts/babysitter/menu?longitude=${lang}&latitude=${lat}&PostType=${postTypes[4]}`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
        .then((res) => {
          console.log("only babysitter", res); setbabysitter(res?.data?.data);
          const total = res?.data?.data.reduce((sum, item) => sum + item.rentalTypeCount, 0);
          setbabysitterCount(total);
        })
        .catch((err) => { console.log("err only babysitter", err) })
    }
    eachData();
  }, [postTypes])

  const getEachData = async (id, name) => {
    console.log("called", name);
    if (id === "called") { return; }
    if (id !== "called") {
      setloader(true);
    }
    const token = localStorage.getItem('token');
    await axios.get(`${appURL}/posts/${name}/menu?longitude=${lang}&latitude=${lat}&PostType=${id}`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
      .then((res) => {
        console.log('res particular sourabh', res?.data?.data); setloader(false);
        setAllCat(res?.data?.data);
        const total = res?.data?.data.reduce((sum, item) => sum + item.rentalTypeCount, 0);
        setpostTypeCount(total);
      })
      .catch((err) => { console.log('err particilar sourabh', err) })
  }

  const rentMenuDetails = async () => {
    console.log("rentMenyDetails")
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };

      const response = await fetch(
        `${appURL}/posts/room-rents/menu?longitude=${lang}&latitude=${lat}`,
        {
          ...fetchConfig,
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      console.log("jsonData rentals", jsonData);
      setRentMenu(jsonData.data);
      setRentCount(jsonData.totalCount);
      console.log(jsonData, "rent menu data +++++++++");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(lat, "my", lang, "my lang");

  localStorage.setItem("newLat", lat);
  localStorage.setItem("newLang", lang);

  const jobMenuDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };

      const response = await fetch(
        `${appURL}/posts/jobs/menu?longitude=${lang}&latitude=${lat}`,
        {
          ...fetchConfig,
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setJobMenu(jsonData.data);
      setJobcount(jsonData.totalCount);
      console.log(jsonData, "job menu data +++++++++");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const BizMenuDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };

      const response = await fetch(
        `${appURL}/posts/biz/menu?longitude=${lang}&latitude=${lat}`,
        {
          ...fetchConfig,
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setBizMenu(jsonData.data);
      setLocalCount(jsonData.totalCount);
      console.log(jsonData, "job menu data +++++++++");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const eventMenuDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };

      const response = await fetch(
        `${appURL}/posts/events/menu?longitude=${lang}&latitude=${lat}`,
        {
          ...fetchConfig,
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setEventMenu(jsonData.data);
      setEventCount(jsonData.totalCount);
      console.log(jsonData, "Events menu data +++++++++");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const buySellMenuDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };

      const response = await fetch(
        `${appURL}/posts/buysell/menu?longitude=${lang}&latitude=${lat}`,
        {
          ...fetchConfig,
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setBuysellMenu(jsonData.data);
      setBuysellCount(jsonData.totalCount);
      console.log(jsonData, "Events menu data +++++++++");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const babySitterMenuDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };

      const response = await fetch(
        `${appURL}/posts/babysitter/menu?longitude=${lang}&latitude=${lat}`,
        {
          ...fetchConfig,
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setBabysitterMenu(jsonData.data);
      setBabyCount(jsonData.totalCount);
      console.log(jsonData, "Events menu data +++++++++");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const favouriteAdsCount = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };

      const response = await fetch(`${appURL}/posts/ads/favorite-count`, {
        ...fetchConfig,
        method: "GET",
        headers: headers,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setFavCount(jsonData.results);
      console.log(jsonData.results, "Favoyrite count data +++++++++");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // MyAds Api
  const myAdsCountResponse = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };

      const response = await fetch(`${appURL}/posts/ads/my-ads-count`, {
        ...fetchConfig,
        method: "GET",
        headers: headers,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      console.log("jsonData ads", jsonData);
      setMyAdsCount(jsonData.results);
      setadsCount(jsonData.results.reduce((total, category) => total + category.count, 0));
      console.log(jsonData.results, "MyAds count data +++++++++");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Hitting Menu Apis

  useEffect(() => {
    if (localStorage.getItem(`token`)) {
      updateToken(true);
    }
  }, [localStorage.getItem(`token`), tokenExist]);

  const checkRental = localStorage.getItem(`rental_isFavorite`);
  useEffect(() => {
    console.log("Header useEffect triggered");
    if (!checkRental) {
      setChangeState(true);
      console.log(checkRental, "uubjdsnbnj");
    }
  }, []);

  useEffect(() => {
    const source = localStorage.getItem(`source`);
    if (source) updateLoginSource(true);
    else updateLoginSource(false);
    fetchingSellerAccountDetail();
    updateToggleProfileDropDown(false);
    // postAnAddCHECK();
  }, []);

  const backgroundColor = localStorage?.getItem("backgroundColor");
  const initial = localStorage?.getItem("initial");

  const closeHeader = () => {
    updateToggleProfileDropDown(false);
    setIsSubmenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef.current?.contains(event.target) &&
        !event.target.closest(".header")
      ) {
        closeHeader();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const handleKeyPress = (e) => {
  //   if (searchTerm) {
  //     navigate(`/search-results/${searchTerm}`);
  //     }
  //   };

  const categories = [
    { value: "categories", label: "All categories" },
    { value: "rentals", label: "Rentals" },
    { value: "jobs", label: "Jobs" },
    { value: "events", label: "Events" },
    { value: "buy&sell", label: "Buy & Sell" },
    { value: "babysitter", label: "Babysitters & Nannies" },
    { value: "localbiz", label: "Local Biz & Services" },
  ];


  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    // Add your logic for handling the selected category here
  };

  const getWidthBasedOnCategory = () => {
    switch (selectedCategory) {
      case 'rentals':
        return '150px';
      case 'jobs':
        return '130px';
      case 'events':
        return '140px';
      case 'buy&sell':
        return '170px';
      case 'babysitter':
        return '265px';
      case 'localbiz':
        return '247px';
      // Add more cases for other categories if needed
      default:
        return '200px'; // Default width
    }
  };

  const calculateWidth = () => {
    const baseWidth = 150;
    const characterFactor = 14;

    return `${selectedCategory.length * characterFactor + baseWidth}px`;
  };

  const [isSelectedChat, setIsSelectedChat] = useState(false);


  const handleKeyPress = (e) => {

    if (searchTerm || selectedCategory) {
      let route;

      switch (selectedCategory) {
        case "rentals":
          route = "/view_for_rent";
          break;
        case "jobs":
          route = "/view_for_job";
          break;
        case "events":
          route = "/view_for_events";
          break;
        case "buy&sell":
          route = "/view_for_Buy_and_Sell";
          break;
        case "babysitter":
          route = "/view_for_Babysitter_and_nannies";
          break;
        case "localbiz":
          route = "/view_for_localBiz_and_Services";
          break;
        default:
          // Handle the default case or navigate to a generic search-results route
          route = "/search-results";
          break;
      }

      // Navigate to the determined route with the searchTerm as a parameter
      navigate(`${route}/${searchTerm}`);
      window.scrollTo(0, 700);
    }
  };

  // const handleKeyPress = (e) => {
  //   if (searchTerm) {
  //     // Check if the current URL contains the string "view_for_rent"
  //     if (window.location.href.includes('view_for_rent')) {
  //       // If true, navigate to the route with searchTerm as a parameter
  //       navigate(`/view_for_rent/${searchTerm}`);
  //     }
  //      else if (window.location.href.includes('view_for_job')) {
  //       // If true, navigate to the route with searchTerm as a parameter
  //       navigate(`/view_for_job/${searchTerm}`);
  //     }
  //     else if (window.location.href.includes('view_for_events')) {
  //       // If true, navigate to the route with searchTerm as a parameter
  //       navigate(`/view_for_events/${searchTerm}`);
  //     }
  //     else if (window.location.href.includes('view_for_Babysitter_and_nannies')) {
  //       // If true, navigate to the route with searchTerm as a parameter
  //       navigate(`/view_for_Babysitter_and_nannies/${searchTerm}`);
  //     }
  //     else if (window.location.href.includes('view_for_Buy_and_Sell')) {
  //       // If true, navigate to the route with searchTerm as a parameter
  //       navigate(`/view_for_Buy_and_Sell/${searchTerm}`);
  //     }
  //     else if (window.location.href.includes('view_for_localBiz_and_Services')) {
  //       // If true, navigate to the route with searchTerm as a parameter
  //       navigate(`/view_for_localBiz_and_Services/${searchTerm}`);
  //     }
  //     else {
  //       // If false, navigate to a different route or handle it as needed
  //       // For example, navigate to a generic search-results route
  //       navigate(`/search-results/${searchTerm}`);
  //       window.scrollTo(0, 700);
  //     }
  //   }
  //   window.scrollTo(0, 700);
  // };

  useEffect(() => {
    const postAnAddCHECK = () => {
      if (!rendered && window.location.search.includes("postadd=true")) {
        document.querySelector(".post-btn").click();
        setRendered(true);
      } else if (window.location.search === "/") {
        window.location.reload("/");
      }
    };

    postAnAddCHECK();
  }, [rendered]);

  // useEffect(() => {
  //   if (window.location.pathname.endsWith(`/search-results/${searchTerm}`)) {
  //     window.scrollTo(0, 700);
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    rentMenuDetails();
    jobMenuDetails();
    BizMenuDetails();
    eventMenuDetails();
    buySellMenuDetails();
    babySitterMenuDetails();
  }, [lat, lang]);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    setActiveSubCategory(null);
  };

  const handleCategoryHover = (category) => {
    setHoveredCategory(category);
    localStorage.setItem("hoveredCategory", JSON.stringify(category.name));
    localStorage.setItem("selectedSubCategory", JSON.stringify(null));
    setIsSubmenuOpen(false);
  };

  console.log(hoveredCategory, "setHoveredCategoryyyyyyyyy")

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = () => {
    setShowModal(true);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const redirectToChromeSettings = () => {
    // Redirect to Chrome settings for location permissions
    window.location.href = "chrome://settings/content/location";
  };

  const hoverCategory = JSON.parse(localStorage.getItem("hoveredCategory"));

  const handleSubCategoryClick = (subCategory) => {
    setActiveSubCategory(subCategory);
    localStorage.setItem("selectedSubCategory", JSON.stringify(subCategory));
    localStorage.setItem("hoveredCategory", JSON.stringify(null));
    localStorage.removeItem("rental_isFavorite");
    window.scrollTo(0, 700);

    setIsSubmenuOpen(false);
  };

  const deleteCache = () => {
    localStorage.setItem("selectedSubCategory", JSON.stringify(null));
    localStorage.setItem("hoveredCategory", JSON.stringify(null));
    localStorage.removeItem("rental_isFavorite");
    localStorage.removeItem("job_isFavorite");
    localStorage.removeItem("event_isFavorite");
    localStorage.removeItem("buySell_isFavorite");
    localStorage.removeItem("babysitter_isFavorite");
    localStorage.removeItem("localBiz_isFavorite");
    setSearchTerm("");
  };

  const deleteFav = () => {
    window.scrollTo(0, 700);
    localStorage.removeItem("rental_isFavorite");
    localStorage.removeItem("job_isFavorite");
    localStorage.removeItem("event_isFavorite");
    localStorage.removeItem("buySell_isFavorite");
    localStorage.removeItem("babysitter_isFavorite");
    localStorage.removeItem("localBiz_isFavorite");
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveCategory(null);
      setActiveSubCategory(null);
    }
  };

  // const goToRentt =()=>{
  //   window.location.reload("/view_for_rent");
  // }

  const storedLat = localStorage.getItem("latts");
  const storedLang = localStorage.getItem("langs");


  const Stored_location = localStorage.getItem("SelectedCity");

  const handleLocationChange = (newLatitude, newLongitude, location_name) => {
    // Store the latitude and longitude in localStorage

    // getCoordinates(newLongitude,newLatitude);
    getCoordinates(storedLang, storedLat);

    // setLat(newLatitude)
    // setLang(newLongitude)
    setLat(storedLat);
    setLang(storedLang);
    setLocationName(Stored_location);

    console.log(
      location_name,
      "location_namelocation_namelocation_namelocation_name"
    );
  };

  useEffect(() => {
    handleLocationChange();
  }, [storedLat, storedLang]);

  useEffect(() => {
    myAdsCountResponse();
  }, [])


  useEffect(() => {
    favouriteAdsCount();

    rentMenuDetails();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function showConfirm() {
    const result = window.confirm(
      "To Use Menehariya please grant location permission"
    );
    if (result) {
      // If the user clicks "OK" (which means "Yes"), refresh the page
      // window.location.reload();
    } else {
      // The user clicked "Cancel" (which means "No"), do nothing
    }
    window.location.reload();
  }

  const geolocation = () => {
    if ("geolocation" in navigator) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          // Check the permission state
          console.log(permissionStatus, "per");
          if (permissionStatus.state === "granted") {
            setLocationPermission(true); // Permission is granted
          } else {
            setLocationPermission(false);
            handleShow();
            setIsModalOpen(true); // Permission is denied or not yet granted
          }

          // Listen for changes in permission status
          permissionStatus.onchange = () => {
            if (permissionStatus.state === "granted") {
              setLocationPermission(true); // Permission is granted after change
            } else {
              setLocationPermission(false);
              handleShow();
              setIsModalOpen(true); // Permission is denied after change
            }
          };
        })
        .catch((error) => {
          console.error("Error checking geolocation permission:", error);
          setLocationPermission(false);
          handleShow(); // Handle errors by assuming permission is denied
        });
    } else {
      console.error("Geolocation is not supported in this browser.");
      setLocationPermission(false); // Geolocation not supported
      handleShow();
    }
  };

  useEffect(() => {
    geolocation();

    //  const btn = document.getElementById("button");
    //  btn.addEventListener("click", () => {
    //   setIsModalOpen(true);
    //  });
  }, []);

  const bell_count = localStorage.getItem("unseen_total");

  //   const handleOutsideClick =()=>{
  //     window.location.reload()
  //  }

  //  const closeModal =()=>{
  //    setIsModalOpen(false);
  //  }

  // const imagesOfBanner = myBanner.map(bannerItem => ({ url: bannerItem.image.url }));

  // const rentalFav = ()=>{
  //   navigate('/view_for_rent');
  //   localStorage.setItem("rental_isFavorite", true)
  // }
  // const jobFav = ()=>{
  //   navigate('/view_for_job');
  //   localStorage.setItem("job_isFavorite", true)
  // }
  // const eventFav = ()=>{
  //   navigate('/view_for_events');
  //   localStorage.setItem("event_isFavorite", true)
  // }
  // const buyFav = ()=>{
  //   navigate('/view_for_Buy_and_Sell');
  //   localStorage.setItem("buySell_isFavorite", true)
  // }
  // const babyFav = ()=>{
  //   navigate('/view_for_Babysitter_and_nannies');
  //   localStorage.setItem("babysitter_isFavorite", true)
  // }
  // const localFav = ()=>{
  //   navigate('/view_for_localBiz_and_Services');
  //   localStorage.setItem("localBiz_isFavorite", true)
  // }

  const handleFavouriteCategory = (category) => {
    console.log("first");
    switch (category) {
      case "Jobs":
        navigate("/view_for_job/fav_job");
        localStorage.setItem("job_isFavorite", true);
        // window.location.reload();
        break;
      case "Rentals":
        navigate("/view_for_rent/fav_rent");
        localStorage.setItem("rental_isFavorite", true);
        // window.location.reload();
        break;
      case "Events":
        navigate("/view_for_events/fav_event");
        localStorage.setItem("event_isFavorite", true);
        // window.location.reload();
        break;
      case "Buy & Sell":
        navigate("/view_for_Buy_and_Sell/fav_buy_sell");
        localStorage.setItem("buySell_isFavorite", true);
        // window.location.reload();
        break;
      case "Babysitters & Nannies":
        navigate("/view_for_Babysitter_and_nannies/fav_baby_sitter");
        localStorage.setItem("babysitter_isFavorite", true);
        // window.location.reload();
        break;
      case "Local Biz & Services":
        navigate("/view_for_localBiz_and_Services/fav_local_service");
        localStorage.setItem("localBiz_isFavorite", true);
        // window.location.reload();
        break;
      default:
        break;
    }
    setIsOpen(false);
    window.scrollTo(0, 700);
  };

  const handleMyAdsCategory = (category) => {
    switch (category) {
      case "Jobs":
        setadsIds("")
        navigate("/jobsAds");
        window.location.reload()

        break;
      case "Rentals":
        setadsIds("")
        navigate("/rentalAds");
        window.location.reload()
        break;
      case "Events":
        setadsIds("")
        navigate("/eventsAds");
        window.location.reload()
        break;
      case "Buy & Sell":
        setadsIds("")
        navigate("/buySellAds");
        window.location.reload()
        break;
      case "Babysitters & Nannies":
        setadsIds("")
        navigate("/babysitterAds");
        window.location.reload()
        break;
      case "Local Biz & Services":
        setadsIds("")
        navigate("/localBizAds");
        window.location.reload()
        break;
      default:
        setadsIds("")
        break;
    }
    setModalAdsOpen(false);
    window.scrollTo(0, 700);
  };

  const location_modal_open = () => {
    setIsOpenlocation(true);
    console.log("chekcing the modal open");
  };

  function closeModalloc() {
    setIsOpenlocation(false);
    console.log("redededded");
    window.location.reload();
  }

  const clearSearch = () => {
    setSearchTerm("");
    chatlistsdetailsData();
  };

  console.log(checkTypeLoc, "nfnfdndnjdndjbdfbfdbj");


  console.log(roomRents, "roomRentssssssss")
  // useEffect(() => {
  //   // Check the current URL and update selectedCategory accordingly
  //   const path = window.location.pathname;

  //   console.log('Current Path:', path);

  //   if (path.includes('/view_for_rent')) {
  //     setSelectedCategory('rentals');
  //   } else if (path.includes('/view_for_job')) {
  //     setSelectedCategory('jobs');
  //   } else if (path.includes('/view_for_events')) {
  //     setSelectedCategory('events');
  //   } else if (path.includes('/view_for_Buy_and_Sell')) {
  //     setSelectedCategory('buy&sell');
  //   } else if (path.includes('/view_for_Babysitter_and_nannies')) {
  //     setSelectedCategory('babysitter');
  //   } else if (path.includes('/view_for_localBiz_and_Services')) {
  //     setSelectedCategory('localbiz');
  //   } 
  // },);


  // const maxLength = Math.max(...categories.map(category => category.label.length));


  // console.log(maxLength, "maxLengthmaxLength");


  const [selectedCategory, setSelectedCategory] = useState('');
  const [navClosed, setnavClosed] = useState(false)


  // useEffect(() => {
  //   console.log('Location changed:', location.pathname);

  //   const pathSegments = location.pathname.split('/');
  //   const categoryFromPath = pathSegments[pathSegments.length - 1];

  //   console.log('Category from path:', categoryFromPath);

  //   if (categories.some((category) => category.value === categoryFromPath)) {
  //     setSelectedCategory(categoryFromPath);
  //   } else {
  //     setSelectedCategory('');
  //   }
  // }, );


  useEffect(() => {
    // Define a mapping between URL paths and category values
    const pathToCategoryMap = {
      '/': 'categories',
      '/view_for_rent': 'rentals',
      '/view_for_job': 'jobs',
      '/view_for_events': 'events',
      '/view_for_Babysitter_and_nannies': 'babysitter',
      '/view_for_Buy_and_Sell': 'buy&sell',
      '/view_for_localBiz_and_Services': 'localbiz',
    };

    // Extract the category from the current route and update the state
    const path = location.pathname;
    const categoryFromPath = pathToCategoryMap[path] || 'categories';

    if (categoryFromPath !== 'categories') {
      setSelectedCategory(categoryFromPath);
    } else {
      // Handle the case where the category in the URL is 'categories'
      // Set selectedCategory based on the searchCategoryParam
      setSelectedCategory('');
    }
  }, [location.pathname]);


  // const categoryWidths = {
  //   'All categories': 195,
  //   'Rentals': 160,
  //   'Jobs': 40,
  //   'Events': 50,
  //   'Buy & Sell': 80,
  //   'Babysitters & Nannies': 250,
  //   'Local Biz & Services': 130,
  // };

  // const minWidth = 195; // Set your default minimum width

  // useEffect(() => {
  //   // Handle the logic for handling the selected category here

  //   // Update the width when the selected category changes
  //   const selectElement = document.getElementById('rest');
  //   if (selectElement) {
  //     const newWidth = categoryWidths[selectedCategory] || minWidth;
  //     selectElement.style.width = `${newWidth}px`;
  //   }
  // }, [selectedCategory]);

  console.log(myjobs, "myjobsssssss")

  return (
    <>
      <CommonModalWrapper
        isOpen={modalIsOpenForLogin}
        className={style.modal_wrapper}
      >
        <CloseIcon onClick={() => toggleModalForLogin(false)} />
        <LoginCard
          updateModalVisibilityForLogin={updateModalVisibilityForLogin}
          toggleModalForLogin={toggleModalForLogin}
          toggleModalForRegister={toggleModalForRegister}
        />
      </CommonModalWrapper>

      <CommonModalWrapper
        isOpen={modalIsOpenForLoginChangePassword}
        className={style.modal_wrapper}
      >
        <CloseIcon
          onClick={() => toggleModalForRegisterForPasswordChange(false)}
        />
        <ChangePasswordCard
          updateIsOpenForLoginChangePassword={
            updateIsOpenForLoginChangePassword
          }
        />
      </CommonModalWrapper>

      <CommonModalWrapper
        isOpen={modalIsOpenForRegister}
        className={style.modal_wrapper}
      >
        <CloseIcon onClick={() => toggleModalForRegister(false)} />
        <RegisterCard
          updateModalVisibilityForRegister={updateModalVisibilityForRegister}
          toggleModalForLogin={toggleModalForLogin}
          toggleModalForRegister={toggleModalForRegister}
        />
      </CommonModalWrapper>

      <CommonModalWrapper
        isOpen={modalIsOpenForDeleteAccount}
        className={`${style.modal_wrapper} ${style.dete_account_modal_wrapper}`}
      >
        <CloseIcon
          onClick={() => toggleModalForRegisterForDeleteAccount(false)}
        />
        <DeleteAccount
          loginSource={loginSource}
          updateModalVisibilityForDeleteAccount={
            updateModalVisibilityForDeleteAccount
          }
        />
      </CommonModalWrapper>

      {/* post ads- categories modal */}
      <CommonModalWrapper
        isOpen={modalIsModalForPostAdsCategries}
        className={style.ads_categories_modal_wrapper}
        closeModal={() => toggleModalForPostAdscategories(false)}
      >
        <CloseIcon onClick={() => toggleModalForPostAdscategories(false)} />
        <AdsCategories
          toggleModalForPostAdscategories={toggleModalForPostAdscategories}
          checkJobEdit={checkJobEdit}
          checkRentalEdit={checkRentalEdit}
          adsIds={adsIds}
          setadsIds={setadsIds}
          checkBabyEdit={checkBabyEdit}
          checkEventEdit={checkEventEdit}
          checkLocalBizEdit={checkLocalBizEdit}
          checkBuySellEdit={checkBuySellEdit}

        />
      </CommonModalWrapper>

      {/* // New mwenu bar */}

      <div className={style.top_header_bar}>
        <Link to="/" onClick={clearSearch}>
          <div>
            <img
              className={style.top_header_bar_logo}
              src="/images/logo_final.png"
              alt="logo"
            />
          </div>
        </Link>
        <div className={style.search_bar_list}>
          <div className={style.search_box}>
            {/* <div>
            <i class="fa-solid fa-magnifying-glass"></i>
            </div> */}

            <i class="fa-solid fa-magnifying-glass"></i>
            <input
              className={style.search_input}
              type="text"
              placeholder="What are you looking for?"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleKeyPress();
                }
              }}
            />
          </div>

          {/* <div className={style.header_style}> */}
          <div className={style.header_style}  >
            {/* {renderContentBasedOnUrl()} */}
            <img src={VectorImage} alt="logosd" />

            <select
              id="mySelect"
              value={selectedCategory}
              onChange={handleCategoryChange}
              style={{ width: getWidthBasedOnCategory() }}

            >
              {categories.map((category) => (
                <option key={category.value} value={category.value}>
                  {category.label}
                </option>
              ))}
            </select>
          </div>

          <div className={style.dropdownbar}>
            <img src="/src/assets/Group (3).png" alt="" />
            {/* <form>
              <select
                id={style.parent_selector}
                className={style.event_type_select}
              >
                <option value="">Select Category</option>
                <option value="cat-1">CLOTHES</option>
                <option value="cat-2">HOME APPLIANCES</option>
                <option value="cat-3">OUTDOOR</option>
              </select>
            </form> */}
            <div className={style.search_button_list}>
              <button onClick={handleKeyPress}>Search</button>
            </div>
          </div>
        </div>
        <div className={style.user_details}>
          <div className={style.address_name}>
            <img src="/src/assets/image 6.png" alt="" />
            {/* <p> California, Los Angeles</p> */}

            {/* This needs to be hide */}

            <div style={{ display: "none" }}>
              {/* Render FilterLocation component inside the modal */}
              <FilterLocation
                onLocationChange={handleLocationChange}
                loctiondata={loctiondata}
                setCheckTypeLoc={setCheckTypeLoc}
              />
            </div>


            <div
              className={style.location_feature}
              onClick={location_modal_open}
            >
              <img src="/images/location pin.svg" alt="logo" />
              <h6>
                {locationName ? locationName : <span>Select Location</span>}
              </h6>
            </div>

            <div className={style.locationss}>
              {/* 
              <div style={{display:"none"}}>
            <DummyLocation
            onLocationChange={handleLocationChange}  
            loctiondata={loctiondata} 
            />                  
            </div> */}

              <Modals
                isOpen={modalIsOpenlocation}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModalloc}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div className={style.header_mod_loc}>
                  <h5>Search Location</h5>
                  {/* <div className={style.close_button} onClick={closeModalloc}>
              <i  className="fa-solid fa-xmark"></i>
            </div> */}

                  <button
                    className={style.close_button}
                    onClick={closeModalloc}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                  <div className={style.filter_loc_header}>
                    {/* Render FilterLocation component inside the modal */}
                    <FilterLocation
                      onLocationChange={handleLocationChange}
                      loctiondata={loctiondata}
                      setCheckTypeLoc={setCheckTypeLoc}
                    />
                  </div>
                  {/* {!checkTypeLoc && (
            <div className={style.location_suggestions}>
              <h6>Nearby</h6>
             <div>
              <p>Use current location </p>
             </div>

            

            </div>

            )} */}

                  <div className={style.setItem} onClick={closeModalloc}>
                    Set Location
                  </div>
                </div>
              </Modals>
            </div>
          </div>


          {tokenExist && (
            <div className={style.chat_icon_header} style={{ paddingRight: '10px' }}>
              <Link to="/chatList">
                <i
                  className="fa-regular fa-comment-dots"
                  style={{ color: location.pathname === '/chatList' ? 'red' : 'black' }}
                  onMouseEnter={(e) => (e.currentTarget.style.color = 'red')}
                  onMouseLeave={(e) => (e.currentTarget.style.color = location.pathname === '/chatList' ? 'red' : 'black')}
                >  {chatCount > 0 && <span className="chat-count">{chatCount}</span>}
                </i>          </Link>
            </div>)}



          <div className={style.user_detail_lists}>
            {tokenExist && (
              <span onClick={openModal}>
                <i
                  className="fa-solid fa-heart"
                  style={{ color: modalIsOpen ? 'red' : 'gray' }}
                  onMouseEnter={(e) => (e.currentTarget.style.color = 'red')}
                  onMouseLeave={(e) => (e.currentTarget.style.color = modalIsOpen ? 'red' : 'gray')}
                ></i>
              </span>
            )}

            <Modals
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className={style.header_mod}>
                <button className={style.close_button} onClick={closeModal}>
                  <i className="fa-solid fa-xmark icon"></i>
                </button>
                <div className={style.fav_pop_up}>
                  {tokenExist && favCount && favCount.length > 0 && (
                    <ul style={{ backgroundColor: 'red' }} className={style.ads_styles}>
                      {favCount.map(
                        (item, index) =>
                          item.count > 0 && (
                            <li
                              key={index}
                              className={style.fav_tab}
                              onClick={() =>
                                handleFavouriteCategory(item.category)
                              }
                            >
                              {item.category} ({item.count})
                            </li>
                          )
                      )}
                    </ul>
                  )}
                </div>
              </div>
            </Modals>

            {/* 
         <Link to="/alert_notifications">
              <i class="fas fa-bell"></i>
         </Link> */}

            {tokenExist ? (
              <Link to="/alert_notifications" className={style.notification_link}>
                <i
                  className="fas fa-bell"
                  style={{ color: location.pathname === '/alert_notifications' ? 'blue' : 'gray' }}
                  onMouseEnter={(e) => (e.currentTarget.style.color = 'blue')}
                  onMouseLeave={(e) => (e.currentTarget.style.color = location.pathname === '/alert_notifications' ? 'blue' : 'gray')}
                ></i>
                {count > 0 && (
                  <span className={style.notification_count}>{count}</span>
                )}
              </Link>
            ) : (
              // Add the alternative content or component here
              ''
            )}


            <img src="/src/assets/Group 1686552277.png" alt="" />
            {/* <div className={style.form_item}>
              <div className={style.select_dropdown}>
                <select className={style.big}>
                  <option>Robert</option>
                  <option>Choose me 2</option>
                  <option>Choose me 3</option>
                </select>
                <img src="/src/assets/Ellipse 1 (4).png" alt="" />
              </div>
            </div> */}
            <div className=" button">
              {tokenExist ? (
                <section className={style.profile_logout_btn}>
                  <aside className={style.profile_user_detail_wrapper}>
                    <Tooltip title={user?.name}>
                      <p className={style.username_text}>{user?.name && user?.name?.length > 6 ? user?.name?.slice(0, 6) + "..." : user?.name}</p>
                    </Tooltip>
                    {/* {
                            localStorage?.getItem(`profilePicture`) &&  localStorage?.getItem(`profilePicture`) !== "null" 
                                  ?
                                  <img  style={{ width: "30px", height: "30px", borderRadius: "50%" }} src={localStorage?.getItem(`profilePicture`)} /> :
                                  
                                  <button style={{ borderRadius: "50%" }} type="button" className="btn btn-outline-light" data-bs-toggle="modal" data-bs-target="#signup"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#f29906" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg></button>


                          } */}
                    {localStorage?.getItem("backgroundColor") === null ||
                      localStorage?.getItem("initial") === null ? (
                      <button
                        style={{ borderRadius: "50%" }}
                        type="button"
                        className="btn btn-outline-light"
                        data-bs-toggle="modal"
                        data-bs-target="#signup"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#f29906"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                          <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                      </button>
                    ) : (
                      <button
                        style={{
                          borderRadius: "50%",
                          backgroundColor: backgroundColor,
                          display: "flex",
                          flexDirection: "column",
                          width: "34px",
                          height: "34px",
                          alignItems: "center",
                          border: "none",
                          justifyContent: "center",
                        }}
                        type="button"
                        className="btn "
                        data-bs-toggle="modal"
                        data-bs-target="#signup"
                      >
                        {initial}
                      </button>
                    )}

                    {!toggleProfileDropDown ? (
                      <svg
                        onClick={toggleProfileDropDownHandler}
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M6 9l6 6 6-6" />
                      </svg>
                    ) : (
                      <svg
                        onClick={toggleProfileDropDownHandler}
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M18 15l-6-6-6 6" />
                      </svg>
                    )}
                  </aside>
                  <div
                    ref={dropdownRef}
                    className={
                      toggleProfileDropDown
                        ? `${style.profile_dropdown} ${style.active}`
                        : style.profile_dropdown
                    }
                  >
                    <figure>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#FD7E14"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3" />
                        <circle cx="12" cy="10" r="3" />
                        <circle cx="12" cy="12" r="10" />
                      </svg>
                      <p>
                        <Link to="/profile"> Profile</Link>
                      </p>
                    </figure>

                    {/* <figure>
                      <i
                        style={{ color: "#FD7E14" }}
                        class="fa-regular fa-credit-card"
                      ></i>
                      <p>
                        <Link to="/BillingInformation">
                          Billing Information
                        </Link>
                      </p>
                    </figure> */}

                    <figure>
                      <i
                        style={{ color: "#FD7E14" }}
                        class="fa-regular fa-credit-card"
                      ></i>
                      <p>
                        <Link to="/my_alerts">My Alerts</Link>
                      </p>
                    </figure>

                    {/* My Ads page */}




                    <figure onClick={openMyAdsModal}>
                      <i
                        style={{ color: "#FD7E14" }}
                        class="fa-regular fa-address-book"
                      ></i>
                      <p>
                        My Ads
                      </p>
                    </figure>

                    <Modals
                      isOpen={modalAdsIsOpen}
                      onRequestClose={closeMyAdsModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <div className={style.header_mod}>
                        <button className={style.close_button} onClick={closeMyAdsModal}>
                          <i className="fa-solid fa-xmark"></i>
                        </button>
                        <div className={style.fav_pop_up}>
                          {(tokenExist && myAdsCount && myAdsCount.length > 0) && (
                            <ul>
                              {adsCount > 0 ? myAdsCount.map(
                                (item, index) =>
                                  item.count > 0 && (
                                    <li
                                      key={index}
                                      className={style.fav_tab}
                                      onClick={() =>
                                        handleMyAdsCategory(item.category)
                                      }
                                    >
                                      {item.category} ({item.count})
                                    </li>
                                  )
                              ) : <h1>No Ads Associated</h1>}
                            </ul>
                          )}
                        </div>

                      </div>
                    </Modals>

                    {/* My Ads page */}

                    {JSON.parse(localStorage.getItem(`user`))?.source ===
                      "email" ? (
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#FD7E14"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M15 3h6v18h-6M10 17l5-5-5-5M13.8 12H3" />
                        </svg>
                        <p
                          onClick={() => {
                            toggleModalForRegisterForPasswordChange(true);
                          }}
                        >
                          Change password{" "}
                        </p>
                      </figure>
                    ) : (
                      ``
                    )}

                    <figure>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#FD7E14"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                      </svg>
                      <p
                        onClick={() => {
                          toggleModalForRegisterForDeleteAccount(true);
                        }}
                      >
                        Delete Account
                      </p>
                    </figure>



                    <figure>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#FD7E14"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M15 3h6v18h-6M10 17l5-5-5-5M13.8 12H3" />
                      </svg>
                      <p onClick={demo}>Logout</p>
                    </figure>
                  </div>
                </section>
              ) : (
                <div className={style.loginAndSignupButton}>
                  <button
                    onClick={() => {
                      toggleModalForRegister(true);
                    }}
                    type="button"
                    className="btn btn-outline-light"
                    data-bs-toggle="modal"
                    data-bs-target="#signup"
                  >
                    Sign Up{" "}
                  </button>
                  <button
                    onClick={() => {
                      toggleModalForLogin(true);
                    }}
                    type="button"
                    className="btn btn-outline-light"
                    data-bs-toggle="modal"
                    data-bs-target="#signup"
                  >
                    Sign In
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* <button className={style.post_button}>Post Ad</button> */}
          <button
            onClick={() => {
              console.log("Sourabh");
              clearAdsID();
              toggleModalForPostAdscategories(true);
            }}
            type="button"
            className=" post-btn"
          >
            Post Ad
          </button>
        </div>
      </div>
      <div className={style.nav_bar_menu}>
        <ul >
          {/* <div className={style.dropdown_container} ref={dropdownRef}>
      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" className={style.custom_menu}>
          Rentals
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {rentMenu.map(category => (
            <Dropdown.Item
              key={category.name}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleCategoryClick(category);
              }}
            >
              {category.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      {activeCategory && (
        <div className={style.subcategory_container}>
          {activeCategory.sub_categories.map(subCategory => (
            <div
              key={subCategory.sub_category_name}
              className={`${style.subcategory_item} ${
                activeSubCategory === subCategory ? style.active : ''
              }`}
              onClick={() => handleSubCategoryClick(subCategory)}
            >
              {subCategory.sub_category_name}
            </div>
          ))}
        </div>
      )}
    </div> */}

          <li>
            <NavDropdown
              title="Rentals"
              id="basic-nav-dropdown"
              onClick={() => {
                console.log("in");
                localStorage.setItem("dynamicId", postTypes[0]);
                setopenSubCat(false);
              }}
            >

              {<NavDropdown.Item className="not_sub" onClick={() => navigate(`/view_for_rent`)} style={{ color: '#f17d3a', fontSize: '18px' }}>View All Rentals </NavDropdown.Item>}

              {(
                roomRents?.map((item, index) => {
                  return (
                    <>
                      {!item?.subcategories[0]?.name ?
                        <NavDropdown.Item className="not_sub">{item?.name}</NavDropdown.Item>

                        :
                        <NavDropdown className="cat_name" onClick={(e) => { seteachId(item?._id); seteachCount(item?.rentalTypeCount); localStorage.setItem('hoveredCategory', JSON.stringify(item._id)); localStorage.setItem('categoryName', item.name); setopenSubCat(true); setidSubCat(index) }} title={item?.name} drop="end">
                          <div>
                            {<NavDropdown.Item className="not_sub" onClick={() => { localStorage.removeItem('selectedSubCategory'); navigate(`/view_for_rent`) }} style={{ color: '#f17d3a', fontSize: '18px' }}>View All {item?.name} </NavDropdown.Item>}
                            {/* ({item?.rentalTypeCount}) */}
                            {roomRents[idSubCat]?.subcategories?.map((item, index) => {
                              return (
                                <NavDropdown.Item onClick={() => { localStorage.setItem('selectedSubCategory', JSON.stringify(item._id)); localStorage.setItem('subcategoryName', item.name); navigate(`/view_for_rent/${item?._id}`) }} className="not_sub">{item?.name} </NavDropdown.Item>)
                              // ({item?.count})
                            })}
                          </div>
                        </NavDropdown>
                      }
                    </>
                  )
                }))
              }
            </NavDropdown>
          </li>

          <li>
            <NavDropdown
              title="Jobs"
              id="basic-nav-dropdown"
              onClick={() => {
                localStorage.setItem("dynamicId", postTypes[1]);
                setopenSubCat(false);
                localStorage.removeItem('subcategoryName');
              }}>

              {<p onClick={() => navigate(`/view_for_job/${postTypes[1]}`)} style={{ color: '#f17d3a', fontSize: '18px' }}>View All Jobs  </p>}
              {/* ({jobsCount}) */}

              {
                myjobs?.map((item, index) => {
                  return (
                    <>
                      {!item?.subcategories[0]?.name ?
                        <NavDropdown.Item className="not_sub" onClick={() => { localStorage.removeItem("selectedSubCategory"); localStorage.setItem('hoveredCategory', JSON.stringify(item._id)); localStorage.setItem('categoryName', item?.name); navigate(`/view_for_job/${item?.name}`) }}>{item?.name} </NavDropdown.Item>
                        // ({item?.rentalTypeCount})
                        :
                        <NavDropdown onClick={() => { localStorage.setItem('categoryName', item?.name); localStorage.setItem('hoveredCategory', JSON.stringify(item._id)); setopenSubCat(true); setidSubCat(index) }} title={item?.name} id="basic-nav-dropdown-right" drop="end">
                          {<p onClick={() => { localStorage.removeItem('selectedSubCategory');; navigate(`/view_for_job`) }} style={{ color: '#f17d3a', fontSize: '18px' }}>View All Jobs </p>}
                          {/* ({item?.rentalTypeCount}) */}
                          {myjobs[idSubCat]?.subcategories?.map((item, index) => {
                            return <NavDropdown.Item onClick={() => { localStorage.setItem('subcategoryName', item?.name); localStorage.setItem('selectedSubCategory', JSON.stringify(item._id)); navigate(`/view_for_job/${item?.name}`) }} className="not_sub">{item?.name} </NavDropdown.Item>
                            // ({item?.count})
                          })}
                        </NavDropdown>
                      }
                    </>
                  )
                })
              }
            </NavDropdown>
          </li>

          <li>
            <NavDropdown
              title="Events"
              id="basic-nav-dropdown"
              onClick={() => {
                localStorage.setItem("dynamicId", postTypes[2]);
                setopenSubCat(false);
                localStorage.removeItem('subcategoryName');
              }}>

              {<p onClick={() => navigate(`/view_for_events/${postTypes[2]}`)} style={{ color: '#f17d3a', fontSize: '18px' }}>View All Events </p>}
              {/* ({eventsCount})  */}
              {
                myevents?.map((item, index) => {
                  return (
                    <>
                      {!item?.subcategories[0]?.name ?
                        <NavDropdown.Item className="not_sub" onClick={() => { localStorage.removeItem("selectedSubCategory"); localStorage.setItem('hoveredCategory', JSON.stringify(item._id)); localStorage.setItem('categoryName', item?.name); navigate(`/view_for_events/${(item?.name)}`) }}>{item?.name} </NavDropdown.Item>
                        // ({item?.rentalTypeCount})
                        :
                        <NavDropdown className="cat_name" onClick={() => { localStorage.setItem('hoveredCategory', JSON.stringify(item._id)); localStorage.setItem('categoryName', item?.name); setopenSubCat(true); setidSubCat(index) }} title={item?.name} id="basic-nav-dropdown-right" drop="end">
                          {<p onClick={() => { localStorage.removeItem('selectedSubCategory');; navigate(`/view_for_job`) }} style={{ color: '#f17d3a', fontSize: '18px' }}>View All {item?.name} </p>}
                          {/* ({item?.rentalTypeCount}) */}
                          {myevents[idSubCat]?.subcategories?.map((item, index) => {
                            return <NavDropdown.Item onClick={() => { localStorage.setItem('selectedSubCategory', JSON.stringify(item._id)); navigate(`/view_for_events/${(item?.name)}`) }} className="not_sub">{item?.name} </NavDropdown.Item>
                            // ({item?.count})
                          })}
                        </NavDropdown>
                      }
                    </>
                  )
                })
              }
            </NavDropdown>
          </li>

          <li>
            <NavDropdown 
            title="Buy & Sell" 
            id="basic-nav-dropdown" 
            onClick={() => { 
              localStorage.setItem("dynamicId", postTypes[5]); 
              setopenSubCat(false);
            }}>
              {<p onClick={() =>navigate(`/view_for_Buy_and_Sell`)} style={{ color: '#f17d3a', fontSize: '18px' }}>View All Buy And Sell  </p>}
              {/* ({mybuysellCount}) */}

              {
                buysell?.map((item, index) => {
                  return (
                    <>
                      {!item?.subcategories[0]?.name ?
                        <NavDropdown.Item className="not_sub" onClick={() => { localStorage.removeItem("selectedSubCategory"); localStorage.setItem('hoveredCategory', JSON.stringify(item._id)); localStorage.setItem('categoryName', item?.name); navigate(`/view_for_Buy_and_Sell/${item?.name}`) }}>{item?.name}</NavDropdown.Item>
                        // ({item?.rentalTypeCount})
                        :
                        <NavDropdown className="cat_name" onClick={() => { localStorage.setItem('hoveredCategory', JSON.stringify(item._id)); localStorage.setItem('categoryName', item?.name); setopenSubCat(true); setidSubCat(index) }} title={item?.name} id="basic-nav-dropdown-right" drop="end">
                          {<p onClick={() => { localStorage.removeItem('selectedSubCategory');; navigate(`/view_for_Buy_and_Sell`) }} style={{ color: '#f17d3a', fontSize: '18px' }}>View All {item?.name} </p>}
                          {/* ({item?.rentalTypeCount}) */}
                          {buysell[idSubCat]?.subcategories?.map((item, index) => {
                            return <NavDropdown.Item onClick={() => { localStorage.setItem('selectedSubCategory', JSON.stringify(item._id)); localStorage.setItem('subcategoryName', item?.name); navigate(`/view_for_Buy_and_Sell/${item?.name}`) }} className="not_sub">{item?.name} </NavDropdown.Item>
                            // ({item?.count})
                          })}
                        </NavDropdown>
                      }
                    </>
                  )
                })
              }
            </NavDropdown>
          </li>

          <li>
            <NavDropdown 
            title="Local Biz & Services" 
            id="basic-nav-dropdown" 
            onClick={() => { 
              localStorage.setItem("dynamicId", postTypes[3]); 
              setopenSubCat(false);
              }}>

              {<p onClick={() => { localStorage.removeItem('hoveredCategory'); localStorage.removeItem('selectedSubCategory'); localStorage.removeItem('categoryName'); localStorage.removeItem('subcategoryName'); navigate(`/view_for_localBiz_and_Services`); }} style={{ color: '#f17d3a', fontSize: '18px' }}>View All Local Biz </p>}
              {/* ({bizCount})  */}

              {
                localbiz?.map((item, index) => {
                  return (
                    <>
                      {!item?.subcategories[0]?.name ?
                        <>
                          <NavDropdown.Item className="not_sub" onClick={() => { localStorage.removeItem('selectedSubCategory'); localStorage.setItem('hoveredCategory', JSON.stringify(item._id)); localStorage.setItem('categoryName', item?.name); navigate(`/view_for_localBiz_and_Services/${item?.name}`) }}>{item?.name}</NavDropdown.Item>
                          {/* ({item?.rentalTypeCount}) */}
                        </>
                        :
                        <>
                          <NavDropdown className="cat_name" onClick={() => { localStorage.setItem('hoveredCategory', JSON.stringify(item._id)); localStorage.setItem('categoryName', item?.name); setopenSubCat(true); setidSubCat(index) }} title={item?.name} id="basic-nav-dropdown-right" drop="end">
                            {<p onClick={() => { localStorage.removeItem('selectedSubCategory');; navigate(`/view_for_localBiz_and_Services`) }} style={{ color: '#f17d3a', fontSize: '18px' }}>View All {item?.name} </p>}
                            {/* ({item?.rentalTypeCount}) */}
                            {localbiz[idSubCat]?.subcategories?.map((item, index) => {
                              return (
                                <div>
                                  <NavDropdown.Item className="not_sub" onClick={() => { localStorage.setItem('selectedSubCategory', JSON.stringify(item._id)); localStorage.setItem('subcategoryName', item?.name); navigate(`/view_for_localBiz_and_Services/${item?.name}`) }}>{item?.name} </NavDropdown.Item>
                                  {/* ({item?.count}) */}
                                </div>
                              )
                            })}
                          </NavDropdown>
                        </>
                      }
                    </>
                  )
                })
              }
            </NavDropdown>
          </li>

          <li>
            <NavDropdown 
            title="Babysitter & Nannies" 
            id="basic-nav-dropdown" 
            onClick={() => { 
              localStorage.setItem("dynamicId", postTypes[4]); 
              setopenSubCat(false);
              localStorage.removeItem('subcategoryName');     
              }}>
              {<p onClick={() =>navigate(`/view_for_Babysitter_and_nannies`)} style={{ color: '#f17d3a', fontSize: '18px' }}>View All Babysitter and Nannies </p>}
              {/* ({babysitterCount})  */}

              {
                babysitter?.map((item, index) => {
                  return (
                    <>
                      {!item?.subcategories[0]?.name ?
                        <>
                          <NavDropdown.Item className="not_sub" onClick={() => { localStorage.removeItem("selectedSubCategory"); localStorage.setItem('hoveredCategory', JSON.stringify(item._id)); localStorage.setItem('categoryName', item?.name); navigate(`/view_for_Babysitter_and_nannies/${item?._id}`) }}>{item?.name} </NavDropdown.Item>
                          {/* ({item?.rentalTypeCount}) */}
                        </>
                        :
                        <>
                          <NavDropdown className="cat_name" onClick={() => { localStorage.setItem('hoveredCategory', JSON.stringify(item._id)); localStorage.setItem('categoryName', item?.name); setopenSubCat(true); setidSubCat(index) }} title={item?.name} id="basic-nav-dropdown-right" drop="end">
                            {<p onClick={() => { localStorage.removeItem('selectedSubCategory');; navigate(`/view_for_Babysitter_and_nannies`) }} style={{ color: '#f17d3a', fontSize: '18px' }}>View All {item?.name} </p>}
                            {/* ({item?.rentalTypeCount}) */}
                            {babysitter[idSubCat]?.subcategories?.map((item, index) => {
                              return (
                                <div>
                                  <NavDropdown.Item className="not_sub" onClick={() => { localStorage.setItem('selectedSubCategory', JSON.stringify(item._id)); localStorage.setItem('subcategoryName', item?.name); navigate(`/view_for_Babysitter_and_nannies/${item?.name}`) }}>{item?.name} </NavDropdown.Item>
                                  {/* ({item?.count}) */}
                                </div>
                              )
                            })}
                          </NavDropdown>
                        </>
                      }
                    </>
                  )
                })
              }
            </NavDropdown>
          </li>
        </ul>
      </div>

      <header>
        <div className={style.my_banner}>

          {location.pathname !== "/chat" && location.pathname !== "/chatList" && (
            <Banner homepageBanner={true} />
          )}

          {/* <Banner homepageBanner={true} /> */}
        </div>

        {/* <div className={style.banner_img}>
  <img src={addFreeImage} alt="image"/>
  </div> */}
      </header>

      {/* <div>
      {
      !locationPermission?(
    <>


      <Modal show={showModal} onHide={handleClose} style={{top:"10%"}}>
        <Modal.Header >
          <Modal.Title  style={{padding:"0",textAlign:"center",width:"100%"}}>Location Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>To Use Menehariya please grant location permission  
        <br/>
Kindly Turn On The Location

        </Modal.Body>
        <Modal.Footer style={{width:"100%",display:"block",textAlign:"center"}}>
          <Button variant="primary" onClick={redirectToChromeSettings}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
      </>
      ):null}
    </div> */}

      {/* <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>
        <button onClick={closeModal}>Close</button>
        <div>I am a modal</div>
        <form>
          <input />
          <button>Tab navigation</button>
          <button>Stays</button>
          <button>Inside</button>
          <button>The modal</button>
        </form>
      </Modal>
  </div> */}
    </>
  );
}
