import React from 'react';
import { toast } from 'react-toastify';
import Inputs from '../../Inputs/Inputs';
import SelectLocation from '../select-location/SelectLocation';

import style from './addListerInfo.module.css'

export default function AdsListerInfo({ onChangeHandler = () => { }, formData, isHideMobileNumberChecked = false, isHideEmailAddress1Checked = false, isHideEmailAddressChecked = false, hideEmailNumberSwitchButtonHandler = () => { }, postType = `` }) {


    return (
        <>
            {
                postType === `event` ?
                    <>


                        <Inputs
                            id='span-2'
                            required={true}
                            name='hosted_by'
                            value={formData?.hosted_by}
                            onChangeHandler={onChangeHandler}
                            label='Hosted by'
                            type='text'
                            disabled={localStorage.getItem('listerInfoChange') === 'true'?false:true}
                            placeholder='Enter hosted by'

                        />
                    </> :
                    <Inputs
                        id='span-2'
                        name='name'
                        value={formData?.name}
                        onChangeHandler={onChangeHandler}
                        required={true}
                        disabled={localStorage.getItem('listerInfoChange') === 'true'?false:true}
                        placeholder='Enter name'
                        label='Name'
                        type='text'
                    />
            }

            {

            }


           <section id='span-2'>
           <Inputs

                name='email_address'
                required={true}
                value={formData?.email_address}
                onChangeHandler={onChangeHandler}
                placeholder='Enter email address'
                label='Email address'
                disabled={true}
                type='text'
            />
            <span className={style.email_hide_message_text}>Your email address will not be shared with others.</span>
           </section>

            <Inputs
                name='primary_phone_number'
                value={formData?.primary_phone_number}
                id='span-2'
                onChangeHandler={onChangeHandler}
                placeholder='Enter primary phone number'
                label='Primary phone number'
                required={true}
                type='number'
            />

            <Inputs
                name='secondary_phone_number'
                id='span-2'
                value={formData?.secondary_phone_number}
                onChangeHandler={onChangeHandler}
                placeholder='Enter Secondary phone number'
                label='Secondary phone number'
                type='number'
            />
            {/* <Inputs
                label='Hide my email address'
                type='switch'
                switchBtnChecked={isHideEmailAddressChecked}
                onChangeHandler={hideEmailNumberSwitchButtonHandler}
                name='hide_email_address'
                value={formData?.hide_email_address}
                className={style.switch_input_field}
            /> */}
{/* 
            <Inputs
                label='Hide my email address'
                type='switch'
                id='span-2'
                min={10}
                max={15}
                inputClassName={style.switch_btn_wrapper}
                switchBtnChecked={isHideEmailAddress1Checked}
                onChangeHandler={hideEmailNumberSwitchButtonHandler}
                name='hide_my_email_address'
                required={true}
                value={isHideEmailAddress1Checked}
                className={style.switch_input_field1}
            /> */}

            <Inputs
                label='Hide my primary phone number'
                type='switch'
                id='span-2'
                min={10}
                max={15}
                inputClassName={style.switch_btn_wrapper}
                switchBtnChecked={isHideMobileNumberChecked}
                onChangeHandler={hideEmailNumberSwitchButtonHandler}
                name='hide_primary_phone_number'
                required={true}
                value={isHideMobileNumberChecked}
                className={style.switch_input_field1}
                
            />

            {
                formData.secondary_phone_number && (formData.secondary_phone_number.length > 9 && formData.secondary_phone_number.length <= 15) ?

                    <Inputs
                        label='Hide my secondary phone number'
                        type='switch'
                        required={true}
                        id='span-2'
                        inputClassName={style.switch_btn_wrapper}
                        switchBtnChecked={isHideEmailAddressChecked}
                        onChangeHandler={hideEmailNumberSwitchButtonHandler}
                        name='hide_secondary_phone_number'
                        value={isHideEmailAddressChecked}
                        className={style.switch_input_field1}
                    />
                    : ``
            }



        </>
    )

}
