import './App.css';
import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Test2 from './pages/Test2';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Home from './pages/home/Home'
import AboutUs from './pages/about-us/About';
import Privacy from './pages/privacypolicy/Privacy';
import Termandcondition from './pages/Termandcondition.jsx/Term';
import LandingPage from './pages/Landing-page/Landingpage';
import Jobpage from './pages/Jobpost-page/Jobpost';
import Localvalpage from './pages/Locval-job-page/Locval';
import Joblisting from './pages/Job-listing-page/Joblisting';
import Detailpage from './pages/Detail_page/Detail';
import Virtualjob from './pages/Virtual-jobs/Virtualjob';
import Virtualjobpage from './pages/Virtual-job-page/Virtualjoblist';
import Virtualdetailpage from './pages/Virtual-job-detail-page/Virtualjobdetail';
import Eventlistpage from './pages/Eventing-listing-page/Eventinglistpage';
import Eventlistingpage from './pages/Eventing-listing-detail-page/eventinglistingdetail';
import Venuebasedpage from './pages/Venue-based-page/Venuebaseddetail';
import Liveeventpage from './pages/Liveevent-page/Liveevent';
import Buysellpage from './pages/Buy and sell page/Buysell';
import Buyselllistpage from './pages/Buyselllisting-page/Buyselllist'
import Rent_View from './pages/views/Rent_view/Rent_View';
import Job_View from './pages/views/Job_View/Job_View';
import Event_view from './pages/views/Event_view/Event_view';
import BabySitter_view from './pages/views/BabySitter_view/BabySitter_view';
import BuySell_view from './pages/views/BuySell_view/BuySell_view';
import LocalBiz_view from './pages/views/LocalBiz_view/LocalBiz_view';
import Chat from './component/Chat/Chat';
import ChatList from './component/Chat/ChatList/ChatList';

import Rent_Details from './pages/DetailPages/Rent_Details/Rent_Details';
import Job_Details from './pages/DetailPages/Job_Details/Job_Details';
import Event_Details from './pages/DetailPages/Event_Details/Event_Details';
import Local_Details from './pages/DetailPages/Local_Biz_Details/Local_Details';
import Buy_Sell_Details from './pages/DetailPages/Buy_Sell_Details/Buy_Sell_Details';
import Baby_Sitter_Details from './pages/DetailPages/BabyNannySitter/Baby_Sitter_Details';

import RentalAds from './component/MyAds/RentalAds/RentalAds';
import JobAds from './component/MyAds/JobAds/JobAds';
import BabySitterAds from './component/MyAds/BabySitterAds/BabySitterAds'
import EventAds from './component/MyAds/EventAds/EventAds';
import BuySellAds from './component/MyAds/BuySellAds/BuySellAds';
import LocalBizAds from './component/MyAds/LocalBizAds/LocalBizAds';
import Search_query from './component/SearchComponent/Search_query';

import Seller_profile from './pages/Seller_profile/Seller_profile';
import TiktokImage from './assest/images/tik-tok.png'


import 'react-toastify/dist/ReactToastify.css';
import standardFetchHandlers from './utils/standardFetchHandlers';
import handleFetchErrors from './utils/handleFetchErrors';
import { GoogleOAuthProvider } from '@react-oauth/google';
import PlacesAutocomplete from './pages/PlacesAutocomplete';
import getFetchConfig from './utils/getFetchConfig';
import Profile from './pages/profile/Profile';
import Billing_info from './pages/Billing_info/Billing_info';
import Ads_alert from './pages/Ads_alert/Ads_alert';
import Show_alerts from './pages/Show_alerts/Show_alerts';
import Form from './component/postAds/Form';
import PaymentFailure from './component/payment-failure/PaymentFailure';
import PaymentSuccess from './component/payment-success/PaymentSuccess';
import ChangeEmailAddress from './pages/change-emailaddress/ChangeEmailAddress';
import { loadStripe } from '@stripe/react-stripe-js';
import LocationPermissionChecker from './component/LocationPermission/LocationPermission';
import Header from './component/header/Header';
import { Facebook, Instagram, LinkedIn, Telegram, Twitter, YouTube } from '@mui/icons-material';
import appURLs from './appURL';
import Footer_menu from './component/footer_menu';
import Footer_menu_other from './component/postAds/footer_menu_other';
// import Seller_profile from './pages/Seller_profile/Seller_profile';
import PagePreview from './component/PagePreview';
import HelpAndSupport from './pages/HelpAndSupport/HelpAndSupport';

function App() {
  const location = useLocation();
  const appURL = appURLs();
  const [stripePromise, setStripePromise] = useState(null);
  const [locationPermission, setLocationPermission] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [checkJobEdit, setcheckJobEdit] = useState(false)
  const [checkRentalEdit, setcheckRentalEdit] = useState(false)
  const [checkBabyEdit, setcheckBabyEdit] = useState(false);
  const [checkEventEdit, setcheckEventEdit] = useState(false);
  const [checkLocalBizEdit, setcheckLocalBizEdit] = useState(false)
  const [checkBuySellEdit, setCheckBuySellEdit] = useState(false);
  const [myselectedChat, setmyselectedChat] = useState('');
  // For chat functionality ********************
  const [adsId, setAds_Id] = useState('');
  const [sellerId, setSellerId] = useState('');
  const [senderId, setSenderId] = useState('');
  const [buyerId, setBuyerId] = useState('');
  const [ads_type, setAds_type] = useState('');
  const [forPostAd, setforpostAd] = useState(true);

  // ******************** For chat functionality

  const [adsIds, setadsIds] = useState("")

  console.log(adsIds, "adsId11111");

  const [changeState, setChangeState] = useState(false);

  const [lat, setLat] = useState(null);
  const [lang, setLang] = useState(null);
  const [refreshList, setrefreshList] = useState(false);

  const [count, setCount] = useState(0);
  const [loaderCheck, setloaderCheck] = useState('');

  console.log(locationPermission, "hello")

  const geetingDataLTLNG = (lat, lang) => {
    setLang(lat);
    setLat(lang)
  }

  const locationChange = (lat, lang) => {
    setLat(lat);
    setLang(lang);

    console.log(lat, "checking the lat");

  };



  useEffect(() => {
    fetch("/config").then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  const [userData, updateUserData] = useState({});

  const fetchConfig = getFetchConfig();

  const fetchingSellerAccountDetail = () => {

    fetch(`${appURL}/user-profile`, {
      ...fetchConfig,
      method: `GET`
    })
      .then(handleFetchErrors)
      .then((sellerAccountJOSNReponse) => {

        console.log(sellerAccountJOSNReponse)

        const sellerAccountDetail = sellerAccountJOSNReponse?.user;

        if (sellerAccountDetail) {

          // //destructing sller data Object
          const sellerDataObj = {
            name: sellerAccountDetail.name,
            email_address: sellerAccountDetail.email_address,
            gender: sellerAccountDetail.gender,
            phone_number: sellerAccountDetail.phone_number,
            profile_image: sellerAccountDetail?.profile_image || ``,
          };

          updateUserData(sellerDataObj)

        }
      })
      .catch(standardFetchHandlers.error);
  };

  useEffect(fetchingSellerAccountDetail, []);

  const checkRoutes = () => {
    const { pathname } = location;
    // Check specific routes and set localStorage if needed
    if (pathname === '/jobsAds' || pathname === '/rentalAds' || pathname === '/babysitterAds' || pathname === '/localBizAds' || pathname === '/eventsAds' || pathname === '/buySellAds') {

      // If the pathname is '/localBizAds', set the localStorage item
      if (pathname === '/localBizAds') {
        localStorage.setItem("currentPage", "localBiz");
      }
      localStorage.removeItem("currentPage");
      return false;
    }

    return true;
  };
  
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top of the page
  }, [location]);
  

  const checkChatRoutes = () => {
    if (location.pathname === '/chatList' || location.pathname === '/chat'
      || location.pathname === '/jobsAds' || location.pathname === '/rentalAds' || location.pathname === '/babysitterAds' || location.pathname === '/localBizAds' || location.pathname === '/eventsAds'
      || location.pathname === '/buySellAds' || location.pathname.slice(0, location.pathname.indexOf('/', 1)) === '/rentDetails' || location.pathname.slice(0, location.pathname.indexOf('/', 1)) === '/jobDetails' || location.pathname.slice(0, location.pathname.indexOf('/', 1)) === '/eventDetails' || location.pathname.slice(0, location.pathname.indexOf('/', 1)) === '/localBizDetails'
      || location.pathname.slice(0, location.pathname.indexOf('/', 1)) === '/buySellDetails' || location.pathname.slice(0, location.pathname.indexOf('/', 1)) === '/babySitterDetails'
    ) return false;
    return true;
  }

  console.log(changeState, "changeStatechangeStatechangeStatechangeState")

  console.log(checkJobEdit, "checkJobEditcheckJobEditcheckJobEditcheckJobEdit");

  return (
    <>
      <PagePreview />
      <GoogleOAuthProvider clientId="304304597733-8nquelhhlt6hgitk84j5vf8vu9hoebmj.apps.googleusercontent.com">
        <ToastContainer />
        {/* <ChatButton /> */}


        <Header setrefreshList={setrefreshList} refreshList={refreshList} getCoordinates={geetingDataLTLNG} count={count} setChangeState={setChangeState}
          checkJobEdit={checkJobEdit} setcheckJobEdit={setcheckJobEdit} setcheckRentalEdit={setcheckRentalEdit} checkRentalEdit={checkRentalEdit} adsIds={adsIds}
          setadsIds={setadsIds} checkBabyEdit={checkBabyEdit} setcheckBabyEdit={setcheckBabyEdit} checkEventEdit={checkEventEdit} setcheckEventEdit={setcheckEventEdit} checkLocalBizEdit={checkLocalBizEdit} myselectedChat={myselectedChat} setcheckLocalBizEdit={setcheckLocalBizEdit} checkBuySellEdit={checkBuySellEdit} setCheckBuySellEdit={setCheckBuySellEdit} setforpostAd={setforpostAd} forPostAd={forPostAd} />

        <Routes>
          {/* Auth */}

          <Route exact index path="/" element={<Home latitudes={lat} longitudes={lang} setCount={setCount} />} />
          <Route exact index path="/aboutus" element={<AboutUs />} />
          <Route exact index path="/privacy" element={<Privacy />} />
          <Route exact index path="/contactus" element={<HelpAndSupport/>} />
          <Route exact index path="/termAndCondition" element={<Termandcondition />} />
          <Route exact index path="/LandingPage" element={<LandingPage />} />
          <Route exact index path="/Jobpage" element={<Jobpage />} />
          <Route exact index path="/Localvalpage" element={<Localvalpage />} />
          <Route exact index path="/Joblisting" element={<Joblisting />} />
          <Route exact index path="/Detailpage" element={<Detailpage />} />
          <Route exact index path="/Virtualjob" element={<Virtualjob />} />
          <Route exact index path="/Virtualjobpage" element={<Virtualjobpage />} />
          <Route exact index path="/Virtualdetailpage" element={<Virtualdetailpage />} />
          <Route exact index path="/Eventlistpage" element={<Eventlistpage />} />
          <Route exact index path="/Eventlistingpage" element={<Eventlistingpage />} />
          <Route exact index path="/Venuebasedpage" element={<Venuebasedpage />} />
          <Route exact index path="/Liveeventpage" element={<Liveeventpage />} />
          <Route exact index path="/Buysellpage" element={<Buysellpage />} />
          <Route exact index path="/Buyselllistpage" element={<Buyselllistpage />} />
          <Route exact index path="/BillingInformation" element={<Billing_info />} />
          <Route exact index path="/my_alerts" element={<Ads_alert />} />
          <Route exact index path="/alert_notifications" element={<Show_alerts count={count} />} />

          <Route exact index path="/view_for_rent" element={<Rent_View latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_rent/:search" element={<Rent_View latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_rent/:subCategory" element={<Rent_View latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_rent/:category" element={<Rent_View latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_rent/fav_rent" element={<Rent_View latitudes={lat} longitudes={lang} />} originalLocation={locationChange} />

          <Route exact index path="/view_for_job" element={<Job_View latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_job/:search" element={<Job_View latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_job/:subCategory" element={<Job_View latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_job/:category" element={<Job_View latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_job/fav_job" element={<Job_View latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />

          <Route exact index path="/view_for_events" element={<Event_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_events/:search" element={<Event_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_events/:subCategory" element={<Event_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_events/:category" element={<Event_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_events/fav_event" element={<Event_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />

          <Route exact index path="/view_for_Babysitter_and_nannies" element={<BabySitter_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_Babysitter_and_nannies/:search" element={<BabySitter_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_Babysitter_and_nannies/:subCategory" element={<BabySitter_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_Babysitter_and_nannies/:category" element={<BabySitter_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_Babysitter_and_nannies/fav_baby_sitter" element={<BabySitter_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_Buy_and_Sell" element={<BuySell_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_Buy_and_Sell/:search" element={<BuySell_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_Buy_and_Sell/:subCategory" element={<BuySell_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_Buy_and_Sell/:category" element={<BuySell_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_Buy_and_Sell/fav_buy_sell" element={<BuySell_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />

          <Route exact index path="/view_for_localBiz_and_Services" element={<LocalBiz_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_localBiz_and_Services/:search" element={<LocalBiz_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_localBiz_and_Services/:subCategory" element={<LocalBiz_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_localBiz_and_Services/:category" element={<LocalBiz_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />
          <Route exact index path="/view_for_localBiz_and_Services/fav_local_service" element={<LocalBiz_view latitudes={lat} longitudes={lang} originalLocation={locationChange} />} />

          <Route exact index path="/rentDetails/:rentalId" element={<Rent_Details latitudes={lat} longitudes={lang} setAds_Id={setAds_Id} setSellerId={setSellerId} setSenderId={setSenderId} setBuyerId={setBuyerId} setAds_type={setAds_type} />} />
          <Route exact index path="/jobDetails/:jobId" element={<Job_Details latitudes={lat} longitudes={lang} />} />
          <Route exact index path="/eventDetails/:eventId" element={<Event_Details latitudes={lat} longitudes={lang} />} />
          <Route exact index path="/localBizDetails/:localId" element={<Local_Details latitudes={lat} longitudes={lang} />} />
          <Route exact index path="/buySellDetails/:buyId" element={<Buy_Sell_Details latitudes={lat} longitudes={lang} />} />
          <Route exact index path="/babySitterDetails/:babyId" element={<Baby_Sitter_Details latitudes={lat} longitudes={lang} />} />


          <Route exact index path="/RentalAds" element={<RentalAds latitudes={lat} longitudes={lang} setcheckRentalEdit={setcheckRentalEdit} setadsIds={setadsIds} />} />
          <Route exact index path="/jobsAds" element={<JobAds latitudes={lat} longitudes={lang} setcheckJobEdit={setcheckJobEdit} setadsIds={setadsIds} />} />
          <Route exact index path="/babysitterAds" element={<BabySitterAds latitudes={lat} longitudes={lang}
            setcheckBabyEdit={setcheckBabyEdit} setadsIds={setadsIds}
          />} />
          < Route exact index path="/localBizAds" element={<LocalBizAds latitudes={lat} longitudes={lang}
            setcheckLocalBizEdit={setcheckLocalBizEdit} setadsIds={setadsIds}
          />} />

          <Route exact index path="/eventsAds" element={<EventAds latitudes={lat} longitudes={lang}
            setcheckEventEdit={setcheckEventEdit} setadsIds={setadsIds} forPostAd={forPostAd}
          />} />
          <Route exact index path="/buySellAds" element={<BuySellAds latitudes={lat} longitudes={lang}
            setCheckBuySellEdit={setCheckBuySellEdit} setadsIds={setadsIds}
          />} />

          <Route exact index path="/chat" element={<Chat adsId={adsId} sellerId={sellerId} senderId={senderId} loaderCheck={loaderCheck} setloaderCheck={setloaderCheck} ads_type={ads_type} />} />
          <Route exact index path="/chatlist" element={<ChatList setrefreshList={setrefreshList} loaderCheck={loaderCheck} setloaderCheck={setloaderCheck} refreshList={refreshList} myselectedChat={myselectedChat} setmyselectedChat={setmyselectedChat} />} />
          <Route exact index path="/seller_profile/:userId" element={<Seller_profile />} />
          <Route exact index path="/profile" element={<Profile latitudes={lat} longitudes={lang} />} />
          <Route exact index path="/form" element={<Form />} />
          <Route exact index path="/change-emailaddress" element={<ChangeEmailAddress />} />
          <Route exact index path="/search-results/:mySearch" element={<Search_query latitudes={lat} longitudes={lang} />} />
          <Route path="/success" element={<PaymentSuccess />} />
          <Route path="/cancel" element={<PaymentFailure />} />
        </Routes>
        {checkRoutes() && <div className="apps">
          <div className="apps-section">
            <h4>Try Menehariya Mobile App </h4>
            <p>
              Rentals, buy & sell, and find just about anything using the app on your mobile.
            </p>
          </div>

          <div className="apps-section1">
            <h6>GET YOUR APP TODAY </h6>
            <div className="links-images">
              <img src="/images/app.png" alt="logo" onClick={() => {
                window.open("https://apps.apple.com/in/app/menehariya/id6446753677");
              }} />
              <img src="/images/google.png" alt="logo" onClick={() => {
                window.open("https://play.google.com/store/apps/details?id=com.menehariya.menehariyaapp&pli=1");
              }} />
              <img src="/images/gallery.jpg" alt="logo" style={{ visibility: 'hidden' }} />
            </div>
          </div>
        </div>}
        {checkRoutes() && <div className=" footer">
          <div className="footer-wraper">
            <img src="/images/logo_final.png" alt="logo" />
            <p>Menehariya is a free classified ads platform designed for the Ethiopian and Eritrean diaspora community.</p>

          </div>
          <Footer_menu title="QUICK LINKS" text1="Rentals" text2="Jobs" text3="Events" text4="Buy & Sell" text5="Babysitter & Nannies" text6="Local Biz & services" />
          <Footer_menu_other title="INFO" text1="About Us" text2="Help & Support" text3="Terms & Conditions" text4="Privacy Policy" />
          <div className="media-heading">
            <h6>SOCIAL MEDIA</h6>
            <p className='footer_icon'>
              <Facebook onClick={() => { window.open("https://www.facebook.com/profile.php?id=100092789332133") }} />
              <Twitter onClick={() => { window.open("https://x.com/menehariyaapp?s=21&t=9wies627SSRGwaUhZZGS1A") }} />
              <Instagram onClick={() => { window.open("https://www.instagram.com/menehariya_app") }} />

              <YouTube onClick={() => { window.open("https://www.youtube.com/@Menehariya") }} />
              <LinkedIn onClick={() => { window.open("https://www.linkedin.com/company/menehariya/") }} />
              <Telegram onClick={() => { window.open("https://t.me/menehariya_app") }} />
              <img onClick={() => { window.open("https://www.tiktok.com/@menehariyat?is_from_webapp=1&sender_device=pc") }} src={TiktokImage} height={25} width={30} />

            </p>
            <div className='footer_language'>
              <select className="selectpicker" data-width="fit">
                <option data-content='<span className="flag-icon flag-icon-us"></span> English'>English
                </option>
                <option data-content='<span className="flag-icon flag-icon-mx"></span> Español'>Español
                </option>
              </select>
            </div>
          </div>
        </div>}

      </GoogleOAuthProvider>

    </>
  );
}

export default App;
