import React, { useEffect, useState } from "react";
import Chat from "../Chat";
import "./ChatList.css";
import getFetchConfig from "../../../utils/getFetchConfig";
import appURLS from "../../../appURL";
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import ImageIcon from '@mui/icons-material/Image';
import { io } from "socket.io-client";

function ChatList({setrefreshList,refreshList,setmyselectedChat}) {
  const appURL = appURLS();
  const fetchConfig = getFetchConfig();

  const [newMessages,setnewMessages]=useState([]);
  const [detailData,setdetailData]=useState(true);
  const ENDPOINT = 'https://menehariya-chat.netscapelabs.com/';
  const sellerId  = localStorage.getItem("sellerIdChat");


  const colorList = {
    A: "#00FFFF",
    B: "#0000FF",
    C: "#FF8C00",
    D: "#FFD700",
    E: "#008000",
    F: "#FF00FF",
    G: "#DDA0DD",
    H: "#FF69B4",
    I: "#4B0082",
    J: "#00A86B",
    K: "#F0E68C",
    L: "#E6E6FA",
    M: "#000080",
    N: "#E0115F",
    O: "#808000",
    P: "#800080",
    Q: "#E0D0C6",
    R: "#FF0000",
    S: "#C0C0C0",
    T: "#008080",
    U: "#808000",
    V: "#D2691E",
    W: "#EE82EE",
    X: "#738678",
    Y: "#FFFF00",
    Z: "#000000",
  };


  const [listData, setListData] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [socket, setSocket] = useState(null);

  const getMessageCount = localStorage.getItem("message_count");
 

  const chatlistsdetailsData = async () => {
    // alert('hello');
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };
      const response = await fetch(`${appURL}/chat/chat-list`, {
        ...fetchConfig,
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      setListData(jsonData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(()=>{
    console.log("selectedChat",selectedChat);
  },[selectedChat])

  const checker = localStorage.getItem('adsIdChat');


  useEffect(() => {
    
    const newSocket = io(ENDPOINT, {
      transports: ['websocket'],
      autoConnect: true,
      // query: `userId=${map.senderId}`
      query: `userId=${sellerId}`
    });

    
    newSocket.on('receive-message', async(data)=>{
      chatlistsdetailsData();
      // handleListClick(selectedChat,'hey');
      // if(data?.ads_id === selectedChat?.ads_id){
      // updateMessageCount(listData,data?.ads_id);
      // }
    })

    setSocket(newSocket);

    // Cleanup function
    return () => {
      newSocket.disconnect();
    };
  }, [sellerId]);

  const updateMessageCount = async(adsData, targetAdsId) => {
    // Find the object with the matching ads_id
    console.log("adsData and",adsData,targetAdsId);
    const ad = adsData.find(item => item.ads_id === targetAdsId);
  
    // If the ad is found, increment its messageCount
    if (ad) {
      ad.messageCount = 0;
    } else {
      console.log(`Ad with ads_id: ${targetAdsId} not found.`);
    }
  
    return adsData;
  };

  const handleListClick = (list,check) => {
    console.log("list check",list);
    setmyselectedChat(list?._id);
    check !== 'hey' && setrefreshList(!refreshList)
    console.log("checklocal",typeof(localStorage.getItem("forChatCount")));
    if(localStorage.getItem("forChatCount") === 'true'){
      localStorage.setItem("forChatCount",false)
    }else if(localStorage.getItem("forChatCount") === 'false'){
      localStorage.setItem("forChatCount",true)
    }
    localStorage.setItem("adsTypeChat", list?.ads_type);
    localStorage.setItem("adsIdChat", list?.ads_id);
    localStorage.setItem("sellerIdChat", list?.sellerId);
    localStorage.setItem("userIdchat", list?.buyerId);
    localStorage.setItem("buyerId", list?.buyerId);
    localStorage.setItem("chatId",list?._id);
    // window.location.reload();
    setSelectedChat(list);
    console.log("selectedCjha",list);
    chatlistsdetailsData();
  };

  const containsUrl = (message) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return urlRegex.test(message);
  };

  useEffect(() => {
    chatlistsdetailsData();
  }, [checker]);

  useEffect(() => {
    // This effect will run whenever the values in local storage change
    const handleStorageChange = () => {
      chatlistsdetailsData();
    };

    console.log('running');

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []); 


  const getBackgroundColor = (initial) => {
    const color = colorList[initial.toUpperCase()] || "#FFFFFF"; // Default to white if the initial is not found
    return { backgroundColor: color };
  };

  const sendChatlistData=async()=>{
    return listData;
  }

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    let formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    formattedTime = formattedTime.replace(/^0/, '');
    return formattedTime;
  };

  useEffect(()=>{
    console.log("selectedChathrechatlist",selectedChat);
  },[selectedChat])

  return (
    <div>
      <div className="MainChat">
        <div className="Chat_lists">
      <h2>Chats</h2>
      {listData && Array.isArray(listData) && listData.map((list, index) => (
  <div key={index} className="list_showing" onClick={() => handleListClick(list)} style={{backgroundColor:`${selectedChat?._id === list?._id ?'lightgray':''}`}}>
    <div className="image_profilo"> 
      {((list?.sellerId === (JSON.parse(localStorage.getItem("user"))?.userId)&&(list?.buyer_image !==null && list?.buyer_image !=="null")) ||(list?.sellerId !== (JSON.parse(localStorage.getItem("user"))?.userId)&&(list?.seller_image !== null && list?.seller_image !== "null"))) ? (
        <img src={list?.sellerId === (JSON.parse(localStorage.getItem("user"))?.userId)?list?.buyer_image:list?.seller_image} alt="seller" />
      ) : (
        <div className="seller_placeholder" style={getBackgroundColor(list && list?.seller_name ? list?.seller_name[0].toUpperCase() : '')}>
          <p> {list?.sellerId === (JSON.parse(localStorage.getItem("user"))?.userId)?list?.buyer_name[0]:list?.seller_name[0]} </p>
        </div>
      )}  
    </div>
    <div className="chat_content">
      <div>
        {list && list?.sellerId === JSON.parse(localStorage.getItem("user"))?.userId?<h5>{list?.buyer_name}</h5>:<h5>{list?.seller_name}</h5>}
        {list && list?.ads_name && <p>{list?.ads_name}</p>} 
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        {list && list?.messages && list?.messages.length > 0 && list?.messages[0].content && (
          <span style={{fontWeight:`${list?.messages[0].status === 'unseen' && list?.messages[0]?.senderId !== JSON.parse(localStorage.getItem("user"))?.userId?'bold':'normal'}`,color:`${list?.messages[0].status === 'unseen' && list?.messages[0]?.senderId !== JSON.parse(localStorage.getItem("user"))?.userId?'black':'gray'}`}}>{containsUrl(list?.messages[0].content)?<div style={{display:'flex'}}><ImageIcon/><p>Image</p></div>:list?.messages[0].content}</span>
          )}
          </div>
      </div>
      <div className="timer_stamp">
        {list && list?.messages && list?.messages.length > 0 && (
          <span>{formatTime(list?.messages[0].timestamp)}</span>
        )}
      </div>
          {list?.messageCount !== 0 && (list?._id !== selectedChat?._id) && <span style={{fontWeight:'bold',display:'flex',alignItems:'flex-end',color:'red'}}><MarkUnreadChatAltIcon />{list?.messageCount?list?.messageCount:''}</span>}
    </div>
  </div>
))}

        </div>
        <div className="chat_model">
         <Chat selectedChat={selectedChat} setSelectedChat={setSelectedChat} newMessages={newMessages} setnewMessages={setnewMessages} listData={listData} setdetailData={setdetailData}/>
         </div>
      </div>
    </div>
  );
}

export default ChatList;
