import React, { useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import getFetchConfig from '../../../utils/getFetchConfig';
import handleFetchErrors from '../../../utils/handleFetchErrors';
import standardFetchHandlers from '../../../utils/standardFetchHandlers';
import {
    isTruthyString,
    isValidEmailAddress,
    isDescriptionHas5Words,
} from '../../../utils/verification';
import moment from 'moment';
import Inputs from '../../Inputs/Inputs';
import PostAdsHeading from '../post-ads-heading/PostAdsHeading';
import FormButton from '../../buttons/form-button/FormButton';
import AdsListerInfo from '../ads-lister-info/AdsListerInfo';
// import AdsPlans from '../ads-plans/AdsPlans';
import AdsPlansUpdated from '../ads-plans/AdsPlansUpdated';
import ImageUpload from '../images-upload/ImageUpload';
import MultiSelect from '../../form-input/MultiSelect';
import RichTextEditor from '../../form-input/RichTextEditor';
import SelectLocation from '../select-location/SelectLocation';
import formDataAppender from '../../../utils/formDataAppender';
import CongratulationsScreen from '../congratulations-screen/CongratulationsScreen';

import appURLs from '../../../appURL';


import style from "./jobs.module.css"
import cuid from 'cuid';

export default function Jobs({ toggleModalVisibilty = () => { }, adsCategoriesId = ``, selectedJobType = ``, adsId = "", ad_steps = null, adsIds, setadsIds }) {


    console.log(adsCategoriesId, "adsCategoriesId");


    // console.log(`selectedJobType`, selectedJobType)
    const dateFormat = 'MM/DD/YYYY';


    const fetchConfig = getFetchConfig(),
        appURL = appURLs();
    // add experience section

    const [experienceLists, updateExperienceLists] = useState([]);
    const [hideIcon, setHideIcon] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [myadsId, setmyadsId] = useState('');
    const [myadsType, setmyadsType] = useState('');


    const [selectedFiles, setSelectedFiles] = useState([]);
    const [locationResponseData, setLocationResponseData] = useState(true);

    const [jobsDynamicData, updateJobsDynamicData] = useState({}),
        [selectedTagline, upatedSelectedTagline] = useState([]),
        [selectedSkill, updateSelectedSkill] = useState([]),
        [jobAdsId, updateJobAdsId] = useState(null),
        [images, updateImage] = useState([]),
        [formData, updateFormData] = useState({

            title: "",
            descriptions: "",
            categories: "",
            type: "",
            role: "",
            video_link: "",
            language: "",
            tagline: [],
            salary: null,
            currency: jobsDynamicData?.currency?.[0],
            amount: null,
            listing_type: "",
            salary_info: jobsDynamicData?.salary_info?.[0],
            no_of_opening: null,
            job_website_link: "",
            employment_type: "",
            other_language: "",
            work_authorization: "",
            preferred_gender: "",
            experience: [],




            name: JSON.parse(localStorage.getItem(`user`))?.name,
            email_address: JSON.parse(localStorage.getItem(`user`))?.email_address,
            primary_phone_number: JSON.parse(localStorage.getItem(`user`))?.phone_number,
            secondary_phone_number: "",
            hide_email_address: "",
            hide_phone_number: false,
            website_link: "",
            adsType: "",
            status: "draft"
        });

    const [showLoader, toggleLoader] = useState(false),
        [steps, updateSteps] = useState(ad_steps || 0);

    const [lat, setlat] = useState("")
    const [lng, setlng] = useState("")

    const {
        categories,
        type,
        role,
        title,
        descriptions,
        experience,

        language,
        other_language,
        employment_type,
        salary,
        currency,
        amount,
        tagline,
        salary_info,
        no_of_opening,
        preferred_gender,
        listing_type,

        job_website_link,
        video_link,
        work_authorization,
        location,
        location_name,
        longitude,
        latitude,

        name,
        email_address,
        primary_phone_number,
        secondary_phone_number,
        hide_phone_number,
        hide_email_address,
        website_link,
        adsType,
        status

    } = formData;


    // fetching ads categories
    const fetchingDynamicsData = () => {

        fetch(`${appURL}/posts/jobs/dynamics-data?PostType=${adsCategoriesId || "64e48fa9f9eeb14b6e2135be"}`, {
            ...fetchConfig,
            method: "GET"
        })
            .then(handleFetchErrors)
            .then((res) => {
                console.log(res)

                if (res.status === 200) {
                    const jobsDynamicDataObj = res?.data;
                    if (jobsDynamicDataObj) updateJobsDynamicData(jobsDynamicDataObj)
                    updateFormData({
                        ...formData,
                        salary_info: jobsDynamicData?.salary_info?.[0] || "/hour",
                        currency: jobsDynamicData?.currency?.[0] || "USD",
                        // listing_type: jobsDynamicData?.list_type
                    })
                    console.log(salary_info, "njnfjnrjnfrjnjrnr");

                } else {
                    return toast.error(`Enable fetch dynamics data`);
                }

            })
            .catch((err) => standardFetchHandlers.error(err));
    }

    const onChangeHandlerForForm = (e) => {
        const inputName = e?.target?.name || e?.name;
        let inputValue = e?.target?.value || e?.value;

        if (inputName === 'salary' || inputName === 'amount') {
            let inputValue1 = e.target.value.replace(/[^0-9.]/g, '');

            if (/^0+$|^0+[1-9][0-9]*$/.test(inputValue1)) {
                setErrorMessage("Please enter a valid digit");
            } else {
                setErrorMessage('');
            }

            if (inputValue1.length > 7) {
                inputValue1 = inputValue1.slice(0, 7);
            }

            inputValue = inputValue1;
        }


        if (inputName === `primary_phone_number` || inputName === `secondary_phone_number`) {
            const inputNumber1 = e.target.value.replace(/\D/g, ""); // Remove non-digit characters

            if (inputNumber1.length <= 15) {
                updateFormData((prev) => ({
                    ...prev,
                    [inputName]: inputValue
                }));
            }
        } else {

            updateFormData((prev) => ({
                ...prev,
                [inputName]: inputValue
            }));
        }
    }

    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState("");

    // Handle typing in the input field (with character limit)
    const handleTagInputChange = (e) => {
        const value = e.target.value;
        if (value.length <= 25) {
            setInputValue(value);  // Only update the input if the length is <= 25
        }
    };

    // Handle adding a tag (either pressing Enter or clicking the Add button)
    const handleAddTag = () => {
        if (inputValue.trim() && !tags.includes(inputValue)) {
            updateFormData((prevData) => ({
                ...prevData,
                tagline: [...prevData.tagline, inputValue],  // Append to tagline array
            }));
            setTags([...tags, inputValue]);  // Add the tag to the list
            setInputValue("");  // Clear the input field
        }
    };

    // Handle tag removal
    const handleRemoveTag = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));  // Remove the tag from the list
    };

    // Handle the Enter key press
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            handleAddTag();
        }
    };


    const [employmentTypeArr, updateEmploymentTypeArr] = useState([]),
        [imagesDataArr, updateImagesDataArr] = useState([]),
        [workauthorizationArr, updateWorkauthorizationArr] = useState([]),
        [myCat, setmyCat] = useState(''),
        [langaugeArr, updateLangaugeArr] = useState([]);

    // checking option other is selected for prefier langauge or not
    const isOtherOptionSelectedLang = useMemo(() => langaugeArr.includes("Other"), [langaugeArr]);

    useEffect(() => {
        console.log("langaugeArr", langaugeArr);
    }, [langaugeArr])




    const handleChangeCheckBox = (e) => {

        // Destructuring
        const { value, checked } = e.target;

        // Case 1 : The user checks the box
        if (checked && e.target.name === `employment_type`) {
            // console.log(value)
            if (value === `Please contact`) {
                updateEmploymentTypeArr([value]);
            } else {
                const filteredData = employmentTypeArr.filter((data) => data !== `Please contact`)
                updateEmploymentTypeArr([...filteredData, value]);
            }

        } else {
            updateEmploymentTypeArr(employmentTypeArr.filter((e) => e !== value));
        }

        // if (checked && e.target.name === `work_authorization`) {
        //     // console.log(value)

        //     updateWorkauthorizationArr([...workauthorizationArr, value]);


        // }
        // // Case 2  : The user unchecks the box
        // else {
        //     updateWorkauthorizationArr(workauthorizationArr.filter((e) => e !== value));
        // }

        if (e.target.name === 'work_authorization') {
            if (checked) {
                updateWorkauthorizationArr([...workauthorizationArr, value]);
            } else {
                updateWorkauthorizationArr(workauthorizationArr.filter((data) => data !== value));
            }
        }




        // Case 1 : The user checks the box
        // if (checked && e.target.name === `language`) {
        //     // console.log(value)
        //     if (value === `Other`) {
        //         updateLangaugeArr([...langaugeArr, value]);
        //     } else {
        //         const filteredData = langaugeArr.filter((data) => data !== value)
        //         updateLangaugeArr([...filteredData, value]);
        //     }

        // }

        if (checked && e.target.name === `language`) {
            if (value === 'Other') {
                console.log(value, "getting the other value");
                updateLangaugeArr([...langaugeArr, value]);

            }
            else {
                updateLangaugeArr([...langaugeArr, value]);
            }

        }
        // Case 2  : The user unchecks the box
        else {
            updateLangaugeArr(langaugeArr.filter((e) => e !== value));
        }



    };


    const handleFormSubmit = async (e) => {
        e.preventDefault();

        localStorage.removeItem("start_date");
        localStorage.removeItem("end_date");

        if (steps === 4) return toggleModalVisibilty("jobs", false);

        toast.dismiss();
        const missingData = [],
            invalidData = [];
        // updateSteps(steps + 1);
        let isDataIncomplete = true;

        if (steps === 0) {
            if (!(title || type || categories || descriptions || experience)) return toast.error(`Please provide all data`);
            if (!isTruthyString(categories)) missingData.push(`Category`);
            if (!isTruthyString(type)) missingData.push(`Type`);
            if (!isTruthyString(title)) missingData.push(`Title`);
            // if (experience && !isTruthyString(experience)) missingData.push(`Experience`);
            // if (selectedSkill && selectedSkill.length === 0) missingData.push(`Role/Skill`);



            if (!isTruthyString(descriptions)) missingData.push(`description`);
            else if (isDescriptionHas5Words(descriptions)) {
                return toast.error("You must write at least 5 words in your description");
            }

            experienceLists.some(
                (experience, i) => {

                    if (!experience.company) {
                        isDataIncomplete = false
                        return toast.error(`Company missing for experience ${i + 1}`);
                    }
                    else if (!experience.title) {
                        isDataIncomplete = false
                        return toast.error(`Title missing for experience ${i + 1}`);
                    }
                    else if (!experience.start_date) {
                        isDataIncomplete = false
                        return toast.error(`Start date missing for experience ${i + 1}`);
                    }
                    else if (!experience.end_date && !experience.is_job_continuous) {
                        isDataIncomplete = false
                        return toast.error(`End date missing for experience ${i + 1}`);
                    }

                }
            );

            // if (isDataIncomplete && experienceLists.length !== 0) missingData.push(`experience`)



        } else if (steps === 1) {

            // if (tagline && !isTruthyString(tagline)) missingData.push(`tagline`);

            if (selectedJobType === "1" && no_of_opening && isNaN(no_of_opening)) invalidData.push(`No of job opening`);

            // if (!isTruthyString(employment_type)) missingData.push(`Employment type`);

            const updatedLangaugeArr = [...langaugeArr];
            if (isOtherOptionSelectedLang) updatedLangaugeArr.push(other_language)
            // if (updatedLangaugeArr && updatedLangaugeArr.length) formDataObj.prefered_language = updatedPreferedLangaugeArr;



            if (preferred_gender && !isTruthyString(preferred_gender)) missingData.push(`preferred gender`);

            if (/^0+$|^0+[1-9][0-9]*$/.test(amount)) {
                return toast.error(`Please enter a valid digit`);
            }

            if (salary && isNaN(Number(salary))) invalidData.push(`salary`);

        } else if (steps === 2) {

            // if (work_authorization && !isTruthyString(work_authorization)) missingData.push(`work authorization`);
            // if (!isTruthyString(location)) missingData.push(`location`);
            if (!isTruthyString(location_name)) missingData.push('location');
            else if (!locationResponseData) {
                return toast.error('Please select a location from the suggestions');
            }



        } else if (steps === 3) {


            if (!(name || email_address || primary_phone_number || secondary_phone_number || primary_phone_number)) return toast.error(`PLlease provide all data`)

            if (!isTruthyString(name)) missingData.push(`Name`);
            if (!email_address) missingData.push(`Email address`);
            else if (email_address && !isValidEmailAddress(email_address)) invalidData.push(`Email address`);

            if (!primary_phone_number) missingData.push(`Primary phone number`);
            else if (primary_phone_number && isNaN(primary_phone_number)) invalidData.push(`Primary phone number`);
            else if (primary_phone_number && (primary_phone_number.length < 10 || primary_phone_number.length > 15)) invalidData.push(`Primary phone number`);

            if (secondary_phone_number && isNaN(secondary_phone_number)) invalidData.push(`Secondary phone number`);
            else if (secondary_phone_number && (secondary_phone_number.length < 10 || secondary_phone_number.length > 15)) invalidData.push(`secondary phone number`);

            if ((secondary_phone_number && secondary_phone_number) && primary_phone_number === secondary_phone_number) return toast.error(`Primary phone number can not be same as secondary phone number`)

        }

        // Show errors if needed
        if (!isDataIncomplete || missingData.length || invalidData.length) {
            if (missingData.length) toast.error(`Missing: ${missingData.join(`, `)}`);
            if (invalidData.length) toast.error(`Invalid: ${invalidData.join(`, `)}`);
            return;
        } else if (steps !== 2 && steps !== 3) {
            updateSteps(steps + 1);
        }

        if (steps == 3 && adsIds && status === "active") {
            toast.success("Ad edit successfully");
            window.location.reload();
        }


        let formDataObj = {
            adsType: adsCategoriesId || adsType,
            salary_info,
            currency,
            experience: [],



            listing_type: selectedJobType === "1"
                ? "Offering - I have a job to offer"
                : selectedJobType === "2"
                    ? "Wanted - I am looking for a job"
                    : "Some default value",
            redirect_uri_success: "menehariya.netscapelabs.com/success",
            hide_phone_number: isHideMobileNumberChecked,
            hide_email_address: isHideEmailAddressChecked
        };

        if (categories) formDataObj.categories = categories;
        if (selectedSkill && selectedSkill.length) formDataObj.role = selectedSkill
        if (type) formDataObj.type = type;
        if (title) formDataObj.title = title;
        if (descriptions) formDataObj.descriptions = descriptions;

        // if()



        // if (experienceLists && experienceLists.length) formDataObj.experience =
        // experienceLists[0].company;

        // const extractExperienceData = (experienceList) => {
        //     return experienceList.map((item) => ({
        //       companyName: item.company || '',
        //       companyTitle: item.title || '',
        //       startDate: item.start_date || '',
        //       endDate: item.end_date || '',
        //       currentlyWorking: item.is_job_continuous || false,
        //     }));
        //   };


        // const extractExperienceData = (experienceList) => {
        //     let data =experienceList.map((item) => ({
        //       companyName: item.company || '',
        //       companyTitle: item.title || '',
        //       startDate: item.start_date || '',
        //       endDate: item.end_date || '',
        //       currentlyWorking: item.is_job_continuous || false,
        //     }));
        //    console.log("data",data)
        //    return data
        //   };

        //   console.log(formDataObj.experience,"ghjkjgh")

        //   // Assuming formDataObj is your form data object
        //   if (experienceLists && experienceLists.length) {
        //     const code = extractExperienceData(experienceLists);
        //     console.log(code, "codecodecodecode");

        //     // Make sure formDataObj.experience is initialized as an array
        //     formDataObj.experience = formDataObj.experience || [];

        //     code.forEach((item) => {
        //       formDataObj.experience.push({
        //         companyName: item.company || '',
        //         companyTitle: item.title || '',
        //         startDate: item.start_date || '',
        //         endDate: item.end_date || '',
        //         currentlyWorking: item.is_job_continuous || false,
        //       });
        //     });
        //   }

        // if (experienceLists && experienceLists.length) {
        //     // Make sure formDataObj.experience is initialized as an array
        //     formDataObj = {
        //       // other form fields
        //       experience: experienceLists.map((item) => ({
        //         companyName: item.company || '',
        //         companyTitle: item.title || '',
        //         startDate: item.start_date || '',
        //         endDate: item.end_date || '',
        //         currentlyWorking: item.is_job_continuous || false,
        //       })),
        //     };
        //   }



        // if (experienceLists && experienceLists.length) {
        //     // Make sure formDataObj.experience is initialized as an array
        //     formDataObj = {
        //         // other form fields
        //         experience: JSON.stringify(
        //             experienceLists.map((item) => ({
        //                 companyName: item.company || '',
        //                 companyTitle: item.title || '',
        //                 startDate: item.start_date || '',
        //                 endDate: item.end_date || '',
        //                 currentlyWorking: item.is_job_continuous || false,
        //             }))
        //         ),
        //         // Include other form fields here
        //     };
        // }

        if (experienceLists && experienceLists.length) {
            // Map and transform the experienceLists data
            const transformedExperience = experienceLists.map((item) => ({
                companyName: item.company || '',
                companyTitle: item.title || '',
                startDate: item.start_date || '',
                endDate: item.end_date || '',
                currentlyWorking: item.is_job_continuous || false,
            }));

            // Convert the transformed data to a JSON-formatted string
            const experienceJsonString = JSON.stringify(transformedExperience);

            // Make sure formDataObj.experience is initialized as an array
            formDataObj.experience = experienceJsonString
        }








        console.log('FormDataObj Experience:', formDataObj.experience);

        //   formDataObj.experience.forEach((experience) => {
        //     console.log('Company Name:', experience.companyName); // Corrected key
        //     console.log('Company Titless:', experience.companyTitle); // Corrected key
        //     console.log('Start Date:', experience.startDate); // Corrected key
        //     console.log('End Date:', experience.endDate); // Corrected key
        //     console.log('Currently Working:', experience.currentlyWorking); // Corrected key
        //     console.log('---');
        //   });                                                                    



        // if (experienceLists && experienceLists.length) {
        //     formDataObj.experience = experienceLists.map((item) => ({
        //         companyName: item.company || '',
        //         companyTitle: item.title || '',
        //         startDate: item.start_date || '',
        //         endDate: item.end_date || '',
        //         currentlyWorking: item.is_job_continuous || false,
        //     }));
        // }        

        // if()

        // if (language) formDataObj.language = language;

        // const updatedLangaugeArr = [...langaugeArr];
        // if (isOtherOptionSelectedLang) updatedLangaugeArr.push(other_language)
        // if (updatedLangaugeArr && updatedLangaugeArr.length) formDataObj.language = updatedLangaugeArr;

        // Other language logic

        const updatedLangaugeArr = [...langaugeArr];
        // Check if the "Other" option is selected
        const isOtherOptionSelectedLang2 = updatedLangaugeArr.includes('Other');

        // Remove "Other" from the array
        const filteredLangaugeArr = updatedLangaugeArr.filter(language => language !== 'Other');

        // If "Other" was selected, add it back to the array
        if (isOtherOptionSelectedLang2) {
            filteredLangaugeArr.push(other_language);
        }

        // Assign the updated array to formDataObj.language
        formDataObj.language = filteredLangaugeArr;


        if (employmentTypeArr && employmentTypeArr.length) formDataObj.employment_type = employmentTypeArr
        if (salary) formDataObj.salary = salary;
        if (amount) formDataObj.amount = amount;
        // if (currency) formDataObj.currency = currency;

        if (tagline) formDataObj.tagline = tagline;
        if (no_of_opening) formDataObj.no_of_opening = no_of_opening;
        if (preferred_gender) formDataObj.preferred_gender = preferred_gender;

        if (job_website_link) formDataObj.job_website_link = job_website_link;
        if (video_link) formData.video_link = video_link;
        if (workauthorizationArr && workauthorizationArr.length) formDataObj.work_authorization = workauthorizationArr;
        // if (langaugeArr && langaugeArr.length) formDataObj.language = langaugeArr.join(', ');
        if (location_name) formDataObj.location_name = location_name;
        if (latitude) formDataObj.latitude = latitude;
        if (longitude) formDataObj.longitude = longitude;


        // lsiter detail;
        if (name) formDataObj.name = name;
        if (email_address) formDataObj.email_address = email_address;
        if (website_link) formDataObj.website_link = website_link;
        // if(hide_phone_number) formDataObj.hide_my_phone = hide_phone_number;
        if (primary_phone_number) formDataObj.primary_phone_number = primary_phone_number;
        if (secondary_phone_number) formDataObj.secondary_phone_number = secondary_phone_number;

        if (isHideMobileNumberChecked) formDataObj.hide_my_phone = isHideMobileNumberChecked;
        if (isHideEmailAddressChecked) formDataObj.hide_my_secondary_phone = isHideEmailAddressChecked;



        const jobFormData = new FormData();

        if ((adsId || adsIds) && status === 'draft') {
            // alert("infirsty")
            steps === 3 && selectedFiles.forEach((file, index) => {
                jobFormData.append('photos', file);
            })
        }
        if (!((adsId || adsIds) && status === 'draft')) {
            // alert("insecond")
            steps === 3 && selectedFiles.forEach((file, index) => {
                jobFormData.append('photos', file);
            })
        }
        if (status === 'active') {
            // alert("inthird")
            steps === 2 && selectedFiles.forEach((file, index) => {
                jobFormData.append('photos', file);
            })
        }


        const data = formDataAppender(formDataObj, jobFormData);


        // const updatedFetchConfig = fetchConfig;
        // delete updatedFetchConfig.headers["Content-Type"];


        for (const pair of data.entries()) {
            console.log(`${pair[0]}, ${pair[1]}`);
        }

        if (steps === 2 && !(jobAdsId || adsId || adsIds)) {
            console.log(`bdhjewvjdqw`)
            toggleLoader(true);

            const updatedFetchConfig = fetchConfig;
            delete updatedFetchConfig.headers['Content-Type'];

            fetch(`${appURL}/posts/jobs/new-job`, {
                ...updatedFetchConfig,
                body: data
            })
                .then(handleFetchErrors)
                .then((res) => {
                    console.log(res)
                    toggleLoader(false);
                    if (res?.status == 200) {
                        const jobsObjectId = res?.postJobAdObjToSend?._id

                        updateSteps(steps + 1);
                        updateJobAdsId(jobsObjectId)


                        localStorage.setItem('adsInfo', JSON.stringify({
                            title: title,
                            id: jobsObjectId,
                            adsType: "jobs",
                            adsCategories: adsCategoriesId || "64e48fa9f9eeb14b6e2135be"
                        }));
                    } else {
                        return toast.error(res?.message);
                    }
                })
                .catch((err) => {
                    toggleLoader(false);
                    console.log(err)
                });
        }
        else if ((steps === 2 || steps === 3 || steps === 1) && (jobAdsId || adsId || adsIds)) {
            console.log(`WORKING`)
            toggleLoader(true);
            // selectedFiles.forEach((file, index) => {
            //     jobFormData.append('photos', file);
            // });


            const updatedFetchConfig = JSON.parse(JSON.stringify(fetchConfig));
            delete updatedFetchConfig.headers['Content-Type'];

            // jobFormData.delete('photos');

            fetch(`${appURL}/posts/jobs/edit/${jobAdsId || adsIds}`, {
                ...updatedFetchConfig,
                method: "PATCH",
                body: data
            })

                .then(handleFetchErrors)
                .then((res) => {
                    console.log(res)
                    console.log(res.updateJobAdObjToSend.advertisement_id, "my_new_id");
                    localStorage.setItem('advertisementId', res.updateJobAdObjToSend.advertisement_id)
                    toggleLoader(false)
                    if (res?.status === 200) {
                        updateSteps(steps + 1);
                        // if(steps==2){
                        //     setadsIds("")
                        // }
                    } else {
                        return toast.error(res?.message);
                    }
                    // console.log(`resresresres`, res)
                })
                .catch((err) => {
                    toggleLoader(false);
                    console.log(err)
                });
        }


    };

    const backBtnClickHandler = () => {
        if (steps === 0) {
            if (adsId || adsIds) {
                toggleModalVisibilty("addJobsVisibilityModal", false);
            } return toggleModalVisibilty("jobs", false);
        }
        updateSteps(steps - 1)
    }


    const hiddenStyle = {
        display: 'none',
    };


    const handleFileChange = (event) => {
        const files = event.target.files;
        setSelectedFiles([...selectedFiles, ...files]);
    };


    // selecting location
    const callBackFunction = (address = '', lat = '', lng = '') => {
        updateFormData({
            ...formData,
            location_name: address,
            latitude: lat,
            longitude: lng,
        });
    }


    const jobTypeRadioBnOption = [
        {
            label: "Local Jobs",
            value: "Local Jobs"
        },
        {
            label: "Remote Jobs",
            value: "Remote Jobs"
        }
    ];

    const [isHideMobileNumberChecked, toggleIsHideMobileNumberChecked] = useState(false),
        [isHideEmailAddressChecked, toggleIsHideEmailAddressChecked] = useState(false),
        [isHideEmailAddress1Checked, toggleIsHideEmailAddress1Checked] = useState(false);


    // lister info
    // const hideEmailNumberSwitchButtonHandler = (e) => {

    //     const inputName = e?.target?.name;
    //     if (inputName === `hide_secondary_phone_number`) toggleIsHideEmailAddressChecked(!isHideEmailAddressChecked)
    //     if (inputName === `hide_primary_phone_number`) toggleIsHideMobileNumberChecked(!isHideMobileNumberChecked)
    //     if (inputName === `hide_my_email_address`) toggleIsHideEmailAddress1Checked(!isHideEmailAddress1Checked)
    //     console.log(inputName)

    // }


    const hideEmailNumberSwitchButtonHandler = (e) => {
        const inputName = e?.target?.name;
        if (inputName === `hide_secondary_phone_number`) {
            toggleIsHideEmailAddressChecked(!isHideEmailAddressChecked);
        }
        if (inputName === `hide_primary_phone_number`) {
            toggleIsHideMobileNumberChecked(!isHideMobileNumberChecked);

            // Log the state to ensure it's being updated correctly
            console.log(inputName, isHideMobileNumberChecked);
        }
        if (inputName === `hide_my_email_address`) {
            toggleIsHideEmailAddress1Checked(!isHideEmailAddress1Checked);
        }
    }



    useEffect(() => {
        const updatedFormData = { ...formData };
        updatedFormData.hide_phone_number = isHideMobileNumberChecked;
        updateFormData(updatedFormData);
    }, [isHideMobileNumberChecked]);


    const onCreateteHandlerForTagline = (value) => {
        if (value && selectedTagline.length <= 4) {
            upatedSelectedTagline([
                ...selectedTagline,
                value
            ])
        }

    }

    const onCreateteHandlerForSkill = (value) => {

        if (value) {
            updateSelectedSkill([
                ...selectedSkill,
                value
            ])
        }

    }


    const onChangeForDate = (date, dateString, id, fieldType) => {

        const index = experienceLists.findIndex(item => item.id === id);

        if (index !== -1) {
            const updatedList = [...experienceLists];

            if (fieldType === 1) {

                console.log(dateString)
                updatedList[index] = {
                    ...updatedList[index],
                    start_date: dateString,
                    end_date: ""

                };
                updateExperienceLists(updatedList);
            } else if (fieldType === 2) {
                updatedList[index] = {
                    ...updatedList[index],

                    end_date: dateString,
                };
                updateExperienceLists(updatedList);

            }

            console.log(updatedList)



        }

    };



    const handleAddRemoveExperienceFields = (action, id) => {
        toast.dismiss();
        let isDataIncompleteForExperince = true
        if (experienceLists?.length !== 0 && action === 1) {
            experienceLists.some(
                (experience, i) => {

                    if (!experience.company) {
                        isDataIncompleteForExperince = false
                        return toast.error(`Company missing for experience`);
                    }
                    else if (!experience.title) {
                        isDataIncompleteForExperince = false
                        return toast.error(`Title missing for experience`);
                    }
                    else if (!experience.start_date) {
                        isDataIncompleteForExperince = false
                        return toast.error(`Start date missing for experience`);
                    }
                    else if (!experience.end_date && !experience.is_job_continuous) {
                        isDataIncompleteForExperince = false
                        return toast.error(`End date missing for experience`);
                    }
                }
            );

            if (!isDataIncompleteForExperince) return
        }

        const updatedList = experienceLists.map(experience => ({
            ...experience,
            is_box_close: true
        }));

        if (action === 1) {
            const newExperience = {
                id: cuid(),
                is_box_close: false,
                company: "",
                title: "",
                start_date: "",
                end_date: "",
                no_of_year: null,
                is_job_continuous: false
            };
            const newExperienceString = JSON.stringify(newExperience);
            updateExperienceLists([...updatedList, newExperience]);
        } else if (action === 2) {
            updateExperienceLists(prevLists => prevLists.filter(item => item.id !== id));
        }
    };



    // const handleExpandingExperience = (id, operationType) => {
    //     toast.dismiss();

    //     const index = experienceLists.findIndex(item => item.id === id);

    //     if (index !== -1) {
    //         const updatedList = [...experienceLists];

    //         if (operationType === 1) {
    //             updatedList[index] = {   
    //                 ...updatedList[index],
    //                 is_box_close: false
    //             };

    //             setHideIcon(!hideIcon);
    //         } else if (operationType === 2) {
    //             const currentList = updatedList[index]
    //             let experienceMissingData = [];


    //             if (!currentList.company) experienceMissingData.push(`company name`);
    //             if (!currentList.title) experienceMissingData.push(`title`);
    //             if (!currentList.start_date) experienceMissingData.push(`start date`);
    //             if ( !currentList.is_job_continuous && !currentList.end_date) experienceMissingData.push(`end date`);


    //             // Show errors if needed

    //             if (experienceMissingData.length) return toast.error(`Missing: ${experienceMissingData.join(`, `)}`);

    //             updatedList[index] = {
    //                 ...updatedList[index],
    //                 is_box_close: true
    //             };

    //         }
    //         setHideIcon(hideIcon);

    //         updateExperienceLists(updatedList);

    //     }

    // }


    // const handleExpandingExperience = (id, operationType) => {
    //     toast.dismiss();

    //     const index = experienceLists.findIndex((item) => item.id === id);

    //     if (index !== -1) {
    //       const updatedList = [...experienceLists];

    //       if (operationType === 1) {
    //         updatedList[index] = {
    //           ...updatedList[index],
    //           is_box_close: false
    //         };

    //         setHideIcon(false); // Show the icon when the box is open
    //       } else if (operationType === 2) {
    //         const currentList = updatedList[index];
    //         let experienceMissingData = [];

    //         if (!currentList.company) experienceMissingData.push(`company name`);
    //         if (!currentList.title) experienceMissingData.push(`title`);
    //         if (!currentList.start_date) experienceMissingData.push(`start date`);
    //         if (!currentList.is_job_continuous && !currentList.end_date) experienceMissingData.push(`end date`);

    //         // Show errors if needed
    //         if (experienceMissingData.length) {
    //           toast.error(`Missing: ${experienceMissingData.join(`, `)}`);
    //           return;
    //         }

    //         updatedList[index] = {
    //           ...updatedList[index],
    //           is_box_close: true
    //         };
    //         setHideIcon(true); // Hide the icon when the box is closed
    //       }

    //       updateExperienceLists(updatedList);
    //     }
    //   };



    const handleExpandingExperience = (id, operationType) => {
        toast.dismiss();

        const index = experienceLists.findIndex((item) => item.id === id);

        if (index !== -1) {
            const updatedList = [...experienceLists];

            if (operationType === 1) {
                updatedList[index] = {
                    ...updatedList[index],
                    is_box_close: false
                };

                setHideIcon(false); // Show the icon when the box is open
            } else if (operationType === 2) {
                const currentList = updatedList[index];
                let experienceMissingData = [];

                if (!currentList.company) experienceMissingData.push(`company name`);
                if (!currentList.title) experienceMissingData.push(`title`);
                if (!currentList.start_date) experienceMissingData.push(`start date`);
                if (!currentList.is_job_continuous && !currentList.end_date) experienceMissingData.push(`end date`);

                // Show errors if needed
                if (experienceMissingData.length) {
                    toast.error(`Missing: ${experienceMissingData.join(`, `)}`);
                    return;
                }

                updatedList[index] = {
                    ...updatedList[index],
                    is_box_close: true
                };

                // Hide the icon when the box is closed
                setHideIcon(experienceLists.length === 1 || experienceLists.length === 2);
            }

            updateExperienceLists(updatedList);
        }
    };




    const goToTop = (id, operationType) => {
        toast.dismiss();
        const index = experienceLists.findIndex(item => item.id === id);

        if (index !== -1) {
            const updatedList = [...experienceLists];

            if (operationType === 1) {
                updatedList[index] = {
                    ...updatedList[index],
                    is_box_close: false
                };
                // setHideIcon(false); // Set isBoxOpen to true when the box is open
            } else if (operationType === 2) {
                const currentList = updatedList[index];
                let experienceMissingData = [];

                updatedList[index] = {
                    ...updatedList[index],
                    is_box_close: true
                };
                // setHideIcon(true); // Set isBoxOpen to false when the box is closed
            }

            updateExperienceLists(updatedList);

            const openExperiences = updatedList.filter(item => !item.is_box_close);

            const hideIcon = openExperiences.length === 0;

            // Check if the current experience being updated is open or closed
            if (hideIcon && operationType === 2) {
                setHideIcon(true); // Hide the icon when the box is closed and there are no open experiences
            }
        }
    };


    //   const handleRefreshClick = ()=>{
    //     if(steps ==3 && adsIds){
    //         window.location.reload();
    //     }
    //   }



    const handleCheckPresentJobDuration = (e, id) => {

        const index = experienceLists.findIndex(item => item.id === id);

        if (index !== -1) {

            const updatedList = [...experienceLists];

            updatedList[index] = {
                ...updatedList[index],
                is_job_continuous: !updatedList[index].is_job_continuous,
                end_date: ""
            };

            updateExperienceLists(updatedList);
        }
    }


    const handleChange1 = (e, id, fieldType) => {


        const index = experienceLists.findIndex(item => item.id === id),
            inputValue = e?.target.value;

        if (index !== -1) {
            const updatedList = [...experienceLists];

            if (fieldType === 1) {
                updatedList[index] = {
                    ...updatedList[index],
                    company: inputValue,
                    // platform_link: fieldType === 2 ? e.target.value :`` ,
                };
            } else if (fieldType === 2) {
                updatedList[index] = {
                    ...updatedList[index],

                    title: inputValue,
                };
            } else if (fieldType === 3) {
                updatedList[index] = {
                    ...updatedList[index],
                    no_of_year: inputValue,
                };
            }

            updateExperienceLists(updatedList);
        }
    };


    const handlingClearSelectInput = (name) => {

        console.log(name)

        switch (name) {
            case "preferred_gender":
                updateFormData({
                    ...formData,
                    preferred_gender: ``
                })
                break;



            default:
                break;
        }

    }




    let HeadingMessage = `Post your Ad`;
    let SubHeadingMessage = ``;

    if (steps === 4) {
        HeadingMessage = `Buy promotions`
    } else if (steps === 5) {
        SubHeadingMessage = ``
    }
    else if (steps === 3) {
        SubHeadingMessage = `  Basic Information`
    }


    const formatingDateInExperience = (startDate, endDate) => {

        const diff = new Date(endDate) - new Date(startDate);

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const years = Math.floor(days / 365);
        const months = Math.floor((days - (years * 365)) / 30);
        const weeks = Math.floor((days - (years * 365 + months * 30)) / 7);
        const remainingDays = days - (years * 365 + months * 30 + weeks * 7);
        return `${years !== 0 ? "Years" + " " + years : ``} ${months !== 0 ? "Months" + " " + months : ``} ${weeks !== 0 ? "Weeks" + " " + weeks : ``} `

    }

    // ${days !==0 ? "days" + remainingDays : ``
    console.log(jobAdsId)

    // const disbaleDate = (current = " ", startDate = " ", dateType = null) => {
    //     if (dateType === 1) {
    //         return current && current.isAfter(moment().startOf('day'));
    //     } else if (dateType === 2 )  {
    //         return current && current.isBefore(startDate, 'day') || current && current.isAfter(moment().startOf('day'));
    //     }

    // };


    const disbaleDate = (current, startDate, dateType) => {
        if (dateType === 1) {

            return current && current.isAfter(moment().startOf('day'));
        } else if (dateType === 2 && startDate) {

            return current && (current.isBefore(moment(startDate).startOf('day')) || current.isAfter(moment().startOf('day')));
        }
    };

    const local_my_id = localStorage.getItem("new_my_id");


    const fetchingAdsById = () => {
        const actualAdsId = adsId || adsIds;

        fetch(`${appURL}/posts/jobs/ad_details?adsId=${actualAdsId}`, {
            ...fetchConfig,
            method: "GET"
        })
            .then(handleFetchErrors)
            .then((res) => {
                console.log(`resredres`, res);

                if (res?.status == 200) {

                    const data = res?.ads_details,
                        adsInfo = data?.adsInfo,
                        listerInfo = data?.lister_basic_info;
                    console.log("cat name", data?.adsInfo?.categories?.name);
                    setmyadsId(res?.ads_details?._id);
                    setmyadsType(res?.ads_details?.adsType);
                    setSelectedFiles(res?.ads_details?.adsInfo?.image);
                    setmyCat(data?.adsInfo?.categories?.name);
                    updateEmploymentTypeArr(adsInfo?.employment_type)
                    updateWorkauthorizationArr(adsInfo?.work_authorization)
                    updateLangaugeArr(adsInfo?.language)
                    updateSelectedSkill(adsInfo?.role)
                    setTags(adsInfo?.tagline)

                    const dataObj = {
                        ...formData,
                        title: adsInfo?.title,
                        descriptions: adsInfo?.descriptions,
                        experience: adsInfo?.experience,
                        // location_name: adsInfo?.location_name,
                        selectedSkill: adsInfo?.role,
                        type: adsInfo?.type,
                        categories: adsInfo?.categories._id,
                        currency: adsInfo?.salary?.currency,
                        amount: adsInfo?.salary?.amount,
                        salary_info: adsInfo?.salary_info,
                        preferred_gender: adsInfo?.preferred_gender,
                        location_name: adsInfo?.location?.location_name,
                        latitude: adsInfo?.location?.coordinates[1],
                        longitude: adsInfo?.location?.coordinates[0],
                        work_authorization: adsInfo?.work_authorization,
                        language: adsInfo?.language,
                        // listerInfo
                        name: listerInfo?.name !== "" ? listerInfo?.name : JSON.parse(localStorage.getItem("user"))?.name,
                        primary_phone_number: listerInfo?.primary_mobile_number?.primary_phone_number !== "" ? listerInfo?.primary_mobile_number?.primary_phone_number : JSON.parse(localStorage.getItem("user"))?.phone_number,
                        secondary_phone_number: listerInfo?.secondary_phone_number,
                        hide_my_email: listerInfo?.hide_my_email,
                        hide_my_phone: listerInfo?.hide_my_phone,
                        hide_secondary_phone_number: listerInfo?.hide_my_secondary_phone || false,
                        hide_primary_phone_number: listerInfo?.hide_my_phone || false,
                        adsType: data?.adsType,
                        status: data.status,
                    }


                    updateFormData(dataObj)

                }
            })
            .catch((err) => {

                console.log(err)
            });
    }





    const backToclose = (id, operationType) => {

        const index = experienceLists.findIndex(item => item.id === id);

        if (index !== -1) {
            const updatedList = [...experienceLists];

            if (operationType === 1) {
                updatedList[index] = {
                    ...updatedList[index],
                    is_box_close: false
                };

            } else if (operationType === 2) {
                const currentList = updatedList[index]
                let experienceMissingData = [];








                updatedList[index] = {
                    ...updatedList[index],
                    is_box_close: true
                };
            }

            updateExperienceLists(updatedList);
        }
    }









    const angleDownClass = "fa-solid fa-angle-down hidden";
    const displayStyle = experience?.is_box_close && hideIcon ? 'hidden' : '';

    const combinedClasses = `${angleDownClass} ${displayStyle}`;



    useEffect(() => {
        fetchingDynamicsData();
        if (adsId || adsIds) fetchingAdsById();
    }, []);


    const abcfunction = (data) => {
        setlat(data.lat)
        setlng(data.lng)

        console.log(data.lat, data.lng, "Nothing")
    }

    return (
        <main className={style.main_wrapper}>

            <PostAdsHeading headingText={status === 'draft' ? 'Post Your Ad' : HeadingMessage} subHeadingText={SubHeadingMessage} />

            <form id={style.room_rents_form} >

                {
                    (() => {


                        if (steps === 0) {

                            return <>
                                {!(status !== "draft" && (adsIds ? true : false)) ?
                                    <Inputs
                                        label='Category'
                                        type='select'
                                        required={true}
                                        id='span-2'
                                        name='categories'
                                        value={categories}
                                        disabled={status !== "draft" && (adsIds ? true : false)}
                                        placeholder='Select category'
                                        onChangeHandler={onChangeHandlerForForm}
                                        selectInputOptions={jobsDynamicData?.categories}
                                    />
                                    : <Inputs
                                        label='Category'
                                        required={true}
                                        type='text'
                                        id='span-2'
                                        name='title'
                                        value={myCat}
                                        disabled={status !== "draft" && (adsIds ? true : false)}
                                        placeholder='Enter Category'
                                    />
                                }

                                <Inputs
                                    label='Job type'
                                    required={true}
                                    id='span-2'
                                    type='radio'
                                    name='type'
                                    value={type}
                                    placeholder='Select type'
                                    disabled={status !== "draft" && (adsIds ? true : false)}
                                    onChangeHandler={onChangeHandlerForForm}
                                    radioBtnOtions={jobTypeRadioBnOption}
                                />

                                <Inputs
                                    label='Job title'
                                    required={true}
                                    type='text'
                                    id='span-2'
                                    name='title'
                                    value={title}
                                    onChangeHandler={onChangeHandlerForForm}
                                    placeholder='Enter job title'
                                />

                                {
                                    selectedJobType === "1" ?
                                        // <Inputs
                                        //     label='Experience'
                                        //     type='text'
                                        //     id='span-2'
                                        //     name='experience'
                                        //     value={experience}
                                        //     onChangeHandler={onChangeHandlerForForm}
                                        //     placeholder='Enter experience'

                                        // /> 

                                        null

                                        :

                                        <section id='span-2'>

                                            <div className={style.label_addbtn_section}>
                                                <span id='label'>Experiences<span className='optional_text'>(Optional)</span></span>
                                                <p onClick={() => { handleAddRemoveExperienceFields(1, null) }} className={style.add_btn_text}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg></p>
                                            </div>



                                            {experienceLists?.length !== 0 &&
                                                experienceLists.map((experience, index) => (

                                                    <div className={style.experience_section_wrapper} id={experience?.is_box_close ? style.closed_experience_section : ``} key={index}>
                                                        <div className={style.delete_edit_setion}>

                                                            <span className='label'>Experience {index + 1}</span>
                                                            <div className={style.delete_edit_icon_wrapper}>
                                                                {experience?.is_box_close &&
                                                                    <span onClick={() => handleExpandingExperience(experience?.id, 1)}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FD7E14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polygon points="16 3 21 8 8 21 3 21 3 16 16 3"></polygon></svg></span>
                                                                }



                                                                {/* <i onClick={() => goToTop(experience?.id, 2)} className={`${style.delete_edit_icon_wrapper}`} style={isSaveClicked ? {} : hiddenStyle}></i> */}


                                                                {/* <i  onClick={() => goToTop(experience?.id, 2)} class="fa-solid fa-angle-down"></i> */}



                                                                {/* <i
  id="myIcon"
  onClick={() => goToTop(experience?.id, 2)}
  className={combinedClasses}
  style={{ display: hideIcon ? 'none' : 'block' }}
></i> */}


                                                                <i
                                                                    id="myIcon"
                                                                    onClick={() => goToTop(experience?.id, 2)}
                                                                    className={combinedClasses}
                                                                    style={{
                                                                        display: hideIcon && experienceLists.length !== 0 ? 'none' : 'block'
                                                                    }}
                                                                ></i>

                                                                {/* <i
                                                                id="myIcon"
                                                                onClick={() => goToTop(experience?.id, 2)}
                                                                className={combinedClasses} style={{ display: experience?.is_box_close && hideIcon ? 'none' : 'block' }}                                       ></i> */}

                                                                {/* {{ display: experience?.is_box_close && hideIcon ? 'none' : 'block' }} */}


                                                                <span onClick={() => { handleAddRemoveExperienceFields(2, experience?.id) }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#FD7E14" strokeWidth="2" strokeLinecap="square" strokeLinejoin="bevel"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></span>


                                                            </div>
                                                        </div>

                                                        {
                                                            !experience?.is_box_close && <section className={style.experience_input_field_wrapper} >

                                                                <input
                                                                    name={`company_${experience?.id}`}
                                                                    value={experience?.company}
                                                                    onChange={(e) => { handleChange1(e, experience?.id, 1) }}
                                                                    type="text"
                                                                    placeholder='Enter company'
                                                                />

                                                                <input
                                                                    name={experience?.id}
                                                                    value={experience?.title}
                                                                    onChange={(e) => { handleChange1(e, experience?.id, 2) }}
                                                                    type="text"
                                                                    placeholder='Enter title'
                                                                />

                                                                <div >


                                                                    <section className={style.experience_date_input_wrapper}>


                                                                        <Space direction="vertical">

                                                                            <DatePicker
                                                                                defaultValue={experience?.start_date ? dayjs(experience?.start_date, dateFormat) : ''}
                                                                                disabledDate={(current) => disbaleDate(current, experience?.start_date, 1)}
                                                                                className={style.date_picker_wrapper}
                                                                                placeholder="Start date"
                                                                                onChange={(date, dateString) => onChangeForDate(date, dateString, experience?.id, 1)}
                                                                                format={dateFormat}
                                                                                value={experience?.start_date ? dayjs(experience?.start_date, dateFormat) : ''}
                                                                            />
                                                                        </Space>



                                                                        {
                                                                            !experience?.is_job_continuous &&
                                                                            <Space direction="vertical">

                                                                                {/* <DatePicker
                                                                                    defaultValue={experience?.end_date ? dayjs(experience?.end_date, dateFormat) : ''}
                                                                                    disabledDate={(current) => disbaleDate(current, experience?.start_date, 2)}
                                                                                    className={style.date_picker_wrapper}
                                                                                    placeholder="End date"
                                                                                    onChange={(date, dateString) => onChangeForDate(date, dateString, experience?.id, 2)}
                                                                                    format={dateFormat}
                                                                                    value={experience?.end_date ? dayjs(experience?.end_date, dateFormat) : ''}
                                                                                /> */}

                                                                                <DatePicker
                                                                                    defaultValue={experience?.end_date ? dayjs(experience?.end_date, dateFormat) : ''}
                                                                                    disabledDate={(current) => disbaleDate(current, experience?.start_date, 2)}
                                                                                    className={style.date_picker_wrapper}
                                                                                    placeholder="End date"
                                                                                    onChange={(date, dateString) => onChangeForDate(date, dateString, experience?.id, 2)}
                                                                                    format={dateFormat}
                                                                                    value={experience?.end_date ? dayjs(experience?.end_date, dateFormat) : ''}
                                                                                />

                                                                            </Space>


                                                                        }
                                                                        <div>
                                                                            <input onChange={(e) => handleCheckPresentJobDuration(e, experience?.id,)} checked={experience?.is_job_continuous} type="checkbox" id={`job_duration_${experience?.id}`}
                                                                                name={`job_duration_${experience?.id}`} />
                                                                            <label htmlFor={`job_duration_${experience?.id}`}>I am currently working</label>
                                                                        </div>

                                                                    </section>

                                                                    {/* <p className={style.experience_count}>{experience?.end_date && experience?.start_date ? formatingDateInExperience(experience?.start_date, experience?.end_date) : ``}</p> */}

                                                                    <span onClick={() => handleExpandingExperience(experience?.id, 2)} className='btn3'>Save</span>
                                                                </div>

                                                            </section>
                                                        }

                                                    </div>
                                                ))}

                                        </section>
                                }


<section id="span-2">
                    <div>
                      <label className="tagline-main">Tagline (optional)</label>
                      <div>
                        <input
                          type="text"
                          name="tagline"
                          value={inputValue}
                          onChange={handleTagInputChange}
                          onKeyPress={handleKeyPress}
                          placeholder="Enter tagline"
                        />
                        <button onClick={handleAddTag} style={{ display: "none" }} >Add Tag</button>
                      </div>

                      {/* Display tags */}
                      <div>
                        {tags.length  > 0 && (
                          <div>
                            {tags.map((tag, index) => (
                              <span key={index} style={{ margin: "5px", padding: "5px", border: "1px solid #ccc", display: "inline-block" }}>
                                {tag}
                                <button
                                  onClick={() => handleRemoveTag(tag)}
                                  style={{ marginLeft: "5px", cursor: "pointer", background: "red", color: "white", border: "none", borderRadius: "50%", width: "20px", height: "20px" }}
                                >
                                  ×
                                </button>
                              </span>
                            ))}
                          </div>
                        )}
                      </div>

                      {/* Display message if the limit is exceeded */}
                      {inputValue.length > 25 && <p style={{ color: 'red' }}>Tag must be less than 25 characters</p>}
                    </div>

                  </section>

                  
                                {/* <section id='span-2'>
                                    <MultiSelect
                                        required={false}
                                        label='Job role / Skills'
                                        id='skill'
                                        value={selectedSkill}
                                        // allowCreate={selectedSkill.length <= 4 ? true : false}
                                        onCreate={onCreateteHandlerForSkill}
                                        name='tagline'
                                        data={["2-4 Years", "4-5 Years"]}
                                        dataKey="short_id"
                                        textField="name_email"
                                        placeholder="Enter your skills"
                                        onChange={(admin) => updateSelectedSkill(admin)}
                                    />
                                </section> */}


                                <RichTextEditor value={descriptions} updateFormData={updateFormData} formData={formData} label="Description" required={true} />

                            </>
                        } else if (steps === 1) {

                            return <>


                                {/* <section id='span-2'>
                                    <MultiSelect
                                        label='Tagline'
                                        id='tagline'
                                        value={selectedTagline}
                                        // allowCreate={selectedTagline.length <= 2 ? true : false}
                                        onCreate={onCreateteHandlerForTagline}
                                        name='tagline'
                                        data={jobsDynamicData?.tagline}
                                        dataKey="keywords"
                                        textField="keywords"
                                        placeholder="Enter tagline"
                                        onChange={(admin) => upatedSelectedTagline(admin)}
                                    />
                                </section> */}

                                <Inputs
                                    label={selectedJobType == "1" ? 'Gender preferences' : `Gender`}
                                    type='select2'
                                    id='span-2'
                                    name='preferred_gender'
                                    handlingClearSelectInput={handlingClearSelectInput}
                                    value={preferred_gender}
                                    onChangeHandler={onChangeHandlerForForm}
                                    placeholder={selectedJobType == "1" ? 'Gender preferences' : `Gender`}
                                    selectInputOptions={jobsDynamicData?.preferred_gender}
                                />


                                {
                                    selectedJobType === "1" &&

                                    <Inputs
                                        label='No. of job openings'
                                        type='number'
                                        required={false}
                                        id='span-2'
                                        name='no_of_opening'
                                        value={no_of_opening}
                                        onChangeHandler={onChangeHandlerForForm}
                                        placeholder='Enter number of opening'
                                    />
                                }


                                <Inputs
                                    name='language'
                                    selectOptionArrForCheckBox={langaugeArr}
                                    onChangeHandler={handleChangeCheckBox}
                                    label='Language'
                                    type='checkbox'
                                    checkboxInputOtions={jobsDynamicData?.language}
                                    value={language}
                                />



                                <Inputs
                                    name='employment_type'
                                    selectOptionArrForCheckBox={employmentTypeArr}
                                    onChangeHandler={handleChangeCheckBox}
                                    label='Employment type'
                                    type='checkbox'

                                    checkboxInputOtions={jobsDynamicData?.employment_type}
                                />


                                {
                                    isOtherOptionSelectedLang &&
                                    <Inputs
                                        id='span-2'
                                        name='other_language'
                                        value={other_language}
                                        // onChangeHandler={onChangeHandlerForForm}
                                        onChangeHandler={(e) => {
                                            const inputValue = e.target.value;
                                            // Limit the input to 15 characters
                                            if (inputValue.length <= 15) {
                                                onChangeHandlerForForm(e);
                                            }
                                        }}
                                        placeholder='Enter other language'
                                        label='Other language'
                                        required={true}
                                        type='text'
                                    />
                                }



                                <div id='span-2'>
                                    <span id='label'> {Number(selectedJobType) === 2 ? ` Expected Salary` : `Salary`} <span className='optional_text'>(Optional)</span></span>


                                    <div className={style.expected_salary_input_wrapper}>
                                        <section>
                                            <Inputs
                                                inputId={style.currency_input_field}
                                                type='select'
                                                name='currency'
                                                value={currency}
                                                onChangeHandler={onChangeHandlerForForm}
                                                selectInputOptions={jobsDynamicData?.currency}
                                            />
                                        </section>
                                        <section>
                                            <Inputs
                                                inputClassName={style.salary_input}
                                                inputId={style.salary_input}
                                                type='number'
                                                id='span-2'
                                                name='amount'
                                                value={amount}
                                                onChangeHandler={onChangeHandlerForForm}
                                                placeholder='Enter salary'
                                            />

                                            <div style={{ color: 'red', fontSize: "12px", marginTop: "10px" }}>{errorMessage}</div>
                                        </section>

                                        <section>
                                            <Inputs
                                                inputClassName={style.salary_input}
                                                inputId={style.expected_salary_input_rate}
                                                type='select'
                                                name='salary_info'
                                                value={salary_info}
                                                onChangeHandler={onChangeHandlerForForm}
                                                selectInputOptions={jobsDynamicData?.salary_info}
                                            />
                                        </section>

                                    </div>


                                </div>





                            </>
                        } else if (steps === 2) {
                            return <>


                                <Inputs
                                    name='work_authorization'
                                    selectOptionArrForCheckBox={workauthorizationArr}
                                    onChangeHandler={handleChangeCheckBox}
                                    label='Work authorization'
                                    type='checkbox'
                                    checkedOptions={work_authorization}
                                    // id='span-2'
                                    checkboxInputOtions={jobsDynamicData?.work_authorization}
                                />


                                <SelectLocation
                                    callBackFunction={callBackFunction}
                                    location={location_name}
                                    latitude={latitude}
                                    longitude={longitude}
                                    // abc={abcfunction} 
                                    // location={location}
                                    locationResponseData={locationResponseData}
                                    setLocationResponseData={setLocationResponseData}
                                    required={true} />



                                {/* <input type="file" name='photos' onChange={handleFileChange} multiple/> */}

                                <ImageUpload images={images} updateImage={updateImage} handleFileChange={handleFileChange} setSelectedFiles={setSelectedFiles}
                                    selectedFiles={selectedFiles} myadsId={myadsId} myadsType={myadsType} />
                            </>
                        } else if (steps === 3) {

                            return <AdsListerInfo
                                onChangeHandler={onChangeHandlerForForm}
                                formData={formData}
                                isHideMobileNumberChecked={isHideMobileNumberChecked}
                                isHideEmailAddressChecked={isHideEmailAddressChecked}
                                isHideEmailAddress1Checked={isHideEmailAddress1Checked}
                                hideEmailNumberSwitchButtonHandler={hideEmailNumberSwitchButtonHandler}
                            />


                        }
                        else if (steps === 4 && !adsIds) {
                            return <AdsPlansUpdated updateSteps={updateSteps}
                                steps={steps} adsId={jobAdsId} adsCategoriesId={adsCategoriesId} />
                        }

                        else if (steps === 4 && adsIds && status != 'active') {
                            return <AdsPlansUpdated
                                updateSteps={updateSteps}
                                steps={steps}
                                adsId={jobAdsId}
                                adsCategoriesId={adsCategoriesId || adsType}
                                adsIds={adsIds}

                            />
                        }

                        else if (steps === 5) {
                            return <CongratulationsScreen />
                        }

                    })()

                }

                {
                    (steps !== 5 && steps !== 4) &&
                    <FormButton
                        cancelButtonText="Back"
                        submitBtnText={(steps === 3 && status === 'active') ? "Update My Ad" :
                            (steps === 4 && status === 'draft') ? "Post My Ad" :
                                "Next"}
                        id="span-2"
                        className={style.form_button_section}
                        backBtnClickHandler={backBtnClickHandler}
                        onClickHandler={handleFormSubmit}
                        showLoadder={showLoader}
                        status={status}
                        steps={steps}
                    />
                }

            </form>

        </main>
    )
}
