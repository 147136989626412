import React, { useState } from 'react';

const PagePreview = () => {
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');

  const fetchPageData = async () => {
    try {
      // Make the request to your proxy server with the entered URL
      const response = await fetch(`/proxy?url=${encodeURIComponent(url)}`);
      const html = await response.text();

      // Parse the HTML using DOMParser
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');

      // Extract metadata: title, description, and image
      const pageTitle = doc.querySelector('title')?.innerText || 'No Title Found';
      const pageDescription = doc.querySelector('meta[name="description"]')?.getAttribute('content') || 'No Description Found';
      const pageImage = doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || 'No Image Found';

      // Update state to show the results
      setTitle(pageTitle);
      setDescription(pageDescription);
      setImage(pageImage);

    } catch (error) {
      console.error('Error fetching page data:', error);
    }
  };

  return (
    <div>
      {/* <input
        type="text"
        placeholder="Enter URL"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        style={{ width: '80%', padding: '8px' }}
      />
      <button onClick={fetchPageData} style={{ padding: '8px 16px', marginLeft: '10px' }}>
        Fetch Page Details
      </button> */}

      {/* {title && (
        <div>
          <h2>{title}</h2>
          <p>{description}</p>
          {image && <img src={image} alt="Preview" style={{ width: '200px' }} />}
        </div>
      )} */}
    </div>
  );
};

export default PagePreview;
