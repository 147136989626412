import React, { useMemo } from 'react';
import { useRef, useState,useEffect } from 'react';
import style from './inputs.module.css'
import { blockInvalidChar } from '../../utils/block';
import Tooltip from '../tools-tip/Tooltip';
import { useLocation } from 'react-router-dom';

export default function Inputs({

    type = ``,
    placeholder = ``,
    label = ``,
    className = ``,
    inputClassName = "",
    inputId = "",
    tooltip = false,
    tooltipField = "",
    toolTipText = "",
    id = ``,
    required = false,
    onChangeHandler = () => { },
    onBlurHandler = () => { },
    name = ``,
    value = ``,
    selectInputOptions = [],
    rows = 4,
    cols = 10,
    radioBtnOtions = [],
    checkboxInputOtions = [],
    checkedOptions,
    selectOptionArrForCheckBox = [],
    min = 1,
    max = 100,
    disabled = false,
    switchBtnChecked = false,
    optional = false,
    adsIds,

    handlingClearSelectInput


}) {

    const initialCheckedItems = useMemo(() => {
        const items = {};
        checkboxInputOtions.forEach((option) => {
          items[option] = selectOptionArrForCheckBox.includes(option);
        });
        return items;
      }, [checkboxInputOtions, selectOptionArrForCheckBox]);

      const [checkedItems, setCheckedItems] = useState(initialCheckedItems);

      useEffect(() => {
        setCheckedItems(initialCheckedItems);
      }, [initialCheckedItems]);
    

    
      const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        setCheckedItems(prevState => ({
          ...prevState,
          [id]: checked
        }));
        onChangeHandler(event);
      };

    const location =useLocation()

    const handleKeyPress = (event) => {
        if (event.key >= "0" && event.key <= "9") {
            event.preventDefault();
        }
    };

    const inputRef = useRef(null);

    const preventingScrolling = (event) => {
        document.activeElement.blur()
    };


    const [passwordInputTypeForNewPassword, updatePasswordInputTypeForNewPassword] = useState(`password`),
        [passwordInputTypeForConfirmPassword, updatePasswordInputTypeForConfirmPassword] = useState(`password`);


        const passwordInputChangeHandler = (inputTpe, source) => {

            if (source === 1) updatePasswordInputTypeForNewPassword(inputTpe);
            else if (source === 2) updatePasswordInputTypeForConfirmPassword(inputTpe);
    
        }


    

    return (        
        <section id={id ? id : ``} className={`${style.input_Wrapper} ${className}`}>
            {

                label ? <label htmlFor="">{label} {required ? <span className={style.required_field}> {type !== "switch" && "*"}</span> : <span className='optional_text'>(Optional)</span>}</label> : ``
            }

            {
                (() => {
                    if (type === `text` || (type === `email`) || (type === `password`)) {
                        return (
                            <input className={inputClassName} id={inputId} disabled={disabled} maxLength={max} minLength={min} type={type} placeholder={placeholder} onChange={onChangeHandler} onBlur={onBlurHandler} name={name} value={value} required={required} />
                        )
                    } if (type === `number`) {
                        return (
                            <input onWheel={preventingScrolling} onKeyDown={blockInvalidChar} className={inputClassName} id={inputId} disabled={disabled} maxLength={max} minLength={min} type={type} placeholder={placeholder} onChange={onChangeHandler} name={name} value={value} required={required} />
                        )
                    } if (type === `switch`) {
                        return (
                            <div className='toggle-switch-btn'>
                                <input checked={switchBtnChecked} name={name} onChange={onChangeHandler} type="checkbox" id={name} /><label htmlFor={name}>Toggle</label>
                            </div>
                        )
                    } if (type === `switch2`) {
                        return (
                            <div>
                                <div className="can-toggle">
                                    <input name={name} onChange={onChangeHandler} id={className} value="1" type="checkbox" />
                                    <label for={className}>
                                        <div className="can-toggle__switch" onChange={onChangeHandler} data-checked="Yes" data-unchecked="No"></div>
                                    </label>
                                </div>

                            </div>
                        )
                    } else if (type === `textarea`) {
                        return (
                            <textarea onChange={onChangeHandler} name={name} value={value} id={name} rows="5" placeholder={placeholder}>
                                {value}
                            </textarea>

                        )
                    } else if (type === "checkbox") {
                        return (
                            <section>
   {checkboxInputOtions.map((checkboxInputOption, i) => {
        const isChecked = checkedItems[checkboxInputOption];
        return (
          <div className={style.checkbox_input_wrapper} key={i}>
            <input
              checked={isChecked}
              name={name}
              className={`${inputClassName} ${style.checkbox_input}`}
              onChange={handleCheckboxChange}
              type={type}
              id={checkboxInputOption}
              value={checkboxInputOption}
            />
            <label htmlFor={checkboxInputOption}>{checkboxInputOption}</label>
          </div>
        );
      })}

                            </section>

                            
                        )   
                    } else if (type === `radio`) {
                        return (
                            <div className={inputClassName}>
                                {
                                    radioBtnOtions?.length ?
                                        radioBtnOtions.map((radioBtnOtion, i) => {

                                            return (
                                                <div key={i} className="radio">
                                                    <input className={style.radio_btn} 
                                                    onChange={onChangeHandler} 
                                                    id={radioBtnOtion?.value || radioBtnOtion} 
                                                    checked={radioBtnOtion?.value ? radioBtnOtion?.value === value : radioBtnOtion === value}
                                                     name={name}
                                                      type="radio"
                                                       value={radioBtnOtion?.value || radioBtnOtion} 
                                                        disabled={disabled} />
                                                    <label htmlFor={radioBtnOtion?.value || radioBtnOtion} className="radio-label">{radioBtnOtion?.label || radioBtnOtion}</label>
                                                </div>
                                            )
                                        })
                                        : ``

                                }

                            </div>
                        )
                    } else if (type === `select`) {
                        return (
                            <div className={style.select_input_wrapper}>
                                <select id={inputId} onChange={onChangeHandler} name={name} className={`${inputClassName} form-select`}
                                  {...(adsIds ? { value: value } : null)}

                                    
                               
                                  >

                                    {
                                        placeholder ? <option disabled={true} selected={placeholder ? true : false}>{placeholder}</option> : ``
                                    }

                                    {
                                        selectInputOptions?.length ?
                                            selectInputOptions.map((selectInputOption, i) => {

                                                return <option
                                                    selected={selectInputOption?._id ? selectInputOption?._id == value : selectInputOption === value}
                                                    key={i}
                                                    value={selectInputOption?._id ? selectInputOption?._id : selectInputOption}
                                                    disabled={disabled}
                                                >
                                                    {selectInputOption?.name ? selectInputOption?.name : selectInputOption}
                                                </option>

                                            })
                                            :

                                            ``
                                    }

                                </select>

                            </div>
                        )

                    } else if (type === `select2`) {
                        return (
                            <div className={style.select_input_wrapper}>
                                <select id={`${inputId}${(!required && value) ? style.select_input : ``}`} onChange={onChangeHandler} name={name} className={`${inputClassName} form-select`} value={value} >

                                    {
                                        placeholder ? <option disabled={(!required && value)} selected={true}>{placeholder}</option> : ``
                                    }

                                    {
                                        selectInputOptions?.length ?
                                            selectInputOptions.map((selectInputOption, i) => {

                                                return <option
                                                    selected={selectInputOption?._id ? selectInputOption?._id == value : selectInputOption === value}
                                                    key={i}
                                                    value={selectInputOption?._id ? selectInputOption?._id : selectInputOption}
                                                >
                                                    {selectInputOption?.name ? selectInputOption?.name : selectInputOption}
                                                </option>

                                            })
                                            :

                                            ``
                                    }

                                </select>

                                {
                                    (!required && value) && <div onClick={() => handlingClearSelectInput(name)} className={style.cross_btn}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="#FD7E14" strokeWidth="2" strokeLinecap="square" strokeLinejoin="bevel"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                                    </div>
                                }
                            </div>
                        )

                    } 
                    
                    
                    else if (type === `password` || type === `password`) {

                        return <div className="form-group">
                            <div className={style.password_input_wrapper}>

                                <input type={passwordInputTypeForConfirmPassword}
                                 onChange={onChangeHandler} 
                                 className="form-control" 
                                 name={name} 
                                 placeholder={placeholder} 
                                 autoComplete="off" 
                                 />
                                {
                                    passwordInputTypeForConfirmPassword === "text" ?
                                        <svg onClick={() => { passwordInputChangeHandler(`password`, 2) }} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                                        :
                                        <svg onClick={() => { passwordInputChangeHandler(`text`, 2) }} fill="#000000" width="20" height="20" viewBox="0 -16 544 544" xmlns="http://www.w3.org/2000/svg" ><title>hide</title><path d="M108 60L468 420 436 452 362 378Q321 400 272 400 208 400 154 361 99 322 64 256 79 229 102 202 124 174 144 160L76 92 108 60ZM368 256Q368 216 340 188 312 160 272 160L229 117Q254 112 272 112 337 112 392 152 446 192 480 256 474 269 461 288 448 307 434 322L368 256ZM272 352Q299 352 322 338L293 309Q283 312 272 312 249 312 233 296 216 279 216 256 216 247 220 236L190 206Q176 229 176 256 176 296 204 324 232 352 272 352Z" /></svg>
                                }

                            </div>


                        </div>
                    }


                })()
            }

        </section>

    )
}
