import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
// import 'dayjs/plugin/customParseFormat';

import CommonModalWrapper from '../../common-modal-Wrapper/CommonModalWrapper';
import CloseIcon from '../../Icons/CloseIcon';
import AddonExampleCard from '../addon-example-card/AddonExampleCard';
import { isValidURL } from '../../../utils/verification';
import getFetchConfig from '../../../utils/getFetchConfig';
import handleFetchErrors from '../../../utils/handleFetchErrors';
import standardFetchHandlers from '../../../utils/standardFetchHandlers';
import LoaderScreen from '../loader-screen/LoaderScreen';
import FormButton from '../../buttons/form-button/FormButton';
import CheckoutButton from '../../buttons/checkout-button/CheckoutButton';
import { getCurrencySymbol } from '../../../utils/getCurrencySymbol';
import getSymbolFromCurrency from 'currency-symbol-map';
import CongratulationsScreen from '../congratulations-screen/CongratulationsScreen';

import Inputs from '../../Inputs/Inputs';
import appURLs from '../../../appURL';

import style from './adsPlans.module.css';


const formatDateFun = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${month} ${day} ${year}`;

    return formattedDate;
}

function addDaysToCurrentDate(days) {
    const currentDate = new Date();
    const newDate = new Date(currentDate.setDate(currentDate.getDate() + days));

    return formatDateFun(newDate);
}

export default function AdsPlans({ selectedPlanId, adExpiryDate, setShowAdsPlan, myads_id, webUrl, setwebUrl, setislink, addsOnArray, adsCategoriesId, adsId = "", updateSteps, steps, myCurrency, adsIds, is_featured_true, is_homePageGallery, is_bump_up, is_uregnt, is_link_webiste, website_urls, is_upcoming_event, setCongratsClose, congratsClose, showAdsPlan }) {


    console.log(
        "selectedPlanId :", selectedPlanId,
        "adExpiryDate:", adExpiryDate,
        "myads_id :", myads_id,
        "webUrl:", webUrl,
        "addsOnArray:", addsOnArray,
        "adsCategoriesId:", adsCategoriesId,
        "adsId:", adsId,
        // "updateSteps:", updateSteps,
        "steps:", steps,
        "myCurrency:", myCurrency,
        "adsIds:", adsIds,
        "is_featured_true:", is_featured_true,
        "is_homePageGallery:", is_homePageGallery,
        "is_bump_up:", is_bump_up,
        "is_uregnt:", is_uregnt,
        "is_link_webiste:", is_link_webiste,
        "website_urls:", website_urls,
        "is_upcoming_event:", is_upcoming_event,
        "congratsClose:", congratsClose,
        "showAdsPlan:", showAdsPlan,

        "checkallllll"
    )



    const fetchConfig = getFetchConfig(),
        appURL = appURLs();

    const [planData, updatePlanData] = useState([]),
        [addOnLists, updateAddOnLists] = useState([]),
        [isLinkYourWebsite, updateIsLinkYourWebsite] = useState(false),
        [homepagesgallery, sethOMEPAGESgallery] = useState(false),
        [urgento, setUrgento] = useState(false),
        [bumping_up, setBumping_up] = useState(false),
        [featuring, setFeaturing] = useState(false),
        [is_webiste_linking, setIs_websiteLinking] = useState(false),
        [is_new_events, setIs_new_events] = useState(false),
        [website, updateWebsite] = useState(""),
        [priceValid, setPriceValid] = useState(false),
        [isButtonDisabled, setIsButtonDisabled] = useState(false),
        [priceError, setPriceError] = useState(''),
        [isLinkYourPrice, updateIsLinkYourPrice] = useState(false),
        [prices, updatePrices] = useState(""),
        [isAllAddonSelected, toggleIsAllAddonSelected] = useState(false),
        [selectedExampleData, updateSelectedExampleData] = useState({}),
        [isModalOpenForExample, toggleExampleModal] = useState(false),
        [selectedAddOn, updateSelectedAddOn] = useState([]),
        [showLoader, toggleShowLoader] = useState(false),
        [selecetdPlan, updateSelecetdPlan] = useState({}),
        [selectedAddOnn, setSelectedAddOn] = useState([]),
        [showLink, setshowLink] = useState(false);
    const [totalPrice, settotalPrice] = useState(0);
    const [isExpired, setIsExpired] = useState(false)
    const [promoteExpired, setPromoteExpired] = useState(false)
    const [adExpiry, setAdExpiry] = useState("")

    useEffect(() => {
        if (adExpiryDate === "lifetime") {
            setAdExpiry("lifetime");
        } else {
            const date = new Date(adExpiryDate);
            const formattedDate = date.toLocaleDateString("en-US", {
                day: "2-digit",
                month: "long",
                year: "numeric",
            });
            setAdExpiry(formattedDate);
        }
    }, [adExpiryDate]);

    const [showCongratulations, setShowCongratulations] = useState(false);

    console.log(webUrl, "webUrl")
    console.log(isModalOpenForExample, "isModalOpenForExample")

    console.log(selectedAddOnn, "selectedAddOnnnnnn")

    useEffect(() => {
        if (webUrl !== "") {
            setshowLink(true)
            updateIsLinkYourWebsite(true)
        }
    }, [webUrl])


    console.log(isAllAddonSelected, "isAllAddonSelected=====")


    console.log(showLink, "----showLink")

    const dateFormat = 'DD/MM/YYYY'; // Adjust the date format according to the format stored in local storage
    const startDate = localStorage.getItem('start_date');
    const endDate = localStorage.getItem('end_date');

    console.log(startDate, endDate, "startDate")

    const start = dayjs(startDate, dateFormat).toDate();
    const end = dayjs(endDate, dateFormat).toDate();
    const dateDiff = Math.floor((end - start) / (1000 * 60 * 60 * 24));

    let expirationDate;

    if (dateDiff > 30) {
        expirationDate = end.toLocaleDateString(undefined, {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    } else {
        const expirationDay = end.getDate() + 1;
        const expirationMonth = end.getMonth() + 1;
        const expirationYear = end.getFullYear();
        expirationDate = new Date(expirationYear, expirationMonth - 1, expirationDay).toLocaleDateString(undefined, {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    }

    console.log(expirationDate, "expirationDate")

    useEffect(() => {
        const todayDate = new Date(); // Get the current date
        const expiration = dayjs(expirationDate, 'DD/MM/YYYY').toDate(); // Parse expirationDate

        if (expiration && expiration < todayDate) {
            setIsExpired(true);
        } else {
            setIsExpired(false);
        }
    }, [expirationDate]);

    const webExpireDate = new Date(is_link_webiste);
    const websiteExpireDate = webExpireDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
    });

    console.log(websiteExpireDate, "webExpireDate")

    useEffect(() => {
        if (is_link_webiste) {
            const todayDate = new Date(); // Get the current date
            // Convert to Date object if necessary
            console.log(webExpireDate,)
            if (webExpireDate < todayDate) {
                setPromoteExpired(true);
            } else {
                setPromoteExpired(false);
            }
        }
    }, [is_link_webiste]);

    const totalAmount = useMemo(() => {
        const totalAddonPrice = selectedAddOn.reduce((sum, addon) => sum + addon.addonPrice, 0);
        const total = totalAddonPrice + Number(selecetdPlan?.price?.amount) || 0;
        // Update the state with the total price
        // setSelectedAddOn([])
        return parseFloat(total.toFixed(2));

    }, [selectedAddOn]);

    const handlenew = () => {
        toggleShowLoader(true);

        if (is_webiste_linking && !webUrl) {
            toggleShowLoader(false);
            return toast.error("Please provide website link");
        }

        const addonIds = selectedAddOnn.map(addon => addon.addonId);
        toast.dismiss();
        let addonIdArr = [];
        for (let i = 0; i < selectedAddOn.length; i++) {
            addonIdArr.push(selectedAddOn[i].priceId);
        }
        console.log("addonIdArr", addonIdArr);
        console.log(adsId, adsIds, selecetdPlan._id, "stripeeee")
        fetch(`${appURL}/posts/payment/create-payment-intent`, {
            ...updatedFetchConfig,

            body: JSON.stringify({
                postId: adsId || adsIds,
                planId: selecetdPlan._id,
                add_ons: showLink ? addonIds : [],
                ...(webUrl?.length > 0 && { website_url: webUrl }),
                price_drop: 0 || "",
                isPromoted: true,
                redirect_uri_success: "https://test.menehariya.com/success",
                //  redirect_uri_success: "http://localhost:3005",
                ...(showLink === false && { linktoyourwebsite: true }),
            }),
        })
            .then(handleFetchErrors)
            .then((res) => {
                toggleShowLoader(false);
                console.log(res);
                if (res.paymentIntent?.toString()?.length > 0) {
                    window.location.href = res?.paymentIntent;
                }
                else if (addonIdArr.length === 0) {
                    console.log("Checker 101010");
                    setShowCongratulations(true);
                    setCongratsClose(true);
                }
                else {
                    toggleShowLoader(false);
                    updateSteps(steps + 1);
                }
            })
            .catch((err) => {
                toggleShowLoader(false);
                setShowAdsPlan(false);
                console.log(err);
            });
    };

    console.log("planDataaaaaaaaaaaa")
    console.log(planData, addOnLists, "planData")

    function calculateDuration(days) {
        const daysInMonth = 30;
        const daysInWeek = 7;
        const months = Math.floor(days / daysInMonth);
        const remainingDays = days % daysInMonth;
        const weeks = Math.floor(remainingDays / daysInWeek);
        const remainingDaysInWeek = remainingDays % daysInWeek;

        if (months !== 0 && weeks !== 0 && remainingDaysInWeek !== 0) {
            return `${months * daysInMonth + weeks * daysInWeek + remainingDaysInWeek} days`;
        } else if (months !== 0 && weeks !== 0) {
            return `${months * daysInMonth + weeks * daysInWeek} days`;

        } else if (months !== 0) {
            const monthValue = months === 1 ? "month" : "months";
            return `${months} ${monthValue}`;
        } else if (weeks !== 0 && remainingDaysInWeek !== 0) {
            return `${weeks * daysInWeek + remainingDaysInWeek} days`;
        } else if (weeks !== 0) {
            const weeksValue = weeks === 1 ? "week" : "weeks";
            return `${weeks} ${weeksValue}`;
        } else {
            return `${remainingDaysInWeek} days`;
        }
    }

    const planRadioBtnOption = useMemo(() => {
        let planRadioBtnOptionArr = [];
        for (let i = 0; i < planData.length; i++) {
            const planDataObj = planData[i];
            planRadioBtnOptionArr.push({
                label: calculateDuration(planDataObj?.duration) !== '0 days' ? calculateDuration(planDataObj?.duration) : 'Permanent',
                value: String(planDataObj?._id),
            })
        }
        return planRadioBtnOptionArr
    }, [planData]);

    // const adsPrice = localStorage.getItem('myItem');
    const myPrice = localStorage.getItem('isCheckedNegotiablePrice')
    const myPrice2 = localStorage.getItem('priceInputValue')

    // fetching ads categories
    const fetchPlanData = () => {
        toggleShowLoader(true)
        console.log(adsCategoriesId, "addOnLists123")
        console.log(myads_id, "addOnLists456")
        fetch(`${appURL}/posts/ads/fetch_plan?ads_type=${adsCategoriesId}&ads_id=${myads_id}`, {
            ...fetchConfig,
            method: "GET",
        })
            .then(handleFetchErrors)
            .then((res) => {
                console.log(res);

                // setshowLink(res?.data[0]?.add_ons[3]?.current_addon?.status);

                myads_id && toggleShowLoader(false);

                if (res.status === 200) {
                    const adsPlansData = res?.data;
                    console.log(adsPlansData, "addOnLists1")
                    // debugger
                    if (adsPlansData.length) {
                        console.log("addOnLists2")
                        updatePlanData(adsPlansData);
                        updateSelecetdPlan(adsPlansData[0]);

                        if (!adsPrice) {
                            const filteredAddOns = adsPlansData[0]?.add_ons.filter(addon => addon.name !== "Price Drop");
                            console.log(filteredAddOns, "addOnLists3")
                            updateAddOnLists(filteredAddOns);
                        }
                        else if (adsPrice) {
                            console.log("addOnLists4")
                            updateAddOnLists(adsPlansData[0]?.add_ons);
                        }
                    }
                } else {
                    return toast.error(`something went wrong`);
                }
            })
            .catch((err) => {
                toggleShowLoader(false)
                console.log(err)
            });
    }

    // useEffect(() => {
    //     if (selectedAddOn?.length === selecetdPlan?.add_ons?.length) toggleIsAllAddonSelected(true)
    //     else toggleIsAllAddonSelected(false)
    // }, [selectedAddOn])

    useEffect(() => {
        fetchPlanData();
    }, [adsCategoriesId, myads_id]);

    const planChangeHanlder = (e) => {
        if (selectedPlanId !== "") return;

        const planId = e?.target?.value;

        const foundPlan = planData.find(plan => plan._id === planId);
        if (foundPlan && Object.keys(foundPlan).length) {
            updateSelecetdPlan(foundPlan);
            updateAddOnLists(foundPlan.add_ons);
            updateSelectedAddOn([]);
            toggleIsAllAddonSelected(false);
        }
    }

    if (showLoader) {
        return <LoaderScreen />
    }

    console.log(
        "is_homePageGallery:", is_homePageGallery, "homepagesgallery:", homepagesgallery, "is_uregnt:", is_uregnt, "urgento:", urgento,
        "is_bump_up:", is_bump_up, "bumping_up:", bumping_up,
        "is_featured_true:", is_featured_true, "featuring:", featuring,
        "is_link_webiste:", is_link_webiste, "is_webiste_linking:", is_webiste_linking,
        "is_upcoming_event:", is_upcoming_event, "is_new_events:", is_new_events,

        "222"
    )

    console.log(addOnLists, "addOnListsssss")
    console.log(totalPrice, "totalPrice33")

    console.log(selectedAddOnn, "isAlreadyExistSelectedAddon====0")

    //selecting add ons
    const handleSelectAddon = (addonId, addonTitle, amount) => {
        console.log(addonId, addonTitle, amount, "isAlreadyExistSelectedAddon====1")

        // setSelectedAddOn(prevSelected => {
        //     console.log('Checking addonId:', addonId);
        //     console.log('Current selected:', prevSelected);

        //     if (prevSelected.some(selectOptionArr => selectOptionArr.addonId === addonId)) {
        //         console.log('AddonId found, removing it');
        //         settotalPrice(totalPrice => parseFloat((totalPrice - amount).toFixed(2)));
        //         return prevSelected.filter(selectOptionArr => selectOptionArr.addonId !== addonId);
        //     } else {
        //         console.log('AddonId not found, adding it');
        //         settotalPrice(totalPrice => parseFloat((totalPrice + amount).toFixed(2)));
        //         return [...prevSelected, { addonId: addonId, addonTitle }];
        //     }
        // });
        setSelectedAddOn((prevSelected) => {
            if (prevSelected.some((selectOptionArr) => selectOptionArr.addonId === addonId)) {
                settotalPrice((prevPrice) => parseFloat((prevPrice - amount).toFixed(2)));
                return prevSelected.filter((selectOptionArr) => selectOptionArr.addonId !== addonId);
            } else {
                settotalPrice((prevPrice) => parseFloat((prevPrice + amount).toFixed(2)));
                return [...prevSelected, { addonId, addonTitle }];
            }
        });


        console.log("price after AddonId", totalPrice)

        // if (addonTitle === 'Link to your website') {
        //     // setshowLink(!showLink);
        //     // setwebUrl('');
        // }

        const isAlreadyExistSelectedAddon = selectedAddOnn?.some((addon) => addon.addonId === addonId);

        console.log(isAlreadyExistSelectedAddon, "isAlreadyExistSelectedAddon====2")

        const foundAddon = selecetdPlan?.add_ons.find((addon) => addon._id == addonId);

        console.log(isAlreadyExistSelectedAddon, "isAlreadyExistSelectedAddon=====3")

        if (isAlreadyExistSelectedAddon) {

            // alert("is_homePageGallery");
            if (addonTitle === "Link to your website") {
                // updateIsLinkYourWebsite(false);
                updateWebsite("");
                setIs_websiteLinking(false)
            } if (addonTitle === "Price Drop") {
                updateIsLinkYourPrice(false);
                updatePrices("");
                setIsButtonDisabled(false)
            }
            if (addonTitle === "Homepage Gallery") {
                sethOMEPAGESgallery(false);
            }
            if (addonTitle === "Urgent") {
                setUrgento(false);
            }
            if (addonTitle === "Bump up") {
                setBumping_up(false);
            }
            if (addonTitle === "Featured") {
                setFeaturing(false);
            }
            if (addonTitle === "Upcoming Event") {
                setIs_new_events(false);
            }
            const filteredSelectedAddon = selectedAddOn.filter((addon) => addon.addonId !== addonId);
            updateSelectedAddOn([...filteredSelectedAddon]);
        }

        else if (addonTitle === "Homepage Gallery") {
            sethOMEPAGESgallery(true);
            const filteredSelectedAddon = selectedAddOn.filter((addon) => addon.addonId !== addonId);
            updateSelectedAddOn([...filteredSelectedAddon]);
        }
        else if (addonTitle === "Urgent") {
            setUrgento(true);
            const filteredSelectedAddon = selectedAddOn.filter((addon) => addon.addonId !== addonId);
            updateSelectedAddOn([...filteredSelectedAddon]);
        }
        else if (addonTitle === "Bump up") {
            setBumping_up(true);
            const filteredSelectedAddon = selectedAddOn.filter((addon) => addon.addonId !== addonId);
            updateSelectedAddOn([...filteredSelectedAddon]);
        }
        else if (addonTitle === "Featured") {
            setFeaturing(true);
            const filteredSelectedAddon = selectedAddOn.filter((addon) => addon.addonId !== addonId);
            updateSelectedAddOn([...filteredSelectedAddon]);
        }
        else if (addonTitle === "Link to your website") {
            //else if (showLink && addonTitle === "Link to your website") {
            console.log("LInkkkkkk")
            setIs_websiteLinking(true);
            const filteredSelectedAddon = selectedAddOn.filter((addon) => addon.addonId !== addonId);
            updateSelectedAddOn([...filteredSelectedAddon]);
        }
        else if (addonTitle === "Upcoming Event") {
            setIs_new_events(true);
            const filteredSelectedAddon = selectedAddOn.filter((addon) => addon.addonId !== addonId);
            updateSelectedAddOn([...filteredSelectedAddon]);
        }

        else {
            if ((foundAddon && Object.keys(foundAddon).length) || isAllAddonSelected) {
                if (addonTitle === "Link to your website") {
                    updateIsLinkYourWebsite(true);
                } if (addonTitle === "Price Drop") {
                    updateIsLinkYourPrice(true);
                }
                // if(addonTitle === "Homepage Gallery"){
                //     sethOMEPAGESgallery(true)
                // } 
                updateSelectedAddOn([
                    ...selectedAddOn,
                    {
                        addonName: foundAddon?.name,
                        addonId: addonId,
                        priceId: foundAddon?.price[0]?._id,
                        addonPrice: foundAddon?.price[0]?.amount,
                        addonCurrency: foundAddon?.price[0]?.currency,
                    },
                ]);
            }
        }
    };

    // console.log(totalPrice, "settotalPrice")
    console.log("updateSelectedAddOn", selectedAddOn)

    console.log(startDate, endDate, selecetdPlan?.name, "locallllllllllll")

    const exp_date = localStorage.getItem('event_end_date');

    const handleChangePrice = (e, addonId) => {
        const selectedOptionData = e.target?.options[e.target.selectedIndex]?.dataset;

        console.log(`event`, e);
        const dataset = e.dataset;
        console.log(`dataset`, selectedOptionData);
        const selectedPrice = e?.target?.value;

        const updatedSelectedAddOn1 = [...selectedAddOn];

        const existingAddonIndex = updatedSelectedAddOn1.findIndex(
            (addon) => addon.addonId === addonId
        );

        if (existingAddonIndex !== -1) {
            updatedSelectedAddOn1[existingAddonIndex].addonPrice = Number(selectedPrice);
            updatedSelectedAddOn1[existingAddonIndex].priceId = selectedOptionData?.priceId;
            updateSelectedAddOn(updatedSelectedAddOn1);
        }
    }

    const checkingSelectedPrice = (addonId) => {
        return selectedAddOn.some((addon) => addon.addonId === addonId)
    }

    const updatedFetchConfig = fetchConfig;
    updatedFetchConfig.headers['Content-Type'] = 'application/json';


    const handle = () => {
        toast.dismiss();
        if (isLinkYourWebsite && !website) {
            return toast.error("Please provide website link");
        }
        //  else if (isLinkYourWebsite && website && !isValidURL(website)) {
        //     return toast.error("Please provide a valid website link");
        // } 
        else if (isLinkYourPrice && !prices) {
            return toast.error("Please provide a price");
        }

        let addonIdArr = [];

        for (let i = 0; i < selectedAddOn.length; i++) {
            addonIdArr.push(selectedAddOn[i].priceId);
        }

        fetch(`${appURL}/posts/payment/create-payment-intent`, {
            ...updatedFetchConfig,
            body: JSON.stringify({
                postId: adsId || adsIds,
                planId: selecetdPlan._id,
                add_ons: addonIdArr,
                website_url: website || "",
                price_drop: prices || "",
                redirect_uri_success: "https://menehariya.netscapelabs.com/success",
            }),
        })
            .then(handleFetchErrors)
            .then((res) => {
                console.log(res);
                if (addonIdArr.length === 0) {
                    console.log("Checker 101010");
                    setShowCongratulations(true);
                    setCongratsClose(true);

                }
                if (res.paymentIntent) {
                    window.location.href = res.paymentIntent;
                } else {
                    updateSteps(steps + 1);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const backBtnClickHandler = () => {
        if (!adsId) {
            updateSteps(steps - 1)
        }
    }

    const handleExampleModal = (value, addonId) => {

        const foundAddonExample = selecetdPlan.add_ons.find((addon) => addon._id == addonId);
        console.log(foundAddonExample, "selecetdPlannnnn222")

        if (foundAddonExample) {

            updateSelectedExampleData({
                title: foundAddonExample?.example_title,
                description: foundAddonExample?.example_description,
                image: foundAddonExample?.example_image
            })
            toggleExampleModal(value)
        } else {
            toggleExampleModal(value)
        }
    }

    let adsPrice = localStorage.getItem('priceInputValue');

    const handlePriceChange = (e) => {
        const enteredPrice = Number(e.target.value);

        if (enteredPrice >= adsPrice) {
            setIsButtonDisabled(true);
            setPriceError("");
            toast.error('Enter a valid amount');
        } else if (enteredPrice >= 1) {
            setIsButtonDisabled(false); // Enable the button when the input is valid
            setPriceError('Your sales price will be displayed as');
        } else {
            setIsButtonDisabled(true); // Disable the button when the input is not valid
            setPriceError('');
        }
        updatePrices(enteredPrice || null);
    };

    // const handleSelectAll = (e) => {

    //     console.log("total===========")
    //     const isChecked = e.target.checked;

    //     if (isChecked) {
    //         const selectedPlanAddon = selecetdPlan?.add_ons;

    //         let allSelectedAddonArr = [];

    //         for (var i = selectedPlanAddon.length - 1; i >= 0; i--) {
    //             const addon = selectedPlanAddon[i];
    //             const addonObj = {
    //                 addonName: addon?.name,
    //                 addonId: addon?._id,
    //                 priceId: addon?.price[0]?._id,
    //                 addonPrice: addon?.price[0]?.amount,
    //                 addonCurrency: addon?.price[0]?.currency,
    //             };

    //             allSelectedAddonArr.push(addonObj.addonName);
    //         }
    //         console.log(allSelectedAddonArr, "allSelectedAddonArr")
    //         updateIsLinkYourWebsite(true);


    //         updateIsLinkYourPrice(true);

    //         updateSelectedAddOn(allSelectedAddonArr);

    //         sethOMEPAGESgallery(true)
    //         setBumping_up(true)
    //         setUrgento(true)
    //         setFeaturing(true)
    //         setIs_new_events(true)

    //     } else {
    //         sethOMEPAGESgallery(false)
    //         setBumping_up(false)
    //         setUrgento(false)
    //         setFeaturing(false)
    //         setIs_new_events(false)
    //         updateIsLinkYourWebsite(false);
    //         //   sethOMEPAGESgallery(false);
    //         updateIsLinkYourPrice(false);
    //         updateSelectedAddOn([]);
    //     }

    //     toggleIsAllAddonSelected(isChecked);
    // };

    console.log(isAllAddonSelected, "isAllAddonSelected========0")

    // const handleSelectAll = (e) => {
    //     console.log(isAllAddonSelected,"isAllAddonSelected=====1")
    //     const isChecked = e.target.checked;

    //     if (isChecked) {
    //         console.log(isAllAddonSelected, "isAllAddonSelected========2")

    //         const selectedPlanAddon = selecetdPlan?.add_ons || [];
    //         let allSelectedAddonArr = [];

    //         for (let i = 0; i < selectedPlanAddon.length; i++) {
    //             const addon = selectedPlanAddon[i];
    //             const addonObj = {
    //                 addonName: addon?.name,
    //                 addonId: addon?._id,
    //                 priceId: addon?.price[0]?._id,
    //                 addonPrice: addon?.price[0]?.amount,
    //                 addonCurrency: addon?.price[0]?.currency,
    //             };

    //             allSelectedAddonArr.push(addonObj);
    //             // Call handleSelectAddon for each addon 
    //             toggleIsAllAddonSelected(true)
    //             handleSelectAddon(addonObj.priceId, addonObj.addonName, addonObj.addonPrice);
    //             console.log(isAllAddonSelected, "isAllAddonSelected========3")
    //         }

    //         console.log(allSelectedAddonArr, "allSelectedAddonArr");

    //         updateIsLinkYourWebsite(true);
    //         updateIsLinkYourPrice(true);

    //     } else {
    //         console.log(isAllAddonSelected, "isAllAddonSelected========4")
    //         selecetdPlan?.add_ons.forEach((addon) => {
    //             handleSelectAddon(addon.priceId, addon.name, addon.price[0]?.amount);
    //         });
    //         toggleIsAllAddonSelected(false)
    //         updateIsLinkYourWebsite(false);
    //         updateIsLinkYourPrice(false);
    //         updateSelectedAddOn([]);
    //         console.log(isAllAddonSelected, "isAllAddonSelected========5")
    //     }
    // };

    const handleSelectAll = (e) => {
        console.log(isAllAddonSelected, "isAllAddonSelected=====1");
        const isChecked = e.target.checked;

        if (isChecked) {
            console.log(isAllAddonSelected, "isAllAddonSelected========2");

            const selectedPlanAddon = selecetdPlan?.add_ons || [];
            let allSelectedAddonArr = [];

            for (let i = 0; i < selectedPlanAddon.length; i++) {
                const addon = selectedPlanAddon[i];
                const addonObj = {
                    addonName: addon?.name,
                    addonId: addon?._id,
                    priceId: addon?.price[0]?._id,
                    addonPrice: addon?.price[0]?.amount,
                    addonCurrency: addon?.price[0]?.currency,
                };

                allSelectedAddonArr.push(addonObj);

                // Call handleSelectAddon for each addon
                toggleIsAllAddonSelected(true);
                handleSelectAddon(addonObj.priceId, addonObj.addonName, addonObj.addonPrice);
                console.log(isAllAddonSelected, "isAllAddonSelected========3");
            }

            console.log(allSelectedAddonArr, "allSelectedAddonArr");

            // Update additional states
            updateIsLinkYourWebsite(true);
            updateIsLinkYourPrice(true);
        } else {
            console.log(isAllAddonSelected, "isAllAddonSelected========4");

            selecetdPlan?.add_ons.forEach((addon) => {
                const addonObj = {
                    addonName: addon?.name,
                    addonId: addon?._id,
                    priceId: addon?.price[0]?._id,
                    addonPrice: addon?.price[0]?.amount,
                    addonCurrency: addon?.price[0]?.currency,
                };

                // Call handleSelectAddon for each addon in reverse
                handleSelectAddon(addonObj.priceId, addonObj.addonName, addonObj.addonPrice);
            });

            // Reset additional states
            toggleIsAllAddonSelected(false);
            updateIsLinkYourWebsite(false);
            updateIsLinkYourPrice(false);
            updateSelectedAddOn([]);
            console.log(isAllAddonSelected, "isAllAddonSelected========5");
        }
    };


    function calculateDuration(days) {
        const daysInMonth = 30;
        const daysInWeek = 7;
        const months = Math.floor(days / daysInMonth);
        const remainingDays = days % daysInMonth;
        const weeks = Math.floor(remainingDays / daysInWeek);
        const remainingDaysInWeek = remainingDays % daysInWeek;

        if (months !== 0 && weeks !== 0 && remainingDaysInWeek !== 0) {
            return `${months * daysInMonth + weeks * daysInWeek + remainingDaysInWeek} days`;
        } else if (months !== 0 && weeks !== 0) {
            return `${months * daysInMonth + weeks * daysInWeek} days`;
        } else if (months !== 0) {
            const monthValue = months === 1 ? "month" : "months";
            return `${months} ${monthValue}`;
        } else if (weeks !== 0 && remainingDaysInWeek !== 0) {
            return `${weeks * daysInWeek + remainingDaysInWeek} days`;
        } else if (weeks !== 0) {
            const weeksValue = weeks === 1 ? "week" : "weeks";
            return `${weeks} ${weeksValue}`;
        } else {
            return `${remainingDaysInWeek} days`;
        }
    }

    console.log(adsId, "adsId-------")

    return (
        <>
            <div className='ad-page-main'>
                <CommonModalWrapper isOpen={isModalOpenForExample} className={style.example_modal} >
                    <CloseIcon onClick={() => handleExampleModal(false, null)} />
                    <AddonExampleCard selectedExampleData={selectedExampleData} handleExampleModal={handleExampleModal} />
                </CommonModalWrapper>

                {showCongratulations &&
                    <CongratulationsScreen adsIds={adsIds} />
                }

                <section id='span-2' className={style.ads_plan_wrapper}>
                    {selecetdPlan?.name !== "Local Biz & Services" && (
                        <>
                            {console.log("Date block")}
                            <div className={style.selected_plan}>
                                <h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>Buy Promotions</h5>
                                <p className={style.ads_expire_info_text}>{isExpired ? `This Ad has expire on ${adExpiry}` : `This Ad will expire on ${adExpiry}`
                                }</p>
                            </div>
                        </>
                    )}

                    {selecetdPlan?.name === "Local Biz & Services" && (
                        <>
                            {/* {
                            planRadioBtnOption.length === 1  && selecetdPlan?.price?.isfree ? `` : <span className={style.select_duration_heading}>Select Duration</span>

                        } */}

                            <h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>Buy Promotions</h5>

                            <section id={planRadioBtnOption.length === 1 && selecetdPlan?.price?.isfree && !expirationDate && style.expire_text_section} className={style.expire_text_section}>
                                {
                                    planRadioBtnOption.length === 1 && !selecetdPlan?.price?.isfree ?
                                        <span className={style.ads_expire_info_text}>{`This Ad will expire on ${adExpiry}`}</span>
                                        // <span className={style.ads_expire_info_text}> This Ad will expire on {expirationDate}</span>
                                        :
                                        <>
                                            {
                                                planRadioBtnOption?.length &&
                                                <Inputs
                                                    name='careType'
                                                    value={String(selectedPlanId)}
                                                    onChangeHandler={planChangeHanlder}
                                                    type='radio'
                                                    id='span-2'
                                                    required={true}
                                                    radioBtnOtions={planRadioBtnOption}
                                                />
                                            }

                                            {/* <div className={style.price_section}>
                                            
                                            <span className={style.plan_price_text} >
                                                
                                                {!selecetdPlan?.price?.isfree && getCurrencySymbol(selecetdPlan?.price?.currency)}
                                                
                                                {selecetdPlan?.price?.isfree ? `Free` : selecetdPlan?.price?.amount}
                                                
                                            </span>

                                            <p className={style.expire_text}>
                                                This Ad will expire on {selecetdPlan?.duration ? addDaysToCurrentDate(selecetdPlan?.duration) : ``}
                                            </p>

                                        </div> */}

                                            <div className={style.price_section}>
                                                <span className={style.plan_price_text}>
                                                    {!selecetdPlan?.price?.isfree
                                                        ? `${getCurrencySymbol(selecetdPlan?.price?.currency)}${selecetdPlan?.price?.amount}`
                                                        : "Free"}
                                                </span>
                                                {/* <h4 style={{textAlign: 'center', fontWeight: 'bold'}}>Buy Promotions</h4> */}
                                                <p className={style.expire_text}>
                                                    {selecetdPlan?.name === "Local Biz & Services" && adExpiryDate === "lifetime"
                                                        ? "This ad won't expire"
                                                        : `This Ad will expire on ${adExpiry}`}
                                                </p>
                                                {/* <p className={style.expire_text}>{`This Ad will expire on ${exp_date}`}</p> */}
                                            </div>

                                        </>}
                            </section>
                        </>
                    )}

                    <section className={style.addon_section_wrapper}>
                        <section className={style.sub_heading_and_selectall_wrapper}>
                            <h4>Make your ad stand out!</h4>
                            <div className={style.sub_heading_and_selectall_section}>
                                <span>Select an option to promote your ad</span>
                                <div className={style.select_all_label} >
                                    <span>Select All</span>
                                    <input checked={isAllAddonSelected} onChange={handleSelectAll} type="checkbox" id="selectaAll" name="selectaAll" />
                                    <label htmlFor="selectaAll"></label>
                                </div>
                            </div>

                        </section>

                        {
                            addOnLists.length ?
                                addOnLists.map((addOn, j) => {
                                    const isFeatured = addOn?.name === "Featured";
                                    const isAddonSelected = selectedAddOn.some(selectOptionArr => selectOptionArr.addonId === addOn._id);
                                    const isPriceValid = is_featured_true
                                    return (

                                        <section key={j} className={style.plan_card_wrapper} id={checkingSelectedPrice(addOn?._id) ? style.active_card : ``}>
                                            <div className={style.check_plan_heading_wrapper} >
                                                <div className={style.check_box_wrapper}
                                                    onClick={() => handleExampleModal(true, addOn?._id)} style={{ cursor: "pointer" }}
                                                >

                                                    <input

                                                        checked={
                                                            (addOn.name === "Homepage Gallery" && (is_homePageGallery || homepagesgallery)) ||
                                                            (addOn.name === "Urgent" && (is_uregnt || urgento)) ||
                                                            (addOn.name === "Bump up" && (is_bump_up || bumping_up)) ||
                                                            (addOn.name === "Featured" && (is_featured_true || featuring)) ||
                                                            (addOn.name === "Link to your website" && (is_link_webiste || is_webiste_linking)) ||
                                                            (addOn.name === "Upcoming Event" && (is_upcoming_event || is_new_events))
                                                        }


                                                        onChange={() => { handleExampleModal(false); handleSelectAddon(addOn?.price[0]?._id, addOn?.name, addOn?.price[0]?.amount) }}

                                                        type="checkbox"
                                                        id={addOn?.price[0]?._id}
                                                        name="selected_addon"
                                                        value={addOn?.price[0]?._id}

                                                    // checked={(is_homePageGallery && addOn.name === "Homepage Gallery") || (is_uregnt && addOn.name === "Urgent") || (is_bump_up && addOn.name === "Bump up") || (is_featured_true && addOn.name === "Featured") || (is_link_webiste && showLink && addOn.name === "Link to your website") || (is_upcoming_event && addOn.name === "Upcoming Event")}
                                                    />
                                                    <label htmlFor={addOn?.price[0]?._id}></label>
                                                    <span style={{ background: `${addOn?.color_code}` }} className={style.plan_heading_text}>{addOn?.name}</span>
                                                </div>

                                                <p className={style.plan_description_text}>{addOn?.description} <span onClick={() => handleExampleModal(true, addOn?._id)} className={style.view_example_text}>View example</span></p>

                                                {addOn?.name === "Featured" ? (
                                                    is_featured_true && <span style={{ color: 'red' }}>
                                                        This ad has expired on {is_featured_true.toLocaleDateString('en-IN')}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                                {addOn?.name === "Homepage Gallery" ? (
                                                    is_homePageGallery && <span style={{ color: 'red' }}>This ad has expired on {is_homePageGallery.toLocaleDateString('en-IN')}</span>
                                                ) : (
                                                    ""
                                                )}

                                                {addOn?.name === "Bump up" ? (
                                                    is_bump_up && <span style={{ color: 'red' }}>This ad has expired on {is_bump_up.toLocaleDateString('en-IN')}</span>
                                                ) : (
                                                    ""
                                                )}

                                                {addOn?.name === "Urgent" ? (
                                                    is_uregnt && <span style={{ color: 'red' }}>This ad has expired on {is_uregnt.toLocaleDateString('en-IN')}</span>
                                                ) : (
                                                    ""
                                                )}

                                                {/* 
                                            {addOn?.name === "Link to your website" && showLink ? (
                                                is_link_webiste && <span style={{ color: 'red' }}>This ad has expired on {is_link_webiste.toLocaleDateString('en-IN')}</span>
                                            ) : (
                                                ""
                                            )} */}

                                                {addOn?.name === "Link to your website" && showLink ? (
                                                    is_link_webiste && <span style={{ color: 'red' }}>{promoteExpired ? `This Ad has expire on ${websiteExpireDate}` : `This Ad will expire on ${websiteExpireDate}`
                                                    }</span>
                                                ) : (
                                                    ""
                                                )}

                                                {addOn?.name === "Upcoming Event" ? (
                                                    is_upcoming_event && <span style={{ color: 'red' }}>This ad has expired on {is_upcoming_event.toLocaleDateString('en-IN')}</span>
                                                ) : (
                                                    ""
                                                )}

                                                {addOn?.name === "Link to your website" && (is_link_webiste || is_webiste_linking) ? (
                                                    <input
                                                        // disabled={!selectedAddOn.some((selectOptionArr) => selectOptionArr.addonId === addOn._id)}
                                                        value={webUrl}
                                                        onChange={(e) => setwebUrl(e.target.value)}
                                                        type="text"
                                                        name="website"
                                                        className={style.website_text_input_field}
                                                        placeholder="Enter website link"
                                                    />
                                                ) : (
                                                    ""
                                                )}

                                                {addOn?.name === "Price Drop" && (
                                                    <div>
                                                        {priceError && <span className={style.error_message}>
                                                            {priceError} <span className={style.error_drop}>{myCurrency ? getSymbolFromCurrency(myCurrency) : "$"}{""}{adsPrice}</span>
                                                            {" "}  <span className={style.actual_drop_price}>{getSymbolFromCurrency(myCurrency) || "$"}{""}{prices}</span>
                                                        </span>}
                                                        <input
                                                            disabled={!selectedAddOn.some((selectOptionArr) => selectOptionArr.addonId === addOn._id)}
                                                            value={prices}
                                                            onChange={handlePriceChange}
                                                            onWheel={(e) => e.currentTarget.blur()}
                                                            type="number"
                                                            name="price"
                                                            className={priceValid ? style.website_text_input_field : `${style.website_text_input_field} ${style.invalid}`}
                                                            placeholder="Enter Price"
                                                        />
                                                    </div>
                                                )}

                                                {/* selectedAddOn.some(selectOptionArr => selectOptionArr.addonId === addOn._id)  */}
                                            </div>

                                            <select id={style.select_plan_input} name="price" onChange={(e) => { handleChangePrice(e, addOn?._id) }} className={checkingSelectedPrice(addOn?._id) ? `${style.select_plan_input} ${style.active_card}` : style.select_plan_input} >

                                                {
                                                    addOn?.price?.length ?
                                                        addOn?.price.map((price, i) => {
                                                            return (
                                                                <option
                                                                    selected={selectedAddOn.find((addonlist) => { return (addonlist?.addonPrice === price?.amount && addOn._id === addonlist.addonId) })}
                                                                    key={i}
                                                                    data-price-id={price?._id}
                                                                    value={price?.amount}>


                                                                    {addOn?.name === "Bump up" ? (
                                                                        price.duration === 30 ? (
                                                                            `Everyday - ${!price?.isfree && getCurrencySymbol(price?.currency)}${price?.isfree ? "Free" : price?.amount}`
                                                                        ) : (
                                                                            `Every ${price.duration} days - ${!price?.isfree && getCurrencySymbol(price?.currency)}${price?.isfree ? "Free" : price?.amount}`
                                                                        )
                                                                    ) : (
                                                                        addOn?.name === "Link to your website" ? (
                                                                            `Free`
                                                                        ) : (
                                                                            `${price.duration} days - ${!price?.isfree && getCurrencySymbol(price?.currency)}${price?.isfree ? "Free" : price?.amount}`
                                                                        )
                                                                    )}



                                                                </option>

                                                            )
                                                        }) : ``
                                                }

                                            </select>
                                        </section>

                                    )

                                })
                                : ``
                        }
                    </section>

                    <div className={style.price_calculation_section}>

                        <section className={style.price_calculation_heading}>
                            <span>Total</span>
                        </section>

                        <section className={style.price_calculation_amount}>
                            <span className={style.amount_text}>${totalPrice}</span>
                        </section>

                    </div>
                    {/* 
                <CheckoutButton cancelButtonText='Back' submitBtnText={selectedAddOn.length !==0 ? "Checkout and Post My Ad": 'Post My Ad'} id="span-2" className={style.form_button_section} backBtnClickHandler={backBtnClickHandler} onClickHandler={handle} showLoadder={false} />  */}


                    <CheckoutButton
                        cancelButtonText=""
                        // submitBtnText={selectedAddOn.length !== 0 ? "Post My Ad" : "Post My Ad ${totalAmount}"}
                        submitBtnText={totalAmount !== 0 ? `Promote My Ad - $${totalAmount}` : "Promote My Ad"}
                        id="span-2"
                        className={style.form_button_section}
                        backBtnClickHandler={backBtnClickHandler}
                        onClickHandler={handlenew}
                        showLoadder={showAdsPlan}
                        disabled={isButtonDisabled}
                    // showAdsPlan={showAdsPlan}
                    //    style={{ display: !cancelButtonText ? 'none' : 'block' }} 
                    />
                </section>

            </div>
        </>
    )

}
